// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  Typography,
} from "@mui/material";

// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';

// SCSS
//import './AdminUsers.scss'

// ─── IMPORTING COMPONENTS  ───────────────────────────────────────
import ListGainsGroups from '../../../components/Admin/Gains/GainsGroups/ListGainsGroups';
import ListGains from '../../../components/Admin/Gains/Gains/ListGains';
import ListGainsLevel from '../../../components/Admin/Gains/Gains/ListGainsLevel';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ViewGains = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // Brands
  const [brands, setBrands] = useState([])
  // Selected groups
  const [groupsSelected, setGroupsSelected] = useState([])
  // Filter brands
  const [brandsFilter, setBrandsFilter] = useState([])
  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  //
  // ─── SEARCH REQUEST TO GET ALL GAINS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      // Je lance ma requête avec l'API
      const getBrands = await Api({
        method: 'GET',
        query: { store: true },
        endpoint: '/brands',
      })

      if (getBrands.success && getBrands.data.length) {
        // Je rempli mon tableau avec les données
        setBrands(getBrands.data);
      }
    };
    if (!brands.length) fetchBrands()
  }, [brands]);

  useEffect(() => {
    // filtrer brands selon group
    if (Object.keys(groupsSelected).length && brands) setBrandsFilter(brands.filter(brand => groupsSelected.includes(brand.brand_id)))
  }, [groupsSelected, brands])

  // function to handle brand filter here then pass to props
  const handleGroupFilter = (value) => {
    setGroupsSelected(value)
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return brands.length && <div className='containerAdminUser'>
    <Typography variant="h1" component="h1">
      Gérer les gains
    </Typography>
    {/* LIST GAINS GROUPS */}
    {brands.length && <ListGainsGroups brands={brands} onFilter={handleGroupFilter} />}
    {/* LIST GAINS */}
    <ListGains brands={brands} brandsFilter={brandsFilter} />

    {/* <Typography variant="h1" component="h1" sx={{ marginTop: 5 }}>
      Gérer les gains de points de fidélité
    </Typography> */}
    {/* LIST GAINS FIDELITY BAR */}
    {/* {brands.length && <ListGainsLevel brands={brands} />} */}
  </div>
};

export default ViewGains;

// GrOup by enseigne pour les users
// prenom / nom / date
// email / tel
// adresse code postal ville

// enlever titre pts de fidélité
// en dessous de la roue en gris clair (plus discret) mettre solde des points de fidélité
// roue : box shadow du truc au dessus moche
// animation gamer clavier hue respire sur le btn (faire partir du doigts)
// grossir roue + btn
//