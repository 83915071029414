// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Title from '../../components/Title'

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../contexts/Api';
import { getValue, formatDate, timeStamp } from '../../contexts/Utils';

// SVG
import LogoBK from '../../assets/static/LOGOBK.png'

// SCSS
import './Gains.scss'

// MODAL
import GainUser from '../../components/Modals/Gain/GainUser';
//import Wheel from '../../components/Wheel/Wheel';
//import LoyaltyPointsBar from '../../components/LoyaltyPointsBar/LoyaltyPointsBar';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Gains = () => {
  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [gains, setGains] = useState({})
  const [brands, setBrands] = useState([]);
  const [modal, setModal] = useState({ open: false, hash: '', name: '', brand: '' })
  // User gain_entry
  const [gainEntry, setGainEntry] = useState({})
  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── FETCH GAINS USER ───────────────────────────────────────
  //
  const fetchGains = async () => {
    const getGains = await Api({
      endpoint: '/gains/users',
      query: { query_user_id: userData.user_id },
      method: 'GET',
    });

    if (getGains.success) { setGains(getGains.data); }
  };

  useEffect(() => {
    fetchGains();
  }, [gainEntry]);

  //
  // ─── GET USER POINTS INFOS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUserPoints = async () => {
      const getUserPoints = await Api({
        endpoint: '/users',
        query: { fields: ["JSON_EXTRACT(gain_entry, '$') as gain_entry"], query_user_id: userData.user_id },
        method: 'GET',
      });
      if (getUserPoints.success) {
        if (getUserPoints.data.gain_entry) getUserPoints.data.gain_entry = JSON.parse(getUserPoints.data.gain_entry)
        setGainEntry({ brand_id: getValue(getUserPoints, ['data', 'gain_entry', 'brand_id']) });
      }
    };
    if (!Object.keys(gainEntry).length) fetchUserPoints();
  }, [userData]);

  // 
  // ─── FETCH BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      const params = {
        endpoint: '/brands',
        query: { store: true },
        method: 'GET',
      }
      const getBrands = await Api(params);

      if (getBrands.success && getBrands.data.length) {
        setBrands(getBrands.data);
      } else {
        setBrands([]);
      }
    };
    if (!brands.length) fetchBrands();
  }, [brands]);

  let uuid = btoa(userData.user_id)

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return gains && brands.length && <>
    {/* {(gainEntry.points >= 50) && <>
      <Wheel afterSpin={(prop) => setGainEntry({ ...gainEntry, points: prop })} brand_id={gainEntry.brand_id} points_user={gainEntry.points} moreSpin={true} />
      <LoyaltyPointsBar user_points={gainEntry.points} showSteps={false} />
    </>} */}
    <Title text='Mes gains' />
    <div className='userGainContainer'>
      {/* {+gainEntry.points >= 50 && <div className='userGainLoyalty'>
        <img loading="lazy" src={LogoBK} alt='Logo BK' />
        <div>
          <h3>Lancer supplémentaire</h3>
          <span className='conditionsGain'>Relancez la roue ci-dessus !</span>
          <p className='dateMaxGain'>Utilise 50 points de fidélité</p>
        </div>
      </div>} */}
      {Boolean(Object.keys(gains).length) ? Object.entries(gains).map(([key, value]) => {
        // Brand Name
        let brandName = 'Gain fidélité'
        let logoGain
        if (value.brand_id) {
          brandName = brands.find(e => e.brand_id === value.brand_id).name
          logoGain = brands.find(e => e.brand_id === value.brand_id).logo
        }
        // Name to pass to modal
        let gainName = value.name
        // HASH
        let gain_uuid = btoa(value.gain_id)
        let hash = uuid + '&' + gain_uuid + '&gain'

        return <div className='userGain' key={key}>
          <img loading="lazy" src={value.brand_id ? `${process.env.REACT_APP_URL}/img/brands/${value.brand_id}/${logoGain}?${timeStamp()}` : LogoBK} alt='Logo BK' />
          <div>
            {value.name && <h3>{value.name}</h3>}
            {value.brand_id && <p>{brandName}</p>}
            {value.conditions && <span className='conditionsGain'>{value.conditions}</span>}
            {value.due_date && <p className='dateMaxGain'>Utilisable jusqu'au <strong>{formatDate({ time: value.due_date, display: 'date_full' })}</strong></p>}
          </div>
          <div className='seeGain' onClick={() => setModal({ open: true, hash: hash, name: gainName, brand: brandName })}>Voir le gain</div>
        </div>
      }) :
        <div>Tentez votre chance à la roue lors de votre passage en caisse pour gagner un gain !</div>}
    </div>
    {/* MODAL QR GAIN */}
    {modal.open && <GainUser open={modal.open} hash={modal.hash} brand={modal.brand} name={modal.name} onClose={() => setModal({ open: false, hash: '', name: '', brand: '' })} />}
  </>

};

export default Gains;