// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { formatDate, timeStamp } from '../../../contexts/Utils';
//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Tooltip,
  Typography,
  TextField,
  FormControl,
  Select,
  MenuItem
} from "@mui/material";
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import RestoreIcon from '@mui/icons-material/Restore';
// THEME
import { useTheme } from '@mui/material/styles';

// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';
import { useNotification } from '../../../components/UseNotification';

// ─── IMPORTING POP OVER  ───────────────────────────────────────
import AdminAlertPopOver from '../../../components/Modals/Admin/AdminAlertPopOver';

// SCSS
import './AdminUsers.scss'
import EditStatusUser from '../../../components/Modals/Admin/Users/EditStatusUser'

//SVG
import LogoBK from '../../../assets/static/LOGOBK.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListUsers = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // USERS
  const [users, setUsers] = useState({ users: [], filtered: [] });
  // FILTRE USER
  const [searchBar, setSearchBar] = useState('')
  const [filter, setFilter] = useState({ all: true, byStatus: 0, byBrand: '', byRole: 0, byStore: '' })
  const [validationCounterUser, setValidationCounterUser] = useState({ counter: 0, users: [] })
  // MANAGE USER STATUS POP OVER
  const [manageUserStatusModal, setManageUserStatusModal] = useState({ open: false, id: null })
  // BRANDS
  const [brands, setBrands] = useState([])
  // STORE
  const [store, setStore] = useState([])
  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState({ open: false, status: null, function: '', text: '' });
  const [openDelete, setOpenDelete] = useState({ open: false, function: '', text: '' });
  // TABLE PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // Notification user
  const { notification } = useNotification()

  //
  // ─── SEARCH REQUEST TO GET ALL USERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUsers = async () => {
      let query = {}
      query.deleted = 0;
      if (userData.role === 7) query.brand_id = "a7a5d846-d8db-4533-a6f4-3e71735c1f8d"
      if (userData.role === 8) query.brand_id = "51914131-4943-49b3-955f-53738211fe29"
      if (userData.role === 4) query.brands_to_exclude = ["a7a5d846-d8db-4533-a6f4-3e71735c1f8d", "51914131-4943-49b3-955f-53738211fe29"]
      const getUsers = await Api({
        endpoint: '/users',
        query,
        method: 'GET',
      });

      if (getUsers.success && getUsers.data.length) {
        let counter = getUsers.data.filter(user => user.status === 1)
        setUsers({ users: getUsers.data, filtered: [] });
        setValidationCounterUser({ counter: counter.length, users: counter })
      }
    };
    if (!users.users.length) fetchUsers();
  }, [users]);

  //
  // ─── SEARCH REQUEST TO GET ALL BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      let query = {}
      query.store = true;
      if (userData.role === 4) query.brands_to_exclude = ["a7a5d846-d8db-4533-a6f4-3e71735c1f8d", "51914131-4943-49b3-955f-53738211fe29"]
      const getBrands = await Api({
        endpoint: '/brands',
        query,
        method: 'GET',
      });

      if (getBrands.success && getBrands.data.length) {
        setBrands(getBrands.data)
      } else {
        setBrands([])
      }
    };
    if (!brands.length) fetchBrands()
  }, [brands]);

  //
  // ─── SEARCH REQUEST TO GET GROUPEBK STORES (ROLE 7) ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchStore = async () => {
      const getStore = await Api({
        endpoint: '/stores',
        query: { brand_id: 'a7a5d846-d8db-4533-a6f4-3e71735c1f8d' },
        method: 'GET',
      });

      if (getStore.success && getStore.data.length) {
        setStore(getStore.data)
      }
    };
    if (!store.length) fetchStore()
  }, [store]);

  // 
  // ─── HANDLE ALERT DIALOG ───────────────────────────────────────
  //
  const handleAlertDialog = (user_id) => {
    setOpenAlert({ open: true, status: 4, function: () => archiveUser(user_id), id: user_id, text: "Archiver l'utilisateur ?" })
  };

  const handleDeleteDialog = (user_id) => {
    setOpenDelete({ open: true, function: () => deleteUser(user_id), id: user_id, text: "Supprimer l'utilisateur ?" })
  };

  const handleRestoreDialog = (user_id) => {
    setOpenAlert({ open: true, status: 2, function: () => restoreUser(user_id), id: user_id, text: "Restaurer l'utilisateur ?" })
  };

  // 
  // ─── HANDLE USER ARCHIVE STATUS ───────────────────────────────────────
  //
  const archiveUser = async (user_id) => {

    const manageUserStatus = await Api({
      endpoint: '/users',
      data: { user_edit: user_id, fields: { status: 4 } },
      method: 'PUT'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (manageUserStatus.success) {
      return notification({ variant: 'success', message: manageUserStatus.message })
    } else {
      notification({ variant: 'error', message: manageUserStatus.message })
    }
  }

  // 
  // ─── DELETE USER ───────────────────────────────────────
  //
  const deleteUser = async (user_id) => {

    let tmpUsersFiltered = users.filtered.filter(u => u.user_id !== user_id);
    let tmpUsers = users.users.filter(u => u.user_id !== user_id);

    const manageUserStatus = await Api({
      endpoint: '/users',
      data: { user_delete: user_id },
      method: 'DELETE'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (manageUserStatus.success) {
      setUsers({ users: tmpUsers, filtered: tmpUsersFiltered });
      setValidationCounterUser({ counter: validationCounterUser.counter - 1, users: tmpUsersFiltered.length ? tmpUsersFiltered : tmpUsers })
      notification({ variant: 'success', message: manageUserStatus.message })

    } else {
      notification({ variant: 'error', message: manageUserStatus.message })
    }
  }

  // 
  // ─── RESTORE USER ───────────────────────────────────────
  //
  const restoreUser = async (user_id) => {
    const restoreUser = await Api({
      endpoint: '/users',
      data: { user_edit: user_id, fields: { status: 2 } },
      method: 'PUT'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (restoreUser.success) {
      return notification({ variant: 'success', message: restoreUser.message })
    } else {
      notification({ variant: 'error', message: restoreUser.message })
    }
  }

  // 
  // ─── HANDLE UPDATE USER STATUS ───────────────────────────────────────
  // 
  const handleUserStatus = (prop) => {
    let indexUser = users.users.findIndex(user => user.user_id === prop.id)
    if (prop.archive) {
      users.users[indexUser].status = +prop.fields.status
    } else if (prop.restore) {
      users.users[indexUser].status = +prop.fields.status
    } else if (prop.reject) {
      users.users.splice(indexUser, 1)
      const newList = validationCounterUser.users.filter(user => user.user_id !== prop.id)
      setValidationCounterUser({ counter: validationCounterUser.counter - 1, users: newList })
    } else {
      users.users[indexUser].status = +prop.fields.status
      users.users[indexUser].role = +prop.fields.role
      const newList = validationCounterUser.users.filter(user => user.user_id !== prop.id)
      setValidationCounterUser({ counter: validationCounterUser.counter - 1, users: newList })
    }
    setUsers({ ...users })
    multiPropsFilter()
  }

  //
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const onSearchInput = (event) => {
    setSearchBar(event.target.value)
  }

  // FILTER USERS
  useEffect(() => {
    multiPropsFilter()
  }, [filter, searchBar]);

  const multiPropsFilter = () => {
    let filterUserList = users.users.filter(user => {
      if (!user.reference) user.reference = ''
      if (!user.name) user.name = ''
      if (!user.firstname) user.firstname = ''
      let conditions = [
        filter.byStatus !== 0 ? parseInt(user.status) === filter.byStatus : true,
        filter.byRole !== 0 ? parseInt(user.role) === filter.byRole : true,
        filter.byBrand.length ? filter.byBrand.includes(user.brand_id) : true,
        filter.byStore.length ? filter.byStore.includes(user.store_id) : true,
        searchBar.length ?
          user.name.toLowerCase().includes(searchBar) ||
          user.firstname.toLowerCase().includes(searchBar) ||
          user.reference.toUpperCase().includes(searchBar) ||
          user.reference.toLowerCase().includes(searchBar) ||
          window._DATA.types.user_status[user.status].label.toLowerCase().includes(searchBar) : true,
      ];
      return conditions.every(Boolean);
    });
    if (!filterUserList.length) filterUserList = [{ none: true }]
    setUsers({ ...users, filtered: filterUserList })
  };

  let usersList = users.users
  if (users.filtered.length || (filter.byStatus !== 0 && typeof filter.byStatus === 'number') || (filter.byBrand.length && typeof filter.byBrand === 'string') || (filter.byRole !== 0 && typeof filter.byRole === 'number') || (filter.byStore.length && typeof filter.byStore === 'string')) {
    usersList = users.filtered
  }

  //
  // ─── PAGINATION ───────────────────────────────────────
  //
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="première page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="page précédente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="page suivante"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="dernière page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usersList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return users && brands && <div className='containerAdminUser'>
    <Typography variant="h1" component="h1">
      Gérer les utilisateurs
    </Typography>
    <div>
      {![7, 8].includes(userData.role) && <form className='formFilterContainer'>
        <ul className='filterContainer'>
          <li onClick={() => setFilter({ ...filter, all: true, byBrand: '' })}>
            <input
              className='filterCheckbox'
              type="checkbox"
              checked={filter.all || (filter.byStatus && !filter.byBrand)}
            />
            <label htmlFor="all">Tous</label>
          </li>
          {brands.map((brand, index) => {
            // Counter user en attente de validation
            let counterWaiting = validationCounterUser.users.filter(user => user.brand_id === brand.brand_id).length

            return <li key={index} onClick={() => setFilter({ ...filter, all: false, byBrand: brand.brand_id })}>
              <input
                className='filterCheckbox'
                id={brand.brand_id}
                type="checkbox"
                checked={brand.brand_id === filter.byBrand}
              />
              <label htmlFor={brand.name}>{brand.name}</label>
              {(validationCounterUser.counter > 0 && counterWaiting > 0) && <span className='counterValidationUser'>{counterWaiting}</span>}
            </li>
          })}
        </ul>
      </form>}
      <div className='inputSearchTable'>
        <TextField className='searchInput' id="outlined-basic" label="Rechercher" variant="outlined"
          onChange={onSearchInput}
          sx={{ width: 300 }}
        />
        <Box sx={{ minWidth: 160 }} className='selectStatus'>
          <span>Status utilisateur</span>
          <FormControl fullWidth id="users">
            <Select
              labelId="status"
              id="status"
              label="Statut"
              defaultValue={0}
            >
              <MenuItem value={0} onClick={() => setFilter({ ...filter, all: true, byStatus: 0 })}>Tous</MenuItem>
              {Object.values(window._DATA.types['user_status']).map((type, index) => {
                return <MenuItem key={index} value={type.number} onClick={() => setFilter({ ...filter, all: false, byStatus: type.number })}>{type.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
        <Box sx={{ minWidth: 160 }} className='selectStatus'>
          <span>Rôle utilisateur</span>
          <FormControl fullWidth id="users">
            <Select
              labelId="role"
              id="role"
              label="Statut"
              defaultValue={0}
            >
              <MenuItem value={0} onClick={() => setFilter({ ...filter, all: true, byRole: 0 })}>Tous</MenuItem>
              {Object.values(window._DATA.types['user_role']).map((type, index) => {
                return <MenuItem key={index} value={type.number} onClick={() => setFilter({ ...filter, all: false, byRole: type.number })}>{type.label}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
        {[7].includes(userData.role) && <Box sx={{ minWidth: 160 }} className='selectStatus'>
          <span>Boutique utilisateur</span>
          <FormControl fullWidth id="users">
            <Select
              labelId="store"
              id="store"
              label="Boutique"
              defaultValue={0}
            >
              <MenuItem value={0} onClick={() => setFilter({ ...filter, all: true, byStore: '' })}>Tous</MenuItem>
              {store.map((store, index) => {
                return <MenuItem key={index} value={store.store_id} onClick={() => setFilter({ ...filter, all: false, byStore: store.store_id })}>{store.name}</MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>}
      </div>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead sx={{ background: '#e17f51' }}>
            <TableRow sx={{ '& th': { color: 'white' } }}>
              <TableCell></TableCell>
              <TableCell>Salarié</TableCell>
              <TableCell>Rôle</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Téléphone</TableCell>
              <TableCell>Date de naissance</TableCell>
              <TableCell>Enseigne</TableCell>
              <TableCell>Boutique</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>App</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? usersList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : usersList
            ).map((user) => {
              // Brand (if there's one)
              let brandUser = brands?.filter(brand => brand.brand_id === user.brand_id) || []
              return user.none ? <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7}>Pas de résultats</TableCell>
              </TableRow> : <TableRow
                hover
                key={user.user_id}
              >
                <TableCell component="th" scope="row">
                  <div className="thumbnailUserContainer">
                    {user.thumbnail ? <img loading="lazy" alt='thumbnail' src={`${process.env.REACT_APP_URL}/img/users/${user.user_id}/${user.thumbnail}?${timeStamp()}`} className='thumbnailUser' /> :
                      <img alt='thumbnail' src={LogoBK} className='thumbnailUser' />}
                    <span>{user.reference}</span>
                  </div>
                </TableCell>
                <TableCell>{user.name} {user.firstname}</TableCell>
                <TableCell>{window._DATA.types.user_role[user.role]?.label}</TableCell>
                <TableCell>{user.email || '-'}</TableCell>
                <TableCell>{user.phone || '-'}</TableCell>
                <TableCell>{formatDate({ time: user.birth_date, display: 'date' }) || '-'}</TableCell>
                <TableCell>{brandUser.length ? brandUser[0].name : '-'}</TableCell>
                <TableCell>{user.store_id && brandUser.length ? brandUser[0].stores?.find(e => e.store_id === user.store_id)?.name : '-'}</TableCell>
                <TableCell>
                  <span className='userStatusTable'>
                    <span className={`_${window._DATA.types.user_status[user.status].number}UserStatus labelUserStatus`}>{window._DATA.types.user_status[user.status].label}</span>
                    {user.deletion_reason && <span> {window._DATA.types.user_refused[user.deletion_reason].label}</span>}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='userAppTable'>
                    {user.has_logged_in ? <span className='appUser'>Oui</span> : <span className='appUser'>Non</span>}
                  </span>
                </TableCell>
                <TableCell>
                  <span className='userActionsTable'>{(user.status === 1 && [1, 4, 7, 8].includes(userData.role)) && <Tooltip title='Valider'>
                    <IconButton aria-label="approve" onClick={() => setManageUserStatusModal({ open: true, id: user.user_id })}>
                      <HowToRegIcon />
                    </IconButton>
                  </Tooltip>}
                    {([1, 4, 7, 8].includes(userData.role)) && <Tooltip title='Voir plus'>
                      <IconButton aria-label="voir plus" component={Link} to={`/admin/utilisateurs/${user.user_id}`}>
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>}
                    {(user.status === 2 && [1, 4, 7, 8].includes(userData.role)) && <Tooltip title='Editer'>
                      <IconButton aria-label="edit" component={Link} to={`/admin/utilisateurs/${user.user_id}/editer`}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>}
                    {(user.status === 2 && [1, 4, 7, 8].includes(userData.role)) && <Tooltip title='Archiver'>
                      <IconButton aria-label="archiver" onClick={() => handleAlertDialog(user.user_id)}>
                        <InventoryIcon />
                      </IconButton>
                    </Tooltip>}
                    {(user.status === 1 && [1, 7, 8].includes(userData.role)) && <Tooltip title='Supprimer'>
                      <IconButton aria-label="supprimer" onClick={() => handleDeleteDialog(user.user_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>}
                    {(user.status === 4 && [1, 4].includes(userData.role)) && <Tooltip title='Restaurer'>
                      <IconButton aria-label="restaurer" onClick={() => handleRestoreDialog(user.user_id)}>
                        <RestoreIcon />
                      </IconButton>
                    </Tooltip>}
                  </span></TableCell>
              </TableRow>
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={7} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'Tous', value: -1 }]}
                colSpan={7}
                count={usersList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'Lignes par page',
                  },
                  native: true,
                }}
                labelRowsPerPage={"Lignes par page"}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + from + '-' + to + ' sur ' + count
                  }
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
    {/* MANAGE USER STATUS */}
    <EditStatusUser open={manageUserStatusModal.open} id={manageUserStatusModal.id} onUpdate={handleUserStatus} onClose={() => setManageUserStatusModal({ open: false })} />
    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert.open} status={openAlert.status} text={openAlert.text} id={openAlert.id} func={openAlert.function} onUpdate={handleUserStatus} onClose={() => setOpenAlert({ open: false })} />
    <AdminAlertPopOver open={openDelete.open} text={openDelete.text} id={openDelete.id} func={openDelete.function} onClose={() => setOpenDelete({ open: false })} />
  </div>
};

export default ListUsers;