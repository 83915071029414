// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpFromSquare } from '@fortawesome/pro-regular-svg-icons'
import { faSquarePlus } from '@fortawesome/pro-regular-svg-icons'
import { faPartyHorn } from '@fortawesome/pro-duotone-svg-icons'

// SCSS
import './ModalPwa.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalPwa = ({ open }) => {

  // FONTAWESOME
  library.add(faArrowUpFromSquare, faSquarePlus, faPartyHorn)

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalPWA">
        <h2>Web-app disponible ! <FontAwesomeIcon icon="fa-duotone fa-party-horn" className='fa-flip' /></h2>
        <div className='qrContainer'>
          <p>Afin de rendre votre expérience la plus optimale possible, cliquez sur <FontAwesomeIcon icon="fa-regular fa-arrow-up-from-square" className='fa-bounce' /> situé en dessous de la barre de recherche, puis sur 'Ajoutez sur l'écran d'accueil' <FontAwesomeIcon icon="fa-regular fa-square-plus" className='fa-bounce' /> pour télécharger BK Fidélité comme une application.</p>
        </div>
      </div>
    </div>
  </div>
}

export default ModalPwa;