// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

// 
// ─── UTILS ───────────────────────────────────────
//
import { prepareFormData, getValue } from '../../../../contexts/Utils';
import Api from '../../../../contexts/Api';
import { useNotification } from '../../../UseNotification';

import Formfields from '../../../Admin/FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditStore = (props) => {

  const existingStore = props.default || {}

  const open = props.open
  const onClose = props.onClose
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});

  // Admin notification
  const { notification } = useNotification()

  //
  // ─── QUILL SETTINGS ───────────────────────────────────────
  //
  const [content, setContent] = useState('');

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  // USE EFFECT SINON LE STATE DU PREMIER STORE EST GARDÉ ET LES HORAIRES SERONT LES MAUVAIS
  useEffect(() => {
    setContent(existingStore.open_hours)
  }, [existingStore.open_hours])

  // 
  // ─── HANDLE ADD STORE ───────────────────────────────────────
  //
  const handleEditStore = async () => {
    let data = prepareFormData({ formId: 'manageStore' })

    // check si objet ne contient que le brand_id ou non
    if (typeof existingStore === 'string') data.fields.brand_id = existingStore
    if (existingStore.store_id) data.store_id = existingStore.store_id
    data.fields['open_hours'] = content

    if (data.errors) {
      setErrors({ ...errors, 'manageStore': { ...data.errors } })
    } else {
      //
      // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
      //
      const addStore = await Api({
        data: data,
        method: existingStore.store_id ? 'PUT' : 'POST',
        endpoint: '/stores',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (addStore.success) {
        onClose()
        return notification({ variant: 'success', message: addStore.message })
      } else {
        notification({ variant: 'error', message: addStore.message })
      }

    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'text', component: 'text', name: 'name', label: "Nom de la boutique", default: existingStore.name, size: '100%' },
    { type: 'text', component: 'text', name: 'address', label: "Adresse", default: existingStore.address, size: '49%' },
    { type: 'checkbox', component: 'checkbox', name: 'analytics', label: "Apparaître dans les résultats d'analytics", checked: existingStore.store_id ? existingStore.analytics : 1, size: '49%' },
    { type: 'text', component: 'text', name: 'cp', label: "Code postal", default: existingStore.cp, size: '49%' },
    { type: 'text', component: 'text', name: 'city', label: "Ville", default: existingStore.city, size: '49%' },
    { type: 'text', component: 'text', name: 'phone', label: "Téléphone", default: existingStore.phone, size: '49%' },
    { type: 'text', component: 'text', name: 'store_code', label: "Code magasin", default: existingStore.store_code, size: '49%' },
    { type: 'text', component: 'text', name: 'url', label: "Lien", default: existingStore.url, size: '100%' },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Dialog
    open={open}
    onClose={onClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth={true}
    maxWidth={'md'}
  >
    <DialogTitle id="alert-dialog-title">
      {typeof existingStore === 'string' ? 'Ajouter une' : 'Modifier la'} boutique
    </DialogTitle>
    <DialogContent>
      <Box className='manageStoreForm' component="form" name='manageStore'>
        {formFields.map((field) => {
          return <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['manageStore', field.name, 'message']) } }} />
        })}
        <div style={{ width: '100%', marginBottom: '15px' }}>
          <ReactQuill style={{ width: '100%' }} value={content} onChange={(e) => setContent(e)} theme="snow" modules={modules}
            formats={formats} placeholder="Horaires du magasin..." />
        </div>
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Annuler</Button>
      <Button variant="contained" onClick={handleEditStore}>{typeof existingStore === 'string' ? 'Ajouter' : 'Enregistrer'} </Button>
    </DialogActions>
  </Dialog>
};

export default EditStore;