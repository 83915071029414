// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Cookies from 'universal-cookie';

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/pro-light-svg-icons';

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../contexts/Api'
import { timeStamp } from '../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ActuCarousel = () => {

  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [activeIndex, setActiveIndex] = useState(0)
  const [actu, setActu] = useState([])

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // FONTAWESOME
  library.add(faCircleArrowRight)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    actusContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      overflowY: 'hidden',
      padding: '0 20px',
      gap: '20px',
      scrollSnapType: 'x mandatory',

      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    actuCard: {
      scrollSnapAlign: 'center',
      flex: '0 0 auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    actuImgContainer: {
      maxWidth: 250,
      maxHeight: 251,
      height: '100%',
      width: '100%',
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        background: 'linear-gradient(transparent 50%, rgba(27, 37, 55, 0.5) 100%)',
        inset: '0px',
        borderRadius: '20px',
      },
    },
    actuCardSolo: {
      scrollSnapAlign: 'center',
      flex: '0 0 auto',
      maxWidth: 250,
      maxHeight: 251,
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        background: 'linear-gradient(transparent 50%, rgba(27, 37, 55, 0.5) 100%)',
        inset: '0px',
        borderRadius: '20px',
      },
    },
    actuImg: {
      width: '100%',
      height: '100%',
      borderRadius: '20px',
      objectFit: 'cover'
    },
    seeMore: {
      zIndex: '1',
      position: 'absolute',
      bottom: '5px',
      right: '10px',
      color: 'white',
      fontSize: '1.5em'
    },
    titleActu: {
      marginTop: 5,
      maxWidth: 250,

      '& h3': {
        paddingLeft: 4
      }
    },
    swiperPagination: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px',
      marginBottom: 20
    },
    scrollIndicator: {
      width: 5,
      height: 5,
      margin: '0 5px',
      borderRadius: '50%',
      background: 'var(--secondary)'
    },
    activeDot: {
      width: 8,
      height: 8,
      background: 'var(--orange-primary)'
    }
  })
  const classes = useStyle();

  // 
  // ─── FETCH ACTUALITIES ───────────────────────────────────────
  //
  useEffect(() => {
    async function fetchActualities() {
      // Je lance ma requête avec l'API
      const getActu = await Api({
        method: 'GET',
        endpoint: '/actualities',
        query: { status: 2, brand_id: userData.brand_id, dateFilter: true }
      })

      if (getActu.success && getActu.data.length) {
        // Je rempli mon tableau avec les données
        setActu(getActu.data);
      }
    };
    if (!actu.length) fetchActualities()
  }, [actu])

  // 
  // ─── OBSERVER IN USE EFFECT ───────────────────────────────────────
  //
  useEffect(() => {
    let rootSelect = document.getElementById('offresContainer')
    let slides = [...document.querySelectorAll('[data-section]')]

    const options = {
      root: rootSelect,
      threshold: 0.5
    }
    const callback = slides => {
      slides.forEach((entry) => {
        if (!entry.intersectionRatio > 0) {
          return
        }
        // Id de la card
        let cardId = entry.target.dataset.section
        // Je set mon state avec l'id de la card si elle est visible totalement
        if (entry.isIntersecting) setActiveIndex(parseInt(cardId))

        // Replace data-src with src attribute (img loading replacement)
        entry.target.children[0].children[0].src = entry.target.children[0].children[0].dataset.src;
        // Remove lazy class and observer
        entry.target.classList.remove('lazy');
      })
    }
    const observer = new IntersectionObserver(callback, options)
    slides.forEach(slide => observer.observe(slide))
  }, [actu])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className={classes.actusContainer}>
      {/* MAP SUR MES ACTU CARD */}
      {actu.map((value, index) => {
        return value.title ? <Link to={`/actualites/${value.actuality_id}`} key={value.actuality_id} data-section={index} className={classes.actuCard}>
          <div className={classes.actuImgContainer}>
            <img className={`${classes.actuImg} lazy`} data-src={value.thumbnail ? `${process.env.REACT_APP_URL}/img/actualities/${value.actuality_id}/${value.thumbnail}?cache=${timeStamp()}` : `${process.env.REACT_APP_URL}/img/actualities/567adf3e-cd68-4f49-bbcd-92feb6d5460b/actu_temp.png?${timeStamp()}`} alt={value.thumbnail} />
            <div to={`/actualites/${value.actuality_id}`} className={classes.seeMore}><FontAwesomeIcon icon="fa-light fa-circle-arrow-right" /></div>
          </div>
          <div className={classes.titleActu}><h3>{value.title}</h3></div>
        </Link>
          : <a className={classes.actuCardSolo} key={index} data-section={value.actuality_id} href={value.url}>
            <img className={`${classes.actuImg} lazy`} data-src={value.thumbnail ? `${process.env.REACT_APP_URL}/img/actualities/${value.actuality_id}/${value.thumbnail}?${timeStamp()}` : `${process.env.REACT_APP_URL}/img/actualities/567adf3e-cd68-4f49-bbcd-92feb6d5460b/actu_temp.png?${timeStamp()}`} alt={value.thumbnail} />
          </a>
      })}
    </div>
    {/* MAP POUR CREER LES BULLETS */}
    <div className={classes.swiperPagination}>
      {actu.map((bullet, index) => {
        return <div id={index} className={`${classes.scrollIndicator} ${activeIndex === index ? classes.activeDot : ''}`}></div>
      })}
    </div>
  </>

}

export default ActuCarousel;