// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';

//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

// 
// ─── UTILS ───────────────────────────────────────
//
import { prepareFormData, getValue } from '../../../../../contexts/Utils';
import Api from '../../../../../contexts/Api';
import { useNotification } from '../../../../UseNotification';

// 
// ─── FORMFIELDS ───────────────────────────────────────
//
import Formfields from '../../../../Admin/FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditGainsGroups = ({ open, onClose, onUpdate, data }) => {
  // Extract what we want from props
  const { groups, brands, currentGroup } = data

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});

  // Admin notification
  const { notification } = useNotification()

  // INIT COUNTER ID GROUP
  let id
  let maxId = Math.max(...Object.keys(groups).map(([key]) => { return key }))
  Object.keys(groups).length ? id = maxId + 1 : id = 1

  // 
  // ─── HANDLE ADD GAINS GROUPS ───────────────────────────────────────
  //
  async function handleEditGainsGoups() {
    let { fields, errors } = prepareFormData({ formId: 'manageGainsGroups' });

    if (errors) {
      setErrors({ ...errors, 'manageGainsGroups': { ...errors } });
    } else {

      if (currentGroup && currentGroup.length) {
        // Change modified group
        groups[currentGroup] = fields;
      } else {
        // Generate uuid
        let group_id = id;
        // Assign new group to object
        groups[group_id] = fields;
      }

      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageGainsGroups = await Api({
        data: { fields: JSON.stringify(groups) },
        method: 'POST',
        endpoint: '/settings/gains_groups',
      });

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageGainsGroups.success) {
        onUpdate(groups);
        onClose();
        return notification({ variant: 'success', message: manageGainsGroups.message });
      } else {
        notification({ variant: 'error', message: manageGainsGroups.message });
      }
    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'text', component: 'text', name: 'name', default: currentGroup && groups[currentGroup].name, label: "Nom du groupe", size: '100%', required: true },
    { type: 'text', component: 'text', name: 'points', default: currentGroup && groups[currentGroup].points, label: "Points de fidélité à gagner", size: '100%', required: true },
    { type: 'select', component: 'select_multiple', name: 'brands', default: (currentGroup && groups[currentGroup].brands.length > 1) && groups[currentGroup].brands, label: "Enseignes", data: brands.map((brand) => { return { id: brand.brand_id, name: brand.name }; }), size: '100%', required: true },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Dialog
    open={open}
    onClose={() => { onClose(); setErrors({}) }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth={true}
    maxWidth={'md'}
  >
    <DialogTitle id="alert-dialog-title">
      {currentGroup ? 'Modifier le' : 'Ajouter un'} groupe
    </DialogTitle>
    <DialogContent>
      <Box className='manageStoreForm' component="form" name='manageGainsGroups'>
        {formFields.map((field) => {
          return <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['manageGainsGroups', field.name, 'message']) } }} />
        })}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => { onClose(); setErrors({}) }}>Annuler</Button>
      <Button variant="contained" onClick={handleEditGainsGoups}>{currentGroup ? 'Enregistrer' : 'Ajouter'} </Button>
    </DialogActions>
  </Dialog>
};

export default EditGainsGroups;