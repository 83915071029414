// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';
import Cookies from 'universal-cookie';

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { faHandBackPointUp } from '@fortawesome/pro-regular-svg-icons';

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../contexts/Api';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Title from '../Title'
import WheelResults from '../Modals/Wheel/WheelResults';

// 
// ─── IMPORTING SCSS & PNG ───────────────────────────────────────
//
import './Wheel.scss'
import wheel from '../../assets/static/wheel.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Wheel = ({ brand_id, updateGainEntry }) => {

  // FONTAWESOME
  library.add(faGift, faBomb, faHandBackPointUp)

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  // Disabled du bouton lors de l'animation de roue
  const [onSpin, setOnSpin] = useState(false)
  // Modal results infos
  const [modalResults, setModalResults] = useState({ open: false, txt: '', data: {}, onConfirm: '' })
  // Classname win or loose
  const [win, setWin] = useState('')
  const [loose, setLoose] = useState('')

  // 
  // ─── HANDLE SPINS ───────────────────────────────────────
  //
  const handleSpins = () => {
    handleWheel()
  }


  // 
  // ─── FETCH WHEEL INFOS ───────────────────────────────────────
  //
  const handleWheel = async () => {
    setOnSpin(true)
    const getSpins = await Api({
      data: { brand_id: brand_id },
      endpoint: '/settings/wheel',
      method: 'POST',
    });

    if (getSpins.success) {
      if (getSpins.won) {
        setWin(true)
      } else {
        setLoose(true)
      }
      handleWheelSpin(getSpins)
    } else {
      setModalResults({ open: true, txt: getSpins.message })
    }
  }

  // 
  // ─── HANDLE WHEEL SPIN ANIMATION ───────────────────────────────────────
  //
  const handleWheelSpin = (prop) => {
    setTimeout(() => {
      setModalResults({ open: true, data: { gain: prop.gain }, txt: prop.message })
      setOnSpin(false)
      setLoose(false)
      setWin(false)
    }, 4500)
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <Title text="Tentez votre chance" />
    <div className="wheelContainer">
      <img loading="lazy" id="wheel" className={`imgwheel ${win ? 'rotate-win' : ''} ${loose ? 'rotate-loose' : ''}`} src={wheel} alt="img wheel" />
      <div className="rouletteTop" id="spin" >
        {!onSpin && <div className="top2" onClick={handleSpins}>
          <div className='pulse'></div>
          <FontAwesomeIcon icon={faRotate} />
        </div>}
      </div>
      <div className="palheta"></div>
    </div>

    {/* MODAL AFTER SPINNING */}
    <WheelResults open={modalResults.open} data={modalResults} onClose={() => { setModalResults({ open: false, txt: '', data: {} }); updateGainEntry() }} />
  </>
};

export default Wheel;