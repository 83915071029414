// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Legals = () => {

  const legals = `<div>
	<div style="clear:both">
		<p style="margin-bottom:0pt; line-height:normal; font-size:10pt; ">
			<span style="color:#302030">Version 04/2024</span>
		</p>
		<p class="Header">
			&#xa0;
		</p>
	</div>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt; ">
		<strong>&#xa0;</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt; ">
		<strong>&#xa0;</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:14pt; ">
		<strong>POLITIQUE DE CONFIDENTIALITE</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		Conformément à l’article 13 du Règlement (UE) 2016/679 «&#xa0;RGPD&#xa0;»<span style="font-size:11pt"> </span>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		<strong>&#xa0;</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		<strong>&#xa0;</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		<strong><u>Programme de fidélité par les salariés et associés de B.K. &amp; A et de ses filiales</u></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		<strong>&#xa0;</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		<strong><u>Public visé</u></strong><strong><u>&#xa0;</u></strong><strong><u>: les Utilisateurs de l’Application
				magasin</u></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; font-size:12pt; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Afin de vérifier la validité de l’accès au programme de fidélité «&#xa0;BK Fidélité&#xa0;» dédié aux salariés et
		associés de B.K. &amp; A et de ses filiales (ci-après «&#xa0;les Salariés et Associés&#xa0;»), les salariés des
		différents points de vente des filiales de de B.K. &amp; A (ci-après «&#xa0;les Utilisateurs&#xa0;» ou
		«&#xa0;l’Utilisateur&#xa0;») utilisent une application (ci-après «&#xa0;l’Application magasin »). Dans le cadre de
		l’utilisation de cette application, nous collectons et traitons des données à caractère personnel vous concernant.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">En application de l’article 13 du Règlement Général sur la Protection des Données
			(ci-après «</span><span style="color:#302030">&#xa0;</span><span style="color:#302030">RGPD</span><span
			style="color:#302030">&#xa0;</span><span style="color:#302030">»), le Responsable du traitement est tenu de
			délivrer aux personnes concernées des informations relatives au traitement dont elles font l’objet.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Cette politique de confidentialité a donc pour objet de vous fournir des informations
			sur la manière dont nous traitons vos données personnelles et sur la manière dont vous pouvez exercer l’ensemble
			de vos droits. </span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:center; line-height:normal; ">
		<span style="color:#302030">***</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Nous sommes attachés au respect des règles de protection de votre vie privée. Nous
			gérons vos données personnelles en toute confidentialité conformément aux dispositions législatives sur la
			protection des données, notamment le RGPD.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Nous nous engageons notamment à respecter les principes suivants</span><span
			style="color:#302030">&#xa0;</span><span style="color:#302030">:</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:27.45pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:8.25pt; font-family:serif; font-size:10pt; color:#302030; ">
			<span style="font-family:Poppins; font-size:11pt">vos données personnelles sont traitées de manière licite,
				loyale et transparente (</span><strong><span style="font-family:Poppins; font-size:11pt; ">licéité, loyauté,
					transparence</span></strong><span style="font-family:Poppins; font-size:11pt">)</span><span
				style="font-family:Poppins; font-size:11pt">&#xa0;</span><span
				style="font-family:Poppins; font-size:11pt">;</span>
		</li>
		<li
			style="margin-left:27.45pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:8.25pt; font-family:serif; font-size:10pt; color:#302030; ">
			<span style="font-family:Poppins; font-size:11pt">vos données personnelles sont collectées pour des finalités
				déterminées, explicites et légitimes, et ne sont pas traitées ultérieurement d’une manière incompatible avec
				ces finalités (</span><strong><span style="font-family:Poppins; font-size:11pt; ">limitation des
					finalités</span></strong><span style="font-family:Poppins; font-size:11pt">)</span><span
				style="font-family:Poppins; font-size:11pt">&#xa0;</span><span
				style="font-family:Poppins; font-size:11pt">;</span>
		</li>
		<li
			style="margin-left:27.45pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:8.25pt; font-family:serif; font-size:10pt; color:#302030; ">
			<span style="font-family:Poppins; font-size:11pt">vos données personnelles sont conservées de manière adéquate,
				pertinente et sont limitées à ce qui est nécessaire au regard des finalités pour lesquelles elles sont
				traitées (</span><strong><span style="font-family:Poppins; font-size:11pt; ">minimisation des
					données</span></strong><span style="font-family:Poppins; font-size:11pt">)</span><span
				style="font-family:Poppins; font-size:11pt">&#xa0;</span><span
				style="font-family:Poppins; font-size:11pt">;</span>
		</li>
	</ul>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:27.91pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<span style="font-family:Poppins">vos données personnelles sont exactes, tenues à jour et toutes les mesures
				raisonnables sont prises pour que les données inexactes, eu égard aux finalités pour lesquelles elles sont
				traitées, soient effacées ou rectifiées sans tarder (</span><strong><span
					style="font-family:Poppins; ">exactitude</span></strong><span style="font-family:Poppins">).</span>
		</li>
	</ul>
	<p style="margin-left:35.7pt; margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Nous mettons en œuvre toutes les mesures techniques et organisationnelles utiles, au
			regard de la nature, de la portée et du contexte des données personnelles que vous nous communiquez et des
			risques présentés par leur traitement, pour préserver la sécurité de vos données personnelles et, notamment,
			empêcher toute destruction, perte, altération, divulgation, intrusion ou accès non autorisé à ces données, de
			manière accidentelle ou illicite.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		En utilisant l’Application magasin dans le cadre de l’exécution de votre contrat de travail, vous acceptez les
		dispositions de la présente politique de confidentialité, notamment le fait que nous collectons, traitons et
		transmettons vos données personnelles.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Par conséquent, nous vous invitons à lire attentivement la présente Politique de confidentialité de manière à être
		pleinement informé(e) de la politique que nous avons adoptée en matière de protection des données personnelles.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong><span style="color:#302030">1.</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">Qui traite vos données
				personnelles</span></strong><strong><span style="color:#302030">&#xa0;</span></strong><strong><span
				style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<strong><span style="color:#302030">B.K. &amp; A,</span></strong><span style="color:#302030"> société par actions
			simplifiée au capital de 2</span><span style="color:#302030">&#xa0;</span><span
			style="color:#302030">331</span><span style="color:#302030">&#xa0;</span><span style="color:#302030">000 €, dont
			le siège social est situé à 68110 ILLZACH – 18, Avenue de Hollande, immatriculée au Registre du Commerce et des
			Sociétés de MULHOUSE sous le numéro 440</span><span style="color:#302030">&#xa0;</span><span
			style="color:#302030">140</span><span style="color:#302030">&#xa0;</span><span style="color:#302030">200, est la
			société qui, en sa qualité de Responsable du traitement, collecte et traite vos données personnelles pour
			elle-même et pour le compte de ses filiales. </span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Nous vous informons que la société B.K. &amp; A est représentée par Monsieur Camel
			BOULHADID, en sa qualité de Président.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<strong><span style="color:#302030">&#xa0;</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong><span style="color:#302030">2. Possédons-nous un délégué à la protection des
				données</span></strong><strong><span style="color:#302030">&#xa0;</span></strong><strong><span
				style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Le Responsable du traitement n’a pas, à ce jour, désigné un Délégué à la protection des données au sens de
		l’article 37 du RGPD.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Néanmoins et comme précisé au point 9. ci-après, vous pouvez à tout moment contacter le Responsable du traitement
		par mail à l’adresse suivante&#xa0;: <a href="mailto:marketing@groupebk.com" style="text-decoration:none"><span
				class="Hyperlink" style="color:#000000"><span class="__cf_email__"
					data-cfemail="93fef2e1f8f6e7fafdf4d3f4e1fce6e3f6f1f8bdf0fcfe">marketing@groupebk.com</span></span></a> pour
		exercer vos droits et/ou pour toute question ou difficulté relative au traitement de vos données personnelles et
		de vos droits.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong><span style="color:#302030">3. Quelles données est-ce que nous traitons</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Une fois l’inscription au programme de fidélité «&#xa0;BK Fidélité&#xa0;» validée par l’équipe dédiée, un QR code
		unique et personnel est attribué aux Salariés et Associés. Afin de pouvoir bénéficier du programme de fidélité et
		des avantages qui y sont associés, ce QR code est présenté à chaque passage en caisse dans les différents points
		de vente des filiales de B.K. &amp; A.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Pour pouvoir accéder à l’Application magasin et ainsi scanner ce QR code en votre qualité d’Utilisateur de
		l’Application magasin, les catégories suivantes de données personnelles sont collectées et traitées, à
		savoir&#xa0;:
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:27.6pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
			<span style="font-family:Poppins; font-size:11pt">votre identifiant composé de votre nom et prénom ;</span>
		</li>
		<li
			style="margin-left:27.6pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:8.4pt; font-family:serif; font-size:10pt; ">
			<span style="font-family:Poppins; font-size:11pt">votre lieu de travail</span><span
				style="font-family:Poppins; font-size:11pt">&#xa0;</span><span style="font-family:Poppins; font-size:11pt">:
				nom de l’enseigne et lieu de situation du magasin.</span>
		</li>
	</ul>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Ces données sont à renseigner lors de la première connexion à l’Application magasin puis sont enregistrées.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Lorsque le QR code est scanné, l’Application magasin récupère les données des Salariés et Associés qui ont été
		collectées dans le cadre de la création du compte de fidélité via l’application salariés, à savoir&#xa0;les
		données suivantes&#xa0;:
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-bottom:6pt; text-align:justify; line-height:normal; font-family:serif; font-size:10pt; ; list-style-position:inside">
			<span style="width:4.6pt; ; display:inline-block">&#xa0;&#xa0; </span><a
				id="_Hlk161037765"><span style="font-family:Poppins; font-size:11pt">Données d’identification</span><span
					style="font-family:Poppins; font-size:11pt">&#xa0;</span><span style="font-family:Poppins; font-size:11pt">:
					nom, prénom, date de naissance, photo ;</span></a>
		</li>
		<li
			style="margin-bottom:6pt; text-align:justify; line-height:normal; font-family:serif; font-size:10pt; ; list-style-position:inside">
			<span style="width:4.6pt; ; display:inline-block">&#xa0;&#xa0; </span><span
				style="font-family:Poppins; font-size:11pt">Coordonnées</span><strong><span
					style="font-family:Poppins; font-size:11pt; ">&#xa0;</span></strong><strong><span
					style="font-family:Poppins; font-size:11pt; ">:</span></strong><span
				style="font-family:Poppins; font-size:11pt"> adresses postale, adresse électronique, numéro de
				téléphone</span><span style="font-family:Poppins; font-size:11pt">&#xa0;</span><span
				style="font-family:Poppins; font-size:11pt">;</span>
		</li>
		<li
			style="margin-bottom:0pt; text-align:justify; line-height:normal; font-family:serif; font-size:10pt; ; list-style-position:inside">
			<span style="width:4.6pt; ; display:inline-block">&#xa0;&#xa0; </span><span
				style="font-family:Poppins; font-size:11pt">Lieu de travail.</span>
		</li>
	</ul>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		En votre qualité d’Utilisateur, vous n’avez pas accès aux données personnelles précitées. En effet, l’Application
		magasin vous informe uniquement, sur la base de ces informations collectées, si l’accès au programme de fidélité
		est valide ou non.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Par ailleurs, l’Application magasin collecte et traite les données suivantes&#xa0;des Salariés et Associés :
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<ul style="margin:0pt; padding-left:0pt">
		<li class="ListParagraph"
			style="margin-left:21.25pt; margin-bottom:0pt; text-indent:-21.25pt; text-align:justify; line-height:150%; font-family:serif; ; list-style-position:inside">
			<span style="width:4.14pt; ; display:inline-block">&#xa0;&#xa0; </span><span
				style="font-family:Poppins">Nombre de passages,</span>
		</li>
		<li class="ListParagraph"
			style="margin-left:21.25pt; margin-bottom:0pt; text-indent:-21.25pt; text-align:justify; line-height:150%; font-family:serif; ; list-style-position:inside">
			<span style="width:4.14pt; ; display:inline-block">&#xa0;&#xa0; </span><span
				style="font-family:Poppins">Lieu de passages (nom de l’enseigne et lieu de situation du magasin),</span>
		</li>
		<li class="ListParagraph"
			style="margin-left:21.25pt; margin-bottom:0pt; text-indent:-21.25pt; text-align:justify; line-height:normal; font-family:serif; ; list-style-position:inside">
			<span style="width:4.14pt; ; display:inline-block">&#xa0;&#xa0; </span><span
				style="font-family:Poppins">Date des passages.</span>
		</li>
	</ul>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Les Salariés et Associés sont informés sur la manière dont leurs données personnelles sont traitées et sur la
		manière dont ils peuvent exercer l’ensemble de leurs droits dans le cadre de la politique de confidentialité
		dédiée au programme de fidélité des salariés et associés de <a id="_Hlk164327989">B.K. &amp; A et de ses filiales
		</a>/ Public visé&#xa0;: Utilisateur de l’Application salariés.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong><span style="color:#302030">4.</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong>À quelles fins mes données sont-elles traitées et quelles
			sont les bases juridiques ?</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		Le Responsable du traitement traite vos données personnelles aux fins et sur les bases juridiques suivantes :
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		<strong>&#xa0;</strong>
	</p>
	<p class="ListParagraph"
		style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
		<strong><span style="font-family:'Times New Roman'; font-weight:normal">-</strong></span><span
			style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
		</span><strong>Activité de traitement</strong><strong>&#xa0;</strong><strong>:</strong>
	</p>
	<p class="ListParagraph" style="margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal">
		Gestion du programme de fidélité «&#xa0;BK Fidélité&#xa0;»
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		<strong>&#xa0;</strong>
	</p>
	<p class="ListParagraph"
		style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
		<strong><span style="font-family:'Times New Roman'; font-weight:normal">-</strong></span><span
			style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
		</span><strong>Finalités</strong><strong>&#xa0;</strong><strong>:</strong>
	</p>
	<p class="ListParagraph" style="margin-bottom:0pt; text-align:justify; line-height:normal">
		<strong>&#xa0;</strong>
	</p>
	<ol style="margin:0pt; padding-left:0pt">
		<li class="ListParagraph"
			style="margin-left:31.51pt; margin-bottom:0pt; text-align:justify; padding-left:4.49pt; font-weight:bold">
			<a id="_Hlk161039947">Permettre aux Utilisateurs de vérifier la validité de l’accès au programme de fidélité des
				Salariés et Associés&#xa0;</a>: <span style="font-weight:normal">les données collectées sont nécessaires au
				Responsable du traitement afin de vérifier que l’accès au programme de fidélité «</span><span
				style="font-weight:normal">&#xa0;</span><span style="font-weight:normal">BK Fidélité</span><span
				style="font-weight:normal">&#xa0;</span><span style="font-weight:normal">» des Salariés et Associés qui
				souhaitent utiliser ledit programme est bien valide</span><span style="font-weight:normal">&#xa0;</span><span
				style="font-weight:normal">;</span>
		</li>
	</ol>
	<p class="ListParagraph" style="margin-bottom:0pt; text-align:justify">
		<strong>&#xa0;</strong>
	</p>
	<ol start="2" style="margin:0pt; padding-left:0pt">
		<li class="ListParagraph"
			style="margin-left:31.51pt; margin-bottom:0pt; text-align:justify; padding-left:4.49pt; font-weight:bold">
			Permettre au Responsable de traitement d’identifier le lieu d’utilisation du programme de fidélité des Salariés
			et Associés : <span style="font-weight:normal">les données collectées sont nécessaires au Responsable du
				traitement afin d’identifier</span><span style="font-weight:normal">&#xa0;</span><span
				style="font-weight:normal">l’enseigne et le lieu de situation du magasin dans lequel le programme de fidélité
				des Salariés et Associés est utilisé</span>.
		</li>
	</ol>
	<p class="ListParagraph">
		<strong>&#xa0;</strong>
	</p>
	<p>
		<br style="page-break-before:always; clear:both" />
	</p>
	<p class="ListParagraph"
		style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
		<strong><span style="font-family:'Times New Roman'; font-weight:normal">-</strong></span><span
			style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
		</span><strong>Bases juridiques</strong><strong>&#xa0;</strong><strong>:</strong>
	</p>
	<p style="margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal">
		<strong>&#xa0;</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		Vos données personnelles sont collectées et traitées dans le cadre des activités contractuelles nécessaires à
		l’exécution du contrat de travail que vous avez conclu avec l’une des filiales du Responsable du traitement.
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		La base juridique de cette finalité est l’article 6&#xa0;1.&#xa0;b) du RGPD, à savoir le traitement est nécessaire
		à l'exécution d'un contrat auquel la personne concernée est partie ou à l'exécution de mesures précontractuelles
		prises à la demande de celle-ci.
	</p>
	<p style="margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal">
		Le Responsable du traitement ne pourrait pas vérifier la validité de l’accès au programme de fidélité des Salariés
		et Associés et ainsi leur faire bénéficier des avantages associés sans utiliser vos données personnelles.
	</p>
	<p style="margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Vos données personnelles sont également collectées et traitées aux fins des intérêts
			légitimes poursuivis par le responsable du traitement ou par un tiers, à savoir :</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<div style="margin-left:18pt; ">
		<p class="ListParagraph"
			style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:Arial">-</span></span><span
				style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Prévention de la fraude – finalité 1.</span>
		</p>
	</div>
	<div style="margin-left:18pt; ">
		<p style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:Arial">-</span></span><span
				style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Analyse de passage – finalité 2.</span>
		</p>
	</div>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">La base juridique de cette finalité est l’article 6</span><span
			style="color:#302030">&#xa0;</span><span style="color:#302030">1.</span><span
			style="color:#302030">&#xa0;</span><span style="color:#302030">f) du RGPD, à savoir le traitement est nécessaire
			aux fins des intérêts légitimes poursuivis par le responsable du traitement ou par un tiers, à moins que ne
			prévalent les intérêts ou les libertés et droits fondamentaux de la personne concernée qui exigent une
			protection des données à caractère personnel.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<strong><span style="color:#302030">&#xa0;</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Le Responsable du traitement a besoin d'utiliser vos données à caractère personnel
			pour protéger ses intérêts légitimes ou ceux des tiers.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong>5.</strong><strong>&#xa0;</strong><strong>A qui vos données personnelles sont-elles
			communiquées</strong><strong>&#xa0;</strong><strong>?</strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		Vos données personnelles sont accessibles à certaines catégories de salariés de B.K. &amp; A et de ses filiales en
		charge du fonctionnement du programme de fidélité, à savoir&#xa0;:
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		&#xa0;
	</p>
	<div style="">
		<p class="ListParagraph"
			style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>aux salariés du service ressources humaines du Responsable du traitement&#xa0;;
		</p>
	</div>
	<div style="">
		<p class="ListParagraph"
			style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span>aux salariés du service comptabilité du Responsable du traitement&#xa0;;
		</p>
	</div>
	<div style="">
		<p class="ListParagraph"
			style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<strong><span style="color:#302030"><span
						style="font-family:'Times New Roman'; font-weight:normal">-</span></strong></span><span
				style="width:14.34pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">aux salariés de la société ATHENA SAS, filiale du Responsable du
				traitement.</span>
		</p>
	</div>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Vos données personnelles peuvent également être accessibles, dans certains cas, aux
			sous-traitants du Responsable du traitement ou de l’une des filiales du Responsable du traitement, à
			savoir</span><span style="color:#302030">&#xa0;</span><span style="color:#302030">notamment :</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<div style="">
		<p class="ListParagraph"
			style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:10.54pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">aux prestataires informatiques</span><span
				style="color:#302030">&#xa0;</span><span style="color:#302030">;</span>
		</p>
	</div>
	<div style="">
		<p class="ListParagraph"
			style="margin-left:18pt; margin-bottom:0pt; text-indent:-18pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:10.54pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">aux fournisseurs de services d’hébergement.</span>
		</p>
	</div>
	<p class="ListParagraph"
		style="margin-left:18pt; margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong><span style="color:#302030">6.</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">Vos données sont-elles
				transférées à des pays extérieurs à l’UE</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Vos données personnelles ne sont pas transférées vers un pays tiers à l’Union
			européenne ou à une organisation internationale.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong>7.</strong><strong>&#xa0;</strong><strong>Combien de temps </strong><strong><span
				style="color:#302030">est-ce que nous conservons vos données</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<strong><span style="color:#302030">&#xa0;</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Nous supprimerons vos données personnelles dès lors que leur conservation ne sera plus
			nécessaire à l’accomplissement des finalités de traitement susmentionnées. </span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">En d’autres termes, vos données personnelles seront supprimées</span><span
			style="color:#302030">&#xa0;</span><span style="color:#302030">dès la cessation de votre contrat de travail ou
			avant la cessation de votre contrat de travail si l’exécution de ce dernier n’implique plus l’utilisation de
			l’Application magasin. Dans ces cas, votre accès à l’Application magasin et les données renseignées dans le
			cadre de son utilisation seront supprimés.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; font-size:12pt; ">
		<strong><span style="color:#302030">8.</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">Quels sont vos
				droits</span></strong><strong><span style="color:#302030">&#xa0;</span></strong><strong><span
				style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Vous pouvez exercer les droits mentionnés aux articles 15 à 22 du RGPD, étant précisé
			que certains droits sont octroyés uniquement pour certaines bases juridiques de traitement.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Au cas présent, vous disposez en particulier</span><span
			style="color:#302030">&#xa0;</span><span style="color:#302030">:</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:28.21pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit d’accès :</span></strong><span style="font-family:Poppins">
				vous avez le droit d’obtenir confirmation que vos données personnelles sont ou non traitées par le Responsable
				du traitement.</span>
		</li>
		<li
			style="margin-left:28.21pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit de rectification :</span></strong><span
				style="font-family:Poppins"> vous avez le droit d’obtenir la rectification d’informations inexactes ou
				incomplètes vous concernant.</span>
		</li>
		<li
			style="margin-left:28.21pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit de retirer votre consentement :</span></strong><span
				style="font-family:Poppins"> vous pouvez, pour les traitements qui seraient fondés sur le consentement,
				retirer à tout moment le consentement que vous avez donné pour un traitement.</span>
		</li>
		<li
			style="margin-left:28.21pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit à l’effacement ou droit à l’oubli :</span></strong><span
				style="font-family:Poppins"> vous avez le droit d’obtenir l’effacement de vos données personnelles lorsque
				l’un des motifs suivants s’applique :</span>
		</li>
	</ul>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Les données ne sont plus nécessaires au regard des finalités pour lesquelles
				elles ont été collectées</span>
		</p>
	</div>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Vous retirez votre consentement au traitement et il n’existe pas d’autre
				fondement juridique au traitement</span>
		</p>
	</div>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Vous vous opposez au traitement de vos données personnelles et il n’existe
				pas de motif légitime impérieux pour le traitement</span>
		</p>
	</div>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Les données font l’objet d’un traitement illicite</span>
		</p>
	</div>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Les données doivent être effacées pour respecter une obligation
				légale.</span>
		</p>
	</div>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:28.21pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit à la limitation</span></strong><strong><span
					style="font-family:Poppins; ">&#xa0;</span></strong><strong><span style="font-family:Poppins; ">:
				</span></strong><span style="font-family:Poppins">vous avez le droit de demander au Responsable du traitement
				de suspendre temporairement l’utilisation de certaines de vos données, notamment dans l’un des cas
				suivants</span><span style="font-family:Poppins">&#xa0;</span><span style="font-family:Poppins">:</span>
		</li>
	</ul>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Vous contestez l’exactitude des données collectées</span>
		</p>
	</div>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Le traitement est illicite, vous vous opposez à leur effacement et exigez à
				la place la limitation de leur utilisation</span>
		</p>
	</div>
	<div style="margin-left:35.55pt; margin-bottom:6pt; ">
		<p style="margin-left:17.85pt; margin-bottom:0pt; text-indent:-17.85pt; text-align:justify; line-height:normal">
			<span style="color:#302030"><span style="font-family:'Times New Roman'">-</span></span><span
				style="width:14.19pt; ; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;
			</span><span style="color:#302030">Le Responsable du traitement n’a plus besoin des données à caractère
				personnel aux fins du traitement mais celles-ci vous sont encore nécessaires pour la contestation, l’exercice
				ou la défense de vos droits en justice</span>
		</p>
	</div>
	<ul style="margin:0pt; padding-left:0pt">
		<li
			style="margin-left:28.21pt; margin-bottom:6pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit à la portabilité :</span></strong><span
				style="font-family:Poppins"> vous avez le droit d’obtenir les données personnelles que vous avez fournies au
				Responsable du traitement dans un format structuré, couramment utilisé et lisible par machine pour que vous
				puissiez les transmettre à un autre responsable de traitement. Ce droit est applicable lorsque le traitement
				est fondé sur votre consentement ou sur l’exécution d’un contrat, et qu’il est effectué à l’aide de procédés
				automatisés.</span>
		</li>
		<li
			style="margin-left:28.21pt; margin-bottom:0pt; text-align:justify; line-height:normal; padding-left:7.79pt; font-family:serif; color:#302030; ">
			<strong><span style="font-family:Poppins; ">du droit d’introduire une réclamation auprès de la Commission
					Nationale de l’Informatique et des Libertés (CNIL).</span></strong>
		</li>
	</ul>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Outre ce qui précède, nous vous informons que vous avez le droit de définir des
			directives générales et particulières précisant la manière dont vous entendez que soient exercés, après votre
			décès, les droits mentionnés ci-dessus. </span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Ces directives sont adressées au Responsable du traitement par courrier postal, à
			l’adresse mentionnée au point 1. ou par courrier électronique, à l’adresse mentionnée au point 9., accompagnées
			d'une copie d'un titre d'identité signé.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Le Responsable du traitement devra, sans retard excessif et en tout cas, au plus tard
			à l’expiration d’un mois à compter de l’exercice de votre droit, vous communiquer toute l’information requise
			et/ou vous informer des actions entreprises en réponse à votre demande.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Seulement en présence de demandes manifestement infondées, excessives ou répétées, le
			Responsable du traitement pourra exiger le paiement de frais raisonnables ou refuser de donner suite à ces
			demandes.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Enfin, il convient de noter qu’en cas d’exercice de l’un ou l’autre des droits énoncés
			ci-dessus, il pourra vous être demandé des informations permettant de confirmer votre identité.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<strong><span style="color:#302030">9.</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">Comment exercer vos
				droits</span></strong><strong><span style="color:#302030">&#xa0;</span></strong><strong><span
				style="color:#302030">?</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Pour exercer vos droits et pour toute question ou difficulté relative au traitement de
			vos données personnelles et de vos droits, vous pouvez contacter le Responsable du traitement par mail à
			l’adresse suivante</span><span style="color:#302030">&#xa0;</span><span style="color:#302030">: </span><a
			href="mailto:marketing@groupebk.com" style="text-decoration:none"><span class="Hyperlink"><span
					class="__cf_email__"
					data-cfemail="4f222e3d242a3b2621280f283d203a3f2a2d24612c2022">marketing@groupebk.com</span></span></a><span
			style="color:#302030">.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Votre droit d’introduire une réclamation auprès de la CNIL peut être exercé en
			adressant votre réclamation à l’adresse postale suivante</span><span style="color:#302030">&#xa0;</span><span
			style="color:#302030">: CNIL, 3 place de Fontenoy, TSA 80715, 75334 PARIS CEDEX 07.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<strong><span style="color:#302030">10.</span></strong><strong><span
				style="color:#302030">&#xa0;</span></strong><strong><span style="color:#302030">Mise à jour de la politique de
				confidentialité</span></strong>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Eu égard à toute mise à jour des règles relatives au traitement des données
			personnelles, cette Politique de confidentialité pourra être modifiée et/ou mise à jour par le Responsable du
			traitement à tout moment.</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">&#xa0;</span>
	</p>
	<p style="margin-bottom:0pt; text-align:justify; line-height:normal; ">
		<span style="color:#302030">Le cas échéant, vous serez informé(e) des modifications par tout moyen et les
			modifications prendront effet dans tous les cas au moment de leur communication ou de la publication sur
			l’application de la Politique de confidentialité modifiée.</span>
	</p>
  </div>`;

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    legalsContainer: {
      padding: '20px',
      maxWidth: '1280px',
      margin: '0 auto',
    },
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.legalsContainer}>
    <div dangerouslySetInnerHTML={{ __html: legals }} />
  </div>
};

export default Legals;