// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { createUseStyles } from "react-jss";
import Title from '../components/Title';
import Api from '../contexts/Api';
import Formfields from '../components/Formfields';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Brands = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  const [brands, setBrands] = useState({ all: [], filtered: [] });
  const [cities, setCities] = useState([]);
  const [filter, setFilter] = useState({ byBrand: '', byCity: '' });

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    filter: {
      position: 'sticky',
      top: 20,
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: '20px',
      padding: '20px',
      margin: '0 20px',
      borderRadius: '10px',
      background: 'white',
      boxSizing: 'border-box',
      '& select[name="city"]': {
        textTransform: 'capitalize',
      },
      zIndex: 9
    },
    listBrands: {
      padding: '20px',
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '20px',
      '& h3': {
        paddingLeft: 0
      },
      '& h4': {
        fontWeight: 'normal',
      },
      '& h3, h4': {
        fontFamily: 'Poppins'
      }
    },
    listStores: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: '20px',
      '& .flex': {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr',
        gap: '10px',
        '& .cgu': {
          textDecoration: 'none!important',
        }
      }
    }
  })
  const classes = useStyle();

  //
  // ─── SEARCH REQUEST TO GET ALL BRANDS (GROUPEBK AND BK AMBITIONS EXCLUDED) ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      const params = {
        endpoint: '/brands',
        query: { store: true, brands_to_exclude: ["a7a5d846-d8db-4533-a6f4-3e71735c1f8d", "51914131-4943-49b3-955f-53738211fe29"] },
        method: 'GET',
      }
      const getBrands = await Api(params);

      if (getBrands.success) {
        setBrands({ all: getBrands.data, filtered: [] });
        let cities = [];
        getBrands.data.forEach(brand => {
          brand.stores.forEach(store => {
            if (store.city) cities.push(store.city.toLowerCase());
          });
        });
        cities = [...new Set(cities)];
        setCities(cities.sort());
      } else {
        setBrands({ all: [], filtered: [] });
      }
    };
    fetchBrands();
  }, []);


  // FILTER BRANDS
  useEffect(() => {
    multiPropsFilter()
  }, [filter]);

  const multiPropsFilter = () => {
    let filterBrandList = brands.all.filter(brand => {
      let filteredStores = brand.stores;
      if (filter.byCity.length && filter.byCity !== '-') {
        filteredStores = brand.stores.filter(store => {
          return store.city?.toLowerCase() === filter.byCity;
        });
      } else {
        brand.filteredStores = brand.stores;
      }

      let conditions = [
        filter.byBrand !== '-' && filter.byBrand.length ? brand.brand_id === filter.byBrand : true,
        filteredStores.length > 0
      ];

      if (conditions.every(Boolean)) {
        brand.filteredStores = filteredStores;
      }

      return conditions.every(Boolean);
    });
    if (!filterBrandList.length) filterBrandList = [{ none: true }]
    setBrands({ ...brands, filtered: filterBrandList })
  };

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'select', component: 'select', name: 'brand_id', label: "Enseigne", size: '49%', handleChange: (brand_id) => setFilter({ ...filter, byBrand: brand_id }), data: brands.all.map((brand) => { return { id: brand.brand_id, name: brand.name } }) },
    { type: 'select', component: 'select', name: 'city', label: "Ville", size: '49%', handleChange: (city) => setFilter({ ...filter, byCity: city }), data: cities.map((city) => { return { id: city, name: city } }) },
  ];

  let brandsList = brands.all
  if (brands.filtered.length || brands.filtered.none) brandsList = brands.filtered

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Title text='Enseignes' return={true} />
    <div className={classes.filter}>
      {formFields.map((field) => {
        return <Formfields key={field.name} field={{ ...field }} />
      })}
    </div>
    <div className={classes.listBrands}>
      {brandsList && brandsList.map(brand => {
        let listStores = brand.stores;
        console.log('stores', brand.stores)
        if (brand.filteredStores) listStores = brand.filteredStores;

        return brand.none ? <div>Pas d'enseignes</div> : <div key={brand.brand_id}>
          <Title text={brand.name} tag='3' brand_id={brand.brand_id} logo={brand.logo} />
          <div className={classes.listStores}>
            {listStores && listStores.map(store => {
              //const encodedAddress = encodeURIComponent(store.address, store.cp, store.city);
              return store.address && <div key={store.store_id}>
                <h4>{store.name.split(" - ")[0]}</h4>
                <div className='flex'>
                  {store.url && <a className='cgu' href={store.url} target='_blank'>Site internet</a>}
                  {/* {store.address && <a className='cgu' href={`https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`} target='_blank'>Itinéraire</a>} */}
                </div>
                <div>{store.address}</div>
                <div>{store.cp} {store.city}</div>
                <div>{store.email}</div>
                <div>{store.opening_hours}</div>
              </div>
            })}
          </div>
        </div>
      })}
    </div>
  </div>
};

export default Brands;