// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// 
// ─── IMPORTING MATERIAL UI ICON ───────────────────────────────────────
//
import RestartAltIcon from '@mui/icons-material/RestartAlt';

//
// ─── UTILS ────────────────────────────
//
import Api from '../../../contexts/Api';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../FormfieldsBack';

// 
// ─── IMPORTING SCSS ───────────────────────────────────────
//
import './Analytics.scss'
import Export from '../Export';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const UsersBar = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // ANALYTICS
  const [analytics, setAnalytics] = useState([]);
  const [storesUser, setStoresUser] = useState([]);
  // BRANDS
  const [brands, setBrands] = useState([]);
  // STORES
  const [stores, setStores] = useState([]);

  //
  // ─── SEARCH REQUEST TO GET BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      const getBrands = await Api({
        endpoint: '/brands',
        query: { fields: ['name', 'brand_id'], orderBy: 'name' },
        method: 'GET'
      });

      if (getBrands.success && getBrands.data.length) {
        setBrands(getBrands.data);
      }
    };
    if (!brands.length) fetchBrands();
  }, [brands])

  //
  // ─── SEARCH REQUEST TO GET USERS BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchAnalytics = async () => {
      const getAnalytics = await Api({
        endpoint: '/analytics/users',
        query: { brands: true },
        method: 'GET'
      });

      if (getAnalytics.success && getAnalytics.data.length) {
        setAnalytics(getAnalytics.data);
      }
    };
    if (!analytics.length) fetchAnalytics();
  }, [analytics]);

  //
  // ─── SEARCH REQUEST TO GET USERS STORES ───────────────────────────────────────
  //
  const fetchStoresAnalytics = async (brand_id) => {

    const getAnalytics = await Api({
      endpoint: '/analytics/users',
      query: { stores: true, brand_id: brand_id },
      method: 'GET'
    });

    if (getAnalytics.success && getAnalytics.data.length) {
      setStoresUser(getAnalytics.data);
    } else {
      setStoresUser([])
    }
  };

  const fetchStores = async (brand_id) => {
    if (brand_id === 'all') {
      setStores([])
      setStoresUser([])
    }
    else {
      const getStores = await Api({
        endpoint: '/stores',
        query: { fields: ['name', 'store_id'], brand_id: brand_id, analytics: 1 },
        method: 'GET'
      });
      if (getStores.success && getStores.data.length) {
        setStores(getStores.data);
        fetchStoresAnalytics(brand_id)
      }
    }
  }

  let analyticsList = analytics
  let storesList = storesUser || []

  // ON BOUCLE POUR AJOUTER 0 EN DONNEE SI PAS D ANALYTICS 
  brands.map((value) => {
    if (analyticsList.find(e => e.brand_id === value.brand_id) === undefined) {
      let obj = {
        brand_id: value.brand_id,
        users: 0,
        name: value.name
      }
      analyticsList.push(obj)
    }
  })


  stores.map((value) => {
    if (storesList.find(e => e.store_id === value.store_id) === undefined) {
      let obj = {
        store_id: value.store_id,
        users: 0,
        name: value.name
      }
      storesList.push(obj)
    }
  })


  // ON TRIE ET RANGE L ARRAY PAR NOM D ENSEIGNE POUR MATCH L'ARRAY DES LABELS
  analyticsList.sort((a, b) => a.name.localeCompare(b.name))
  storesList.sort((a, b) => a.name.localeCompare(b.name))

  const data = {
    labels: stores.length ? stores.map(store => { return store.name }) : brands.map(brand => { return brand.name }),
    datasets: [
      {
        label: 'Nombre d\'inscrits',
        data: storesList.length ? storesList.map(users => { return users.users }) : analyticsList.map(users => { return users.users }),
        backgroundColor: '#e17f51',
      },
    ],
    options: {
      scales: {
        y: {
          ticks: {
            stepSize: 1
          }
        }
      },
      barThickness: 50,
      responsive: true,
      maintainAspectRatio: false
    }
  };

  // PLUGIN FOR NO DATA MESSAGE
  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every(item => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "30px Arial";
          ctx.fillText("Pas de données", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  return <div style={{ width: '100%' }}>
    <div className='text'>Filtrer par enseigne</div>
    <div className='selectContainer'>
      <Formfields field={{ type: 'select', component: 'select', name: 'brands', size: '25%', admin: true, all: true, default: 'all', handleChange: (brand) => fetchStores(brand), data: brands.map((brand) => { return { id: brand.brand_id, name: brand.name }; }) }} />
    </div>

    <div className='barContainer'> <Bar options={data.options} data={data} plugins={plugins} /> </div>
  </div>
}

export default UsersBar;