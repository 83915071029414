// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import { Avatar, Paper, Tooltip, IconButton } from '@mui/material';
import WestIcon from '@mui/icons-material/West';

// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';
import { formatDate, timeStamp } from '../../../contexts/Utils';
import { useNotification } from '../../../components/UseNotification';

// ICONS
import EditIcon from '@mui/icons-material/Edit';
import InventoryIcon from '@mui/icons-material/Inventory';

// ─── IMPORTING POP OVER  ───────────────────────────────────────
import AdminAlertPopOver from '../../../components/Modals/Admin/AdminAlertPopOver';

//PNG
import LogoBK from '../../../assets/static/LOGOBK.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const UserDetails = () => {
  // Récupérer l'id de l'utilisateur
  const params = useParams();

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [user, setUser] = useState({})
  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState({ open: false, function: '', text: '' });

  // Go back
  const navigate = useNavigate()
  // Notification user
  const { notification } = useNotification()

  //
  // ─── SEARCH REQUEST TO GET USER ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUser = async () => {
      const getUser = await Api({
        endpoint: '/users',
        query: { single_user: params.user_id },
        method: 'GET',
      });

      if (getUser.success) {
        setUser(getUser.data)
      } else {
        setUser({});
      }
    };

    if (!Object.keys(user).length) fetchUser();

  }, [user, params.user_id,]);

  // 
  // ─── HANDLE ALERT DIALOG ───────────────────────────────────────
  //
  const handleAlertDialog = (user_id) => {
    setOpenAlert({ open: true, function: () => archiveUser(user_id), text: "Archiver l'utilisateur ?" })
  };

  // 
  // ─── HANDLE USER STATUS ───────────────────────────────────────
  //
  const archiveUser = async (user_id) => {

    const manageUserStatus = await Api({
      endpoint: '/users',
      data: { user_edit: user_id, fields: { status: 4 } },
      method: 'PUT'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (manageUserStatus.success) {
      navigate(-1)
      return notification({ variant: 'success', message: manageUserStatus.message })
    } else {
      notification({ variant: 'error', message: manageUserStatus.message })
    }
  }

  return <>
    <WestIcon onClick={() => navigate(-1)} className='arrow' />
    <Paper sx={{ padding: 5, width: '900px' }}>
      <div className='containerUserProfile'>
        <div className='headerUserProfile'>
          <div>
            <h2>{user.name} {user.firstname}</h2>
            <h4>{user.reference}</h4>
          </div>
        </div>
        {user.status === 2 && [1, 7, 8].includes(userData.role) && <div className='tabsUserProfile'>
          <IconButton aria-label="editer" component={Link} to={`/admin/utilisateurs/${user.user_id}/editer`}>
            <EditIcon /> Editer
          </IconButton>
          <IconButton aria-label="archiver" onClick={() => handleAlertDialog(user.user_id)}>
            <InventoryIcon /> Archiver
          </IconButton>
        </div>}
      </div>

      <div className='containerInfosUserProfile'>
        <div className='infos'>
          <span className='infosUserProfile'>
            <p className='labelInfosUserProfile'>Email :</p>
            <p>{user.email ? user.email : 'Non renseigné'}</p>
          </span>

          <span className='infosUserProfile'>
            <p className='labelInfosUserProfile'>Téléphone :</p>
            <p>{user.phone ? user.phone : 'Non renseigné'}</p>
          </span>

          <span className='infosUserProfile'>
            <p className='labelInfosUserProfile'>Date de naissance :</p>
            <p>{user.birth_date ? formatDate({ time: user.birth_date, display: 'date' }) : 'Non renseigné'}</p>
          </span>

          <span className='infosUserProfile'>
            <p className='labelInfosUserProfile'>Addresse :</p>
            <p>{user.address ? user.address : 'Adresse non renseigné'} - {user.cp ? user.cp : 'Code postal non renseigné'} {user.city ? user.city : 'Ville non renseigné'}</p>
          </span>
          <span className='infosUserProfile'>
            <p className='labelInfosUserProfile'>Enseigne :</p>
            <p>{user.brandName ? user.brandName : 'Non renseigné'}</p>
          </span>
          <span className='infosUserProfile'>
            <p className='labelInfosUserProfile'>Boutique :</p>
            <p>{user.storeName ? user.storeName : 'Non renseigné'}</p>
          </span>
        </div>
        <div className='userPicture'>
          <Avatar src={user.thumbnail ? `${process.env.REACT_APP_URL}/img/users/${user.user_id}/${user.thumbnail}?${timeStamp()}` : LogoBK} sx={{ width: '12rem', height: '12rem', boxShadow: 'rgb(0 0 0 / 12%) 0rem 0.3125rem 0.625rem 0rem' }} />
        </div>
      </div>
    </Paper>
    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert.open} text={openAlert.text} func={openAlert.function} onClose={() => setOpenAlert({ open: false })} />
  </>
}

export default UserDetails