// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

//
// ─── UTILS ────────────────────────────
//
import Api from '../../../contexts/Api';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../FormfieldsBack';

// 
// ─── IMPORTING SCSS ───────────────────────────────────────
//
import './Analytics.scss'
import Export from '../Export';
import Cookies from 'universal-cookie';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarAdmin = ({ brands }) => {
  // FONTAWESOME
  library.add(faRotateRight)
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // ANALYTICS
  const [analytics, setAnalytics] = useState({ all: [], filtered: [] });
  const [storesUser, setStoresUser] = useState([]);
  // BRANDS
  const [brandId, setBrandId] = useState('');
  // STORES
  const [stores, setStores] = useState([]);
  // USERS
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState('');
  // POP UP USERS
  const [popUpUser, setPopUpUser] = useState(0);
  // EMPTY STATE
  const [empty, setEmpty] = useState(false);

  // DATE FILTER
  const [dateFilter, setDateFilter] = useState({ start: '', end: '', reset: false });

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  //
  // ─── SEARCH REQUEST TO GET USERS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchAnalytics = async () => {
      const getAnalytics = await Api({
        endpoint: '/users',
        query: { fields: ['user_id', 'name', 'firstname'], deleted: 0, status: 2, not_role: 3 },
        method: 'GET'
      });

      if (getAnalytics.success && getAnalytics.data.length) {
        setUsers(getAnalytics.data);
      }
    };
    if (!users.length) fetchAnalytics();
  }, [users]);

  //
  // ─── SEARCH REQUEST TO GET GAINS POINTS INFOS ───────────────────────────────────────
  //
  const fetchAnalytics = async (user_id) => {
    setDateFilter({ ...dateFilter, reset: false });
    setEmpty(false)
    let query = { brands: true };
    if (user_id) query.user = user_id;
    const getAnalytics = await Api({
      endpoint: '/analytics/scan',
      query,
      method: 'GET'
    });

    if (getAnalytics.success && getAnalytics.data.length) {
      setAnalytics({ all: getAnalytics.data, filtered: [] });
    } else {
      if (getAnalytics.empty) return setEmpty(true)
      setAnalytics({ all: [], filtered: [] });
    }
  };

  useEffect(() => {
    if (!analytics.all.length || (dateFilter.reset && !userId.length)) fetchAnalytics();
    if (dateFilter.reset && userId.length) fetchAnalytics(userId)
  }, [analytics]);

  //
  // ─── SEARCH REQUEST TO GET STORES INFOS ───────────────────────────────────────
  //
  const fetchStoresAnalytics = async (brand_id, user_id) => {
    setEmpty(false)
    let query = { stores: true, brand_id: brand_id };
    if (user_id) query.user = user_id;
    if (dateFilter.start) query.time_create = dateFilter.start
    if (dateFilter.end) query.time_end = dateFilter.end
    const getAnalytics = await Api({
      endpoint: '/analytics/scan',
      query,
      method: 'GET'
    });

    if (getAnalytics.success && getAnalytics.data.length) {
      setStoresUser(getAnalytics.data);
    } else {
      if (getAnalytics.empty) return setEmpty(true)
      setStoresUser([])
    }
  };

  const fetchStores = async (brand_id) => {
    if (brand_id === 'all') {
      setStores([])
      setStoresUser([])
      fetchAnalytics(userId)
    }
    else {
      let query = { fields: ['name', 'store_id'], brand_id: brand_id, analytics: 1 };
      if (dateFilter.start) query.time_create = dateFilter.start
      if (dateFilter.end) query.time_end = dateFilter.end
      const getStores = await Api({
        endpoint: '/stores',
        query,
        method: 'GET'
      });
      if (getStores.success && getStores.data.length) {
        setStores(getStores.data);
        fetchStoresAnalytics(brand_id)
      }
    }
  }

  //
  // ─── SEARCH REQUEST TO GET PASSAGE SCAN INFOS BY DATE ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchAnalytics = async () => {
      setEmpty(false)
      const getAnalytics = await Api({
        endpoint: '/analytics/scan',
        query: { brands: true, time_create: dateFilter.start, time_end: dateFilter.end },
        method: 'GET'
      });

      if (getAnalytics.success) {
        setAnalytics({ ...analytics, filtered: getAnalytics.data });
      } else {
        if (getAnalytics.empty) return setEmpty(true)
        setAnalytics({ all: [], filtered: [] });
        setStoresUser([])
      }
    };
    if (dateFilter.start || dateFilter.end) fetchAnalytics();
    else setAnalytics({ ...analytics, filtered: [] });
  }, [dateFilter]);

  let analyticsList = analytics.all
  if (analytics.filtered.length || empty) analyticsList = analytics.filtered

  let storesList = storesUser || []
  if (empty) storesList = []

  // ON BOUCLE POUR AJOUTER 0 EN DONNEE SI PAS D ANALYTICS 
  brands.map((value) => {
    if (analyticsList.find(e => e.brand_id === value.brand_id) === undefined) {
      let obj = {
        brand_id: value.brand_id,
        passageNumber: 0,
        name: value.name
      }
      analyticsList.push(obj)
    }
  })

  stores.map((value) => {
    if (storesList.find(e => e.store_id === value.store_id) === undefined) {
      let obj = {
        store_id: value.store_id,
        passageNumber: 0,
        name: value.name
      }
      storesList.push(obj)
    }
  })

  // ON TRIE ET RANGE L ARRAY PAR NOM D ENSEIGNE POUR MATCH L'ARRAY DES LABELS
  analyticsList.sort((a, b) => a.name.localeCompare(b.name))
  storesList.sort((a, b) => a.name.localeCompare(b.name))

  const data = {
    labels: stores.length ? stores.map(store => { return store.name }) : brands.map(brand => { return brand.name }),
    datasets: [
      {
        label: 'Nombre de passage en caisse',
        data: storesList.length ? storesList.map(passage => { return passage.passageNumber }) : analyticsList.map(passage => { return passage.passageNumber }),
        backgroundColor: '#e17f51',
      },
    ],
    options: {
      scales: {
        y: {
          ticks: {
            stepSize: 1
          }
        }
      },
      barThickness: 50,
      responsive: true,
      maintainAspectRatio: false
    }
  };

  // PLUGIN FOR NO DATA MESSAGE
  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every(item => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "30px Arial";
          ctx.fillText("Pas de données", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  // GET USER POP UP COUNT
  useEffect(() => {
    const fetchUserPopUpCount = async () => {
      const query = { user_to_fetch: userId };

      if (dateFilter.start) query.time_create = dateFilter.start;
      if (dateFilter.end) query.time_end = dateFilter.end;

      const getData = await Api({ endpoint: '/tracking', query, method: 'GET' });

      if (getData.success) {
        setPopUpUser(getData.data.popupTracking);
      } else {
        setPopUpUser(0);
      }
    };

    if (userId) fetchUserPopUpCount();

  }, [userId, dateFilter])

  // HANDLE SEARCH USER AND STORES
  const handleSearch = (user_id) => {
    setUserId(user_id);

    if (user_id !== null && stores.length) {
      fetchStoresAnalytics(brandId, user_id);
      return;
    }

    if (user_id !== null) {
      fetchAnalytics(user_id);
      return;
    }

    if (stores.length) {
      fetchStoresAnalytics(brandId);
    }
  }

  const usersList = users.filter(user => user.name !== null && user.firstname !== null)

  return <div style={{ width: '100%' }}>
    <div>
      <div className='barFilterContainer'>
        <div className='flexRowContainer'>
          <div className='flexColumnContainer'>
            <div className='text'>Filtrer par date</div>
            <div className='dates'>
              <button className='resetButton' onClick={() => setDateFilter({ start: '', end: '', reset: true })}><FontAwesomeIcon icon={faRotateRight} /></button>
              <div className='datesInput'>
                <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_create', admin: true, start: true, size: '50%', reset: dateFilter.reset, handleChange: (e) => setDateFilter({ ...dateFilter, start: e, reset: false }) }} />
                -
                <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_end', admin: true, size: '50%', className: 'needMargin', reset: dateFilter.reset, handleChange: (e) => setDateFilter({ ...dateFilter, end: e, reset: false }) }} />
              </div>
            </div>
          </div>
          {[1].includes(userData.role) && <>
            <div className='flexColumnContainer'>
              <div className='text'>Filtrer par enseigne</div>
              <div className='maxHeight'>
                <Formfields field={{ type: 'select', component: 'select', name: 'brands', size: '100%', admin: true, all: true, default: 'all', handleChange: (brand) => { setBrandId(brand); fetchStores(brand) }, data: brands.map((brand) => { return { id: brand.brand_id, name: brand.name }; }) }} />
              </div>
            </div>
            <div className='flexColumnContainer'>
              <div className='text'>Rechercher un utilisateur</div>
              <div className='maxHeight'>
                <Formfields field={{ type: 'text', component: 'search', name: 'user_id', placeholder: 'Rechercher', size: '100%', data: usersList, handleChange: (user_id) => handleSearch(user_id) }} />
              </div>
            </div>
          </>}
        </div>
        <Export data={analyticsList.filter(brand => brand.brand_id !== 'a7a5d846-d8db-4533-a6f4-3e71735c1f8d')} date={{ start: dateFilter.start, end: dateFilter.end }} type='caisse' />
      </div>
    </div>
    <p className='tip tipDates'>
      Laisser vide pour toutes dates confondues
    </p>
    {[1].includes(userData.role) && userId && <div className='flexRowContainer mTop'>
      <div className='text'>Ouverture(s) de la pop up</div>
      <div className='popUpCount'>{popUpUser}</div>
    </div>}

    {analyticsList.length ?
      <div className='barContainer'> <Bar options={data.options} data={data} plugins={plugins} /> </div> :
      <div>Pas de données d'analytics pour le moment.</div>
    }
  </div>
}

export default BarAdmin;