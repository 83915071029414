// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react'

// SCSS
import './ModalCgu.scss'
import Formfields from '../../Formfields'
import Api from '../../../contexts/Api'
import { useNotification } from '../../UseNotification'
import Button from '../../Button'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalCgu = ({ open, user_id, onClose }) => {

  // Notification
  const { notification } = useNotification()

  //
  // ─── HANLDE CGU ───────────────────────────────────────
  //
  const handleCgu = async () => {
    const resetCgu = await Api({
      endpoint: '/users',
      method: 'PUT',
      data: { fields: { cgu: 1 }, user_edit: user_id },
    });

    if (resetCgu.success) {
      onClose()
      return notification({ variant: 'success', message: 'Cgu acceptées' })
    } else {
      notification({ variant: 'error', message: resetCgu.message })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container-cgu two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalCgu">
        <h2>Cgu modifiées</h2>
        <div className='qrContainer'>
          <p>Nos conditions générales d'utilisation ont été modifiées. Vous pouvez les consulter <a className='cgu' href='https://bk-fidelite.fr/file/BK&A-BK-FIDELITE-CGU.pdf' target="_blank">ici</a>.</p>
          <Formfields field={{ type: 'checkbox', name: 'cgu', component: "checkbox", text: "J'accepte les conditions générales d'utilisation", checked: true, disabled: true }} />
          <Button className="button cta" text="Enregister" onClick={handleCgu} />
        </div>
      </div>
    </div>
  </div>
}

export default ModalCgu;