// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// 
// ─── UTILS ───────────────────────────────────────
//
import Api from '../contexts/Api';
import { prepareFormData, getValue } from '../contexts/Utils';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import Button from '../components/Button';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ResetPassword = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});

  // redirect the user
  let navigate = useNavigate();
  // inform the user
  const { notification } = useNotification()

  const { token } = useParams();

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "password", component: "text", id: 'password', name: "password", placeholder: "nouveau mot de passe", required: true, iconStart: 'fa-solid fa-lock', iconEnd: true },
  ]

  // 
  // ─── HANDLE USER RESET PASSWORD ───────────────────────────────────────
  // 
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'resetPassword' });

    if (data.errors) {
      setErrors({ ...errors, 'resetPassword': { ...data.errors } })
    } else {
      const resetPassword = await Api({
        data,
        endpoint: `/users/password/reset/${token}`
      })

      notification({ variant: resetPassword.success ? 'success' : 'error', message: resetPassword.message })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <form className='authForm' name='resetPassword'>
    <h1>Réinitialiser le mot de passe</h1>
    <div className='loginForm'>
      {formFields.map((field) => (<Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['resetPassword', field.name, 'message']) } }} />))}
    </div>
    <div className='buttonContainer'>
      <Button className="button secondary" text="Connexion" onClick={() => navigate("/connexion")} />
      <Button className="button third" text='Envoyer' onClick={handleChange} />
    </div>
  </form>
};

export default ResetPassword;