// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { useNavigate } from 'react-router-dom';

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { timeStamp } from '../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Title = (props) => {

  // Fontawesome library
  library.add(faCircleArrowLeft)

  // Go back
  const navigate = useNavigate()

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    titleContainer: {
      display: 'flex',
      width: '100%',
      position: 'relative',
      margin: '10px 0 30px',
      alignItems: 'center',

      '&::after': {
        content: '""',
        position: 'absolute',
        bottom: '-10px',
        left: '0px',
        background: 'var(--secondary)',
        width: '12em',
        height: '2px',
      },
      '& .imgContainer': {
        width: '40px',
        height: '30px',
        marginRight: '10px',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        }
      },
    },
    title: {
      paddingLeft: '20px',
      color: 'var(--grey)',
      fontWeight: '500',
      fontSize: 25
    },
    icons: {
      color: 'var(--orange-primary)',
      fontSize: '1.5em',
      paddingLeft: '10px',
    },
  })
  const classes = useStyle();

  const CustomBalise = props.tag ? `h${props.tag}` : `h1`;

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.titleContainer}>
    {props.return && <div onClick={() => navigate(-1)}><FontAwesomeIcon className={classes.icons} icon="fa-regular fa-circle-arrow-left" /></div>}
    {props.logo && <div className='imgContainer'>
      <img src={`${process.env.REACT_APP_URL}/img/brands/${props.brand_id}/${props.logo}?${timeStamp()}`} alt={props.text} />
    </div>}
    <CustomBalise className={classes.title}>{props.text}</CustomBalise>
  </div>
}

export default Title;