// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';

//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

// 
// ─── UTILS ───────────────────────────────────────
//
import { prepareFormData, getValue } from '../../../../contexts/Utils';
import Api from '../../../../contexts/Api'
import { useNotification } from '../../../UseNotification';

import Formfields from '../../../Admin/FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditStatusUser = ({ open, id, onUpdate, onClose }) => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});
  // Form fields cond
  const [onCheck, setOnCheck] = useState(true)
  // Admin notification
  const { notification } = useNotification()

  // 
  // ─── HANDLE DISPLAY FIELDS ───────────────────────────────────────
  //
  const handleCond = (e) => {
    const { value } = e.target.dataset
    if (+value === 2) { setOnCheck(true) } else setOnCheck(false)
  }

  // 
  // ─── HANDLE USER STATUS ───────────────────────────────────────
  //
  const handleUserStatus = async () => {
    let { fields } = prepareFormData({ formId: 'manageUserStatus' })

    let data = fields.status === '3' ? { fields, user_delete: id } : { fields, email: true, user_edit: id }

    const manageUserStatus = await Api({
      endpoint: '/users',
      data,
      method: fields.status === '3' ? 'DELETE' : 'PUT'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (manageUserStatus.success) {
      onUpdate({ fields, id, reject: fields.status === '3' ? true : false })
      setOnCheck(true)
      onClose()
      return notification({ variant: 'success', message: manageUserStatus.message })
    } else {
      notification({ variant: 'error', message: manageUserStatus.message })
    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'select', component: 'select', name: 'status', label: "Inscription de l'utilisateur", size: '100%', data: [{ id: 2, name: 'Valider' }, { id: 3, name: 'Refuser' }], default: 2, required: true, onSelect: handleCond },
    {
      type: 'select', component: 'select', name: 'deletion_reason', label: "Raison du refus", deFaultValue: 2, default: 2, size: '100%', required: true, cond: !onCheck, data: Object.values(window._DATA.types['user_refused']).map((type) => {
        return { id: type.number, name: type.label };
      }),
    },
    { type: 'text', component: 'text', multiline: true, maxRows: 6, rows: 4, name: 'comment', label: "Observation", size: '100%', cond: !onCheck },
    { type: 'text', component: 'text', name: 'reference', label: "Référence interne de l'utilisateur", size: '100%', cond: onCheck },
    {
      type: 'select', component: 'select', name: 'role', label: "Rôle de l'utilisateur", deFaultValue: 2, default: 2, size: '100%', required: true, cond: onCheck, data: Object.values(window._DATA.types['user_role']).map((type) => {
        return { id: type.number, name: type.label };
      }),
    },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Dialog
    open={open}
    onClose={() => {
      setOnCheck(true);
      onClose()
    }}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth={true}
    maxWidth={'xs'}
  >
    <DialogTitle id="alert-dialog-title">
      Validation de l'utilisateur
    </DialogTitle>
    <DialogContent>
      <Box className='adminUserForm' component="form" name='manageUserStatus'>
        {formFields.map((field) => {
          const checkCond = field.cond || !field.hasOwnProperty('cond')
          return checkCond && <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['manageUserStatus', field.name, 'message']) } }} />
        })}
      </Box>
      {/* {!onCheck && <p>Attention, cet utilisateur sera supprimé définitivement.</p>} */}
    </DialogContent>
    <DialogActions>
      <Button onClick={() => {
        setOnCheck(true);
        onClose()
      }}>Annuler</Button>
      <Button variant='contained' onClick={handleUserStatus} autoFocus>
        Confirmer
      </Button>
    </DialogActions>
  </Dialog>
};

export default EditStatusUser;