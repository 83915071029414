// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';
import { timeStamp } from '../contexts/Utils';
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const HeroBanner = ({ link }) => {

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    heroBanner: {
      marginBottom: '20px',
      height: '150px',
      width: '100%',
    },
    bannerImg: {
      objectFit: 'cover',
      height: '100%',
      width: '100%',
    }
  })
  const classes = useStyle();
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.heroBanner}>
    {/* Changer alt dynamique */}
    <img loading="lazy" className={classes.bannerImg} src={`${process.env.REACT_APP_URL}/img/${link}?${timeStamp()}`} alt="Bannière" />
  </div>
};

export default HeroBanner;