// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

// 
// ─── UTILS ───────────────────────────────────────
//
import Api from '../contexts/Api';
import { prepareFormData, getValue } from '../contexts/Utils';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import Button from '../components/Button';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ForgotPassword = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});

  // redirect the user
  let navigate = useNavigate();
  // inform the user
  const { notification } = useNotification()
  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // Check if user is already logged in. Redirect if true
  useEffect(() => {
    if (userData) navigate("/")
  }, [userData])

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "email", component: "text", name: "email", placeholder: "e-mail", required: true, iconStart: 'fa-duotone fa-envelope' }
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'resetPassword' });

    if (data.errors) {
      setErrors({ ...errors, 'resetPassword': { ...data.errors } })
    } else {
      const resetPassword = await Api({
        data,
        endpoint: '/users/password/reset'
      })

      notification({ variant: resetPassword.success ? 'success' : 'error', message: resetPassword.message })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <form className='authForm' name='resetPassword'>
    <h1>Mot de passe oublié</h1>
    <div className='loginForm'>
      {formFields.map((field) => (<Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['resetPassword', field.name, 'message']) } }} />))}
    </div>
    <div className='buttonContainer'>
      <Button className="button secondary" text="Précédent" onClick={() => navigate("/connexion")} />
      <Button className="button third" text='Envoyer' onClick={handleChange} />
    </div>
  </form>
};

export default ForgotPassword;