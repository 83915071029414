// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react'
import { createUseStyles } from 'react-jss'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPartyHorn } from '@fortawesome/pro-duotone-svg-icons'
import { faGift } from '@fortawesome/pro-duotone-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons'

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Button from '../../Button';

// SCSS
import './WheelResults.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const WheelResults = ({ open, data, onClose }) => {
  let result = data.data || {}

  // FONTAWESOME
  library.add(faPartyHorn, faGift, faXmark)

  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    close: {
      position: 'absolute',
      top: 10,
      right: 15,

      '& svg': {
        fontSize: 17
      }
    },
    title: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',

      '& svg': {
        fontSize: 25,
        color: 'var(--orange-primary)'
      }
    },
    text: {
      margin: '20px 0'
    }
  })

  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container-wheel two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalQrUser">
        <span className={classes.close} onClick={() => onClose()}><FontAwesomeIcon icon="fa-solid fa-xmark" /></span>
        <div className={classes.title}>
          {result.gain && <FontAwesomeIcon icon="fa-duotone fa-party-horn" className='fa-bounce' />}
          <h2>{data.txt}</h2>
          {result.gain && <FontAwesomeIcon icon="fa-duotone fa-gift" className='fa-bounce' />}
        </div>
        {result.gain && <p className={classes.text}>Vous avez gagné <span className='boldOrange'>{result.gain.name}</span>, utilisable chez {result.gain.brand_name}.</p>}
      </div>
    </div>
  </div>
}

export default WheelResults;