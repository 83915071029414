// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import Api from '../../../contexts/Api';
// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
} from "@mui/material";
// THEME
import { useTheme } from '@mui/material/styles';
// ICONS
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
//
// COMPONENTS
import Export from '../Export';
import Formfields from '../FormfieldsBack';
import { formatDate } from '../../../contexts/Utils';

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MarketingAnalyticsTable = ({ brands }) => {
  // FONTAWESOME
  library.add(faRotateRight)
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  const [analyticsGains, setAnalyticsGains] = useState({ all: [], filtered: [] });
  // DATE FILTER
  const [dateFilter, setDateFilter] = useState({ start: '', end: '', reset: false });
  // TABLE PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  //
  // ─── SEARCH REQUEST TO GET GAINS MARKETING INFOS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchGainsAnalytics = async () => {
      const getAnalytics = await Api({
        endpoint: '/analytics',
        query: { fields: ['status', 'brand_id', 'gain_id', 'time_create'], loyalty: 0 },
        method: 'GET'
      });

      if (getAnalytics.success && getAnalytics.data.length) {
        setAnalyticsGains({ all: getAnalytics.data, filtered: [] });
      }
    };
    if (!analyticsGains.all.length) fetchGainsAnalytics();
  }, [analyticsGains]);

  //
  // ─── PAGINATION ───────────────────────────────────────
  //
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="première page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="page précédente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="page suivante"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="dernière page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - analyticsGains.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {

    if (dateFilter.reset) setAnalyticsGains({ ...analyticsGains, filtered: [] })

    const filteredList = analyticsGains.all.filter(item => {
      const itemDate = formatDate({ time: item.time_create, display: 'datetimepicker' });
      //Compare the dates
      if (dateFilter.start.length && !dateFilter.end.length) return itemDate >= dateFilter.start
      else return itemDate >= dateFilter.start && itemDate <= dateFilter.end
    });
    if (!filteredList.length && !dateFilter.reset) filteredList.none = true
    setAnalyticsGains({ ...analyticsGains, filtered: filteredList });
  }, [dateFilter])

  // 
  // ─── FORMAT DATA TO BE USED ───────────────────────────────────────
  //
  let analyticsList = analyticsGains.all
  if (analyticsGains.filtered.length || analyticsGains.filtered.none) analyticsList = analyticsGains.filtered

  const groupedByStatus = analyticsList.reduce((result, obj) => {
    const status = obj.status;
    if (!result[status]) {
      result[status] = [];
    }
    result[status].push(obj);
    return result;
  }, []);


  let total
  let total_won
  let total_used

  let data_to_send = {}

  // DATA TABLEAU
  let played
  let won
  let used
  let percentWon
  let percentUsed

  if (groupedByStatus.length) {
    total = groupedByStatus[3] ? groupedByStatus[3].length : 0
    total_won = groupedByStatus[1] ? ((groupedByStatus[1].length / total) * 100).toFixed(1) : 0
    total_used = groupedByStatus[2] ? ((groupedByStatus[2].length / total_won) * 100).toFixed(1) : 0

    for (let i = 0; i < brands.length; i++) {
      const { name, brand_id } = brands[i];
      played = groupedByStatus[3] ? groupedByStatus[3].filter(el => el.brand_id === brand_id).length : 0
      won = groupedByStatus[1] ? groupedByStatus[1].filter(el => el.brand_id === brand_id).length : 0
      used = groupedByStatus[2] ? groupedByStatus[2].filter(el => el.brand_id === brand_id).length : 0

      percentWon = ((won / played) * 100).toFixed(1)
      percentUsed = ((used / won) * 100).toFixed(1)

      if (brand_id !== 'a7a5d846-d8db-4533-a6f4-3e71735c1f8d') {
        data_to_send[name] = {
          players: played,
          gains_won: won || 0,
          percentage_gains_won: +percentWon || 0,
          percentage_gains_used: +percentUsed || 0
        };
      }
    }

    data_to_send.played = total
    data_to_send.gains_won = groupedByStatus[1] ? groupedByStatus[1].length : 0
    data_to_send.wonTotal = +total_won
    data_to_send.usedTotal = +total_used
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div style={{ width: '100%' }}>
    <div className='barFilterContainer'>
      <div className='flexRowContainer'>
        <div className='flexColumnContainer'>
          <div className='text'>Filtrer par date</div>
          <div className='dates'>
            <button className='resetButton' onClick={() => setDateFilter({ start: '', end: '', reset: true })}><FontAwesomeIcon icon={faRotateRight} /></button>
            <div className='datesInput'>
              <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_create', admin: true, start: true, size: '50%', reset: dateFilter.reset, handleChange: (e) => setDateFilter({ ...dateFilter, start: e, reset: false }) }} />
              -
              <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_end', admin: true, size: '50%', className: 'needMargin', reset: dateFilter.reset, handleChange: (e) => setDateFilter({ ...dateFilter, end: e, reset: false }) }} />
            </div>
          </div>
        </div>
      </div>
      <Export data={data_to_send} date={{ start: dateFilter.start, end: dateFilter.end }} type='gains' />
    </div>
    <p className='tip tipDates'>
      Laisser vide pour toutes dates confondues
    </p>

    {analyticsList.length ? <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: '#e17f51' }}>
          <TableRow sx={{ '& th': { color: 'white' } }}>
            <TableCell>Enseigne</TableCell>
            <TableCell>Nombre de personnes ayant joué</TableCell>
            <TableCell>Gains gagnés</TableCell>
            <TableCell>Gains utilisés</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? brands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : brands
          ).map((brand) => {

            if (groupedByStatus.length) {
              played = groupedByStatus[3] ? groupedByStatus[3].filter(el => el.brand_id === brand.brand_id).length : 0
              won = groupedByStatus[1] ? groupedByStatus[1].filter(el => el.brand_id === brand.brand_id).length : 0
              used = groupedByStatus[2] ? groupedByStatus[2].filter(el => el.brand_id === brand.brand_id).length : 0
              percentWon = ((won / played) * 100).toFixed(1)
              percentUsed = ((used / won) * 100).toFixed(1)
            }

            return <TableRow
              hover
              key={brand.brand_id}
            >
              <TableCell>{brand.name}</TableCell>
              <TableCell>{played}</TableCell>
              <TableCell>{+percentWon || 0}%</TableCell>
              <TableCell>{+percentUsed || 0}%</TableCell>
            </TableRow>
          })}

          <TableRow hover>
            <TableCell>TOTAL</TableCell>
            <TableCell>{total}</TableCell>
            <TableCell>{+total_won}%</TableCell>
            <TableCell>{+total_used}%</TableCell>
          </TableRow>

          {
            emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )
          }
        </TableBody >
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Tous', value: -1 }]}
              colSpan={6}
              count={analyticsGains.all.length || analyticsGains.filtered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'Lignes par page',
                },
                native: true,
              }}
              labelRowsPerPage={"Lignes par page"}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + ' sur ' + count
                }
              }
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              className='paginationCustom'
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer > : <div>Pas de données d'analytics pour le moment.</div>}
  </div>
};

export default MarketingAnalyticsTable;