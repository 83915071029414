// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Api from '../../../contexts/Api';

import EditOfferForm from '../../../components/Admin/EditOfferForm';
import TitleForm from '../../../components/Admin/TitleForm';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditOffer = () => {
  const params = useParams();

  const id = params.offer_id

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offer, setOffer] = useState({})

  //
  // ─── SEARCH REQUEST TO GET OFFER ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchOffer = async () => {
      const getOffre = await Api({
        endpoint: '/offers',
        query: { offer_id: id },
        method: 'GET',
      });

      if (getOffre.success) {
        setOffer(getOffre.data);
      } else {
        setOffer({});
      }
    };
    if (!Object.keys(offer).length) fetchOffer();
  }, [offer, id]);

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='containerAdmin'>
    <TitleForm text="Modifier l'offre" />
    {Object.keys(offer).length && <EditOfferForm update={true} default={offer} type='offers' />}
  </div>
};

export default EditOffer;