// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react';

import EditActualityForm from '../../../components/Admin/EditActualityForm';
import TitleForm from '../../../components/Admin/TitleForm';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AddActuality = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='containerAdmin'>
    <TitleForm text='Ajouter une actualité' />
    <EditActualityForm />
  </div>
};

export default AddActuality;