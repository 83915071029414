// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// 
// ─── IMPORTING MATERIAL UI ───────────────────────────────────────
//
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import { prepareFormData } from '../../contexts/Utils';
import Api from '../../contexts/Api';
import { useNotification } from '../UseNotification';
import Formfields from './FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditActualityForm = (props) => {
  // redirect the user
  let navigate = useNavigate();
  const existingPost = props.default || {}
  const update = props.update || false

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});
  const [onCheck, setOnCheck] = useState(Boolean(existingPost.detail) || true)
  const [brands, setBrands] = useState([]);

  // Admin notification
  const { notification } = useNotification()

  //
  // ─── QUILL SETTINGS ───────────────────────────────────────
  //
  const [content, setContent] = useState(existingPost.content);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
  ];

  //
  // ─── SEARCH REQUEST TO GET BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      // Je lance ma requête avec l'API
      const getBrands = await Api({
        method: 'GET',
        endpoint: '/brands',
      })

      if (getBrands.success && getBrands.data.length) {
        // Je rempli mon tableau avec les données
        setBrands([{ brand_id: '-', name: 'Aucune' }, ...getBrands.data]);
      }
    };
    if (!brands.length) fetchBrands()
  }, [brands]);

  // 
  // ─── HANDLE ADD / UPDATE ACTUALITY ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'manageActuality' })

    data.fields['content'] = content
    if (data.fields['time_online'] === undefined) data.fields['time_online'] = null
    if (data.fields['time_offline'] === undefined) data.fields['time_offline'] = null

    if (data.errors) {
      setErrors({ ...errors, 'manageActuality': { ...data.errors } })
    } else {
      //
      // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
      //
      let formData = data

      if (update) {
        // si update uniquement 
        formData.actuality_id = existingPost.actuality_id
        formData.thumbnail = existingPost.thumbnail
        formData.newsletter = existingPost.newsletter
        delete data['fields']['thumbnail']
        delete data['fields']['banner']
      }

      else if (!update) {
        formData = new FormData()
        const files = [
          'thumbnail',
          'banner',
        ];

        if (data['fields']['thumbnail'] || data['fields']['banner']) {
          files.forEach((file) => {
            if (data['fields'][file]) {
              formData.append('files[]', data['fields'][file], `${file}-${data['fields'][file].name}`);
              delete data['fields'][file];
            }
          });
        }
        formData.append('fields', JSON.stringify(data.fields))
      }

      const manageActuality = await Api({
        data: formData,
        method: update ? 'PUT' : 'POST',
        endpoint: '/actualities',
        removeHeader: update ? false : true
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageActuality.success) {
        navigate(-1)
        return notification({ variant: 'success', message: manageActuality.message })
      } else {
        notification({ variant: 'error', message: manageActuality.message })
      }
    }
  }

  // 
  // ─── UPLOAD FILE ───────────────────────────────────────
  //
  const uploadFile = async (event) => {
    const element = event.target
    // 
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const formData = new FormData();
    if (element.files[0]) {
      formData.append('file', element.files[0], `${element.name}-${element.files[0].name}`);
    }
    formData.append('id', existingPost.actuality_id)
    formData.append('file', 'actualities')
    formData.append('type', element.name)
    // 
    // ─── API REQUEST FOR FILE UPLOAD ───────────────────────────────────────
    //
    const params = {
      data: formData,
      endpoint: '/files',
      method: 'POST',
      removeHeader: true
    }
    const addFile = await Api(params);
    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    if (addFile && addFile.success) {
      return notification({ variant: 'success', message: addFile.message })
    } else {
      notification({ variant: 'error', message: addFile.message })
    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const optionsThumbnail = { fileName: existingPost.thumbnail, link: `/actualities/${existingPost.actuality_id}/${existingPost.thumbnail}` }
  const optionsBanner = { fileName: existingPost.banner, link: `/actualities/${existingPost.actuality_id}/${existingPost.banner}` }

  const formFieldsColumn = [
    { type: 'text', component: 'file', name: 'thumbnail', label: "Image à la une (maximum 8mo)", ...(update && { handleChange: uploadFile }), ...(update && optionsThumbnail) },
    { type: 'text', component: 'file', name: 'banner', label: "Bannière (maximum 8mo)", ...(update && { handleChange: uploadFile }), ...(update && optionsBanner), cond: onCheck },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (existingPost || !update) &&
    <Box component="form" name='manageActuality' className='adminform'>
      <div className='columnAdminForm'>
        {formFieldsColumn.map((field) => {
          const checkCond = field.cond || !field.hasOwnProperty('cond')
          return checkCond && <Formfields key={field.name} field={field} />
        })}
      </div>
      <div className='contentAdminForm'>
        <div className='headerContent'>
          <Formfields field={{ type: 'select', component: 'select', name: 'status', label: "Statut de l'actualité", data: [{ id: 1, name: 'Brouillon' }, { id: 2, name: 'En ligne' }, { id: 3, name: 'Archivé' }], default: existingPost.status || 1, size: '90%', required: true }} />
          <Formfields field={{ type: 'select', component: 'select', name: 'brand_id', label: "Enseigne reliée à l'actualité", size: '90%', data: brands.map((brand) => { return { id: brand.brand_id, name: brand.name }; }), default: existingPost.brand_id }} />
          <Formfields field={{ type: 'switch', component: 'switch', name: 'detail', default: existingPost.detail || true, label: "Détail de l'actualité", onSelect: () => setOnCheck(!onCheck), size: '100%' }} />
        </div>
        <div>Programmer la publication :</div>
        <div className='headerContent'>
          <Formfields field={{ type: 'date', component: 'datepicker', default: existingPost.time_online, name: 'time_online', label: "Date de début", size: '100%' }} />
          <Formfields field={{ type: 'date', component: 'datepicker', default: existingPost.time_offline, name: 'time_offline', label: "Date de fin", size: '100%' }} />
        </div>
        {onCheck && <>
          <Formfields field={{ type: 'text', component: 'text', name: 'title', default: existingPost.title, label: "Titre de l'actualité", size: '100%' }} />
          <div className='titleContenu'>Contenu de l'actualité</div>
          <div style={{ width: '100%', marginBottom: '15px' }}>
            <ReactQuill style={{ width: '100%' }} value={content} onChange={(e) => setContent(e)} theme="snow" modules={modules}
              formats={formats} placeholder="Contenu de l'actualité" />
          </div>
        </>}
        {onCheck && <>
          <Formfields field={{ type: 'text', component: 'text', name: 'url', default: existingPost.url, label: "Lien", size: '100%' }} />
          <Formfields field={{ type: 'text', component: 'text', name: 'cta', default: existingPost.cta, label: "Texte dans le bouton", size: '100%' }} />
        </>}
        <Button variant="contained" onClick={handleChange}> <AddCircleOutlineIcon /> {update ? 'Enregistrer' : 'Ajouter'}</Button>
      </div>
    </Box>
};

export default EditActualityForm;