//
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react'
import "leaflet/dist/leaflet.css"
import L from 'leaflet'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { getValue } from '../contexts/Utils';
import { createUseStyles } from 'react-jss';
//
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const GeoSearch = (props) => {
  const [geo, setGeo] = useState([]);
  const [search, setSearch] = useState('');
  const [geoApi, setGeoApi] = useState({});
  const [onSelect, setOnSelect] = useState(false)

  const defaultValue = props.default || {};

  useEffect(() => {
    const timer = setTimeout(
      () =>
        search &&
        fetch(`https://api-adresse.data.gouv.fr/search/?q=${search}`)
          .then((response) => response.json())
          .then((result) => {
            if (getValue(result, ['features']).length) {
              const geoArray = [];
              result.features.forEach((value) => {
                const geoLocalisation = value.geometry
                const values = value.properties;
                let geoValues = {
                  label: values.label,
                  city: values.city,
                  cp: values.postcode,
                  address: values.type === 'municipality' ? '' : values.name,
                  x: geoLocalisation['coordinates'][1],
                  y: geoLocalisation['coordinates'][0],
                };
                geoArray.push(geoValues);
              });
              setGeo([...geoArray]);
            }
          }),
      200
    );
    // INIT MAP
    let map = L.map('map')
    // https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png //
    L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      // attribution: '&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
    }).addTo(map)
    map.setView([47.7467233, 7.3389937], 13);
    // CHANGE MAP VIEW AND ADD MARKER ON SELECT
    if (map && onSelect) {
      map.setView([geo[0].x, geo[0].y], 13)
      // MARKER ICON
      var svg = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 256c-35.3 0-64-28.7-64-64s28.7-64 64-64s64 28.7 64 64s-28.7 64-64 64z"/></svg>'
      var iconUrl = 'data:image/svg+xml;base64,' + btoa(svg)
      var icon = L.icon({
        iconUrl: iconUrl,
        iconSize: [30, 40],
      });
      L.marker([geo[0].x, geo[0].y], { icon: icon }).addTo(map)
    }
    return () => {
      clearTimeout(timer)
      map.remove()
    }
  }, [search, onSelect]);

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    error: {
      color: '#ED4E4A',
      marginTop: '10px'
    }
  })
  const classes = useStyle();

  //
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return geo && (
    <>
      <Autocomplete
        id={props.name}
        size="small"
        autoComplete
        filterOptions={(x) => x}
        options={geo.map((option) => option.label)}
        onInputChange={(event, newInputValue) => {
          setSearch(newInputValue);
        }}
        className={props.className}
        onChange={(e) => {
          const geoApiValues = geo.filter((v) => v.label === e.target.innerHTML);
          if (geoApiValues.length) {
            setGeoApi({ ...geoApiValues[0] });
          }
          setOnSelect(!onSelect)
        }}
        value={search || defaultValue.geo}
        renderInput={(params) => (
          <TextField {...params} readOnly='true' variant="standard" label="Ville de résidence *" fullWidth />
        )}
      />
      <span className={classes.error}>{props.error ? props.error : false}</span>
      <input type='hidden' name='address' value={geoApi.address} />
      <input type='hidden' name='cp' value={geoApi.cp} />
      <input required={props.required} type='hidden' name='city' value={geoApi.city} />
      <div id='map' className='mapEmbed'></div>
    </>
  )
};

export default GeoSearch;