import { Helmet } from 'react-helmet-async';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const MetaView = ({ children, title }) => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <Helmet title={title} />
    {children}
  </>
};

export default MetaView;