// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../contexts/Api';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../Formfields';
import { getValue, timeStamp } from '../../contexts/Utils';

// 
// ─── IMPORTING SCSS ───────────────────────────────────────
//
import './Hive.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Hive = ({ onSelect, defaultBrand, defaultStore, error = {} }) => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  const [brands, setBrands] = useState([]);
  const [isActive, setIsActive] = useState()
  const [onCheck, setOnCheck] = useState({ check: false, brand: defaultBrand || '' })

  // 
  // ─── FETCH BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    async function fetchBrands() {
      // Je lance ma requête avec l'API
      const getBrands = await Api({
        method: 'GET',
        query: { store: true },
        endpoint: '/brands'
      })

      if (getBrands.success && getBrands.data.length) {
        // Je rempli mon tableau avec les données
        setBrands(getBrands.data);
      }
    };
    if (!brands.length) fetchBrands()
  }, [brands])

  // 
  // ─── INIT STATE IF DATA ───────────────────────────────────────
  //
  useEffect(() => {
    if (defaultBrand) {
      setOnCheck({ check: true, brand: defaultBrand })
      setIsActive(defaultBrand)
    }
  }, [defaultBrand])

  // INIT HIVE : ROWS AND DIV
  let counterHive = 0
  let counterRow = 1

  // CHANGE CLASS OF SELECTED DIV
  const handleClick = (event, brand) => {
    setIsActive(event.target.value)
    onSelect()
    setOnCheck({ check: true, brand: brand })
  }

  let selectStore = brands.filter((brand) => brand.brand_id === onCheck.brand)

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <ul className='map'>
      {brands && brands.map(value => {
        // Counter pour positionner ensuite mes alvéoles correctement
        counterHive++
        if (counterHive === 4) {
          counterHive = 1
          counterRow++
        }
        let idContainer = `0${counterHive}0${counterRow}`
        // Hive display
        return <li className={`hive ${isActive === value.brand_id ? 'selectedDiv' : ''}`} key={value.brand_id} id={idContainer} onClick={(e) => handleClick(e, value.brand_id)}>
          <label id={idContainer}>
            <input name='brand_id' type='radio' id={idContainer} value={value.brand_id} />
            <img loading="lazy" className={`image` + value.brand_id} src={`${process.env.REACT_APP_URL}/img/brands/${value.brand_id}/${value.logo}?${timeStamp()}`} alt={value.name} id={idContainer} />
          </label>
        </li>
      })}
    </ul>
    {onCheck.check && selectStore.length && <Formfields field={{ type: 'select', required: true, default: defaultStore, component: 'select', name: 'store_id', label: "Votre boutique", size: '90%', error: getValue(error, ['registrationForm1', 'store_id', 'message']), data: onCheck.brand && selectStore[0].stores.map((store) => { return { id: store.store_id, name: store.name }; }) }} />}
  </>

};

export default Hive;