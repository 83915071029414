// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { v4 } from 'uuid'
//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

// 
// ─── UTILS ───────────────────────────────────────
//
import { prepareFormData, getValue } from '../../../../../contexts/Utils';
import Api from '../../../../../contexts/Api';
import { useNotification } from '../../../../UseNotification';

// 
// ─── FORMFIELDS ───────────────────────────────────────
//
import Formfields from '../../../../Admin/FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditGainForm = ({ open, onClose, onUpdate, data }) => {
  // Extract what we want from props
  const { brands, currentGain, level } = data

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});
  const [stores, setStores] = useState([])
  const [palier, setPalier] = useState(false)

  // Admin notification
  const { notification } = useNotification()

  //
  //─── USE EFFECT WHEN MODAL IS OPEN ───────────────────────────────────────
  //
  useEffect(() => {
    if (brands && brands.length && currentGain.brand_id) handleStore(currentGain.brand_id)
    if (currentGain.level || level) setPalier(true)
  }, [currentGain, level, brands])

  // 
  // ─── HANDLE ADD GAINS ───────────────────────────────────────
  //
  const handleEditGains = async () => {
    let { fields, errors } = prepareFormData({ formId: 'manageGains' })

    currentGain ? fields['gain_id'] = currentGain.gain_id : fields['gain_id'] = v4()
    // if (fields['time_offline'] === undefined) {
    //   const currentDate = new Date();
    //   const nextMonth = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    //   const formattedDate = nextMonth.toISOString().split('T')[0];
    //   fields['time_offline'] = formattedDate
    // }

    if (errors) {
      setErrors({ ...errors, 'manageGains': { ...errors } })
    } else {
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageGains = await Api({
        data: { fields },
        method: currentGain ? 'PUT' : 'POST',
        endpoint: '/gains',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageGains.success) {
        onUpdate(fields)
        handleExit()
        return notification({ variant: 'success', message: manageGains.message })
      } else {
        notification({ variant: 'error', message: manageGains.message })
      }
    }
  }

  // 
  // ─── HANDLE STORE SELECT ───────────────────────────────────────
  //
  const handleStore = (gain_store) => {
    let selectBrand = brands.filter((brand) => brand.brand_id === gain_store)
    let selectStore = selectBrand[0].stores.map((store) => { return { id: store.store_id, name: store.name } })
    setStores(selectStore);
  }

  // 
  // ─── HANDLE MODAL CLOSING / EXIT ───────────────────────────────────────
  //
  const handleExit = () => {
    onClose()
    setErrors({})
    setPalier(false)
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'text', component: 'text', name: 'name', default: currentGain && currentGain.name, label: "Nom du gain", size: '100%', required: true },
    // { type: 'date', component: 'datepicker', default: currentGain.time_offline, name: 'time_offline', label: "Date de fin", size: '49%' },
    { type: 'switch', component: 'switch', name: 'level', default: currentGain.level || level, label: "Gain de palier", size: '49%', disabled: true, cond: palier },
    { type: 'text', component: 'text', name: 'points', default: currentGain.points, label: "Points de fidélité nécessaires", size: '49%', required: true, cond: palier },
    {
      type: 'select', component: 'select', name: 'brand_id', label: "Enseigne", size: '100%', onSelect: (e) => handleStore(e.target.dataset.value), data: brands && brands.map((brand) => {
        return { id: brand.brand_id, name: brand.name };
      }), default: currentGain.brand_id, cond: !palier
    },
    {
      type: 'select', component: 'select', name: 'store_id', label: "Boutique", size: '100%', data: stores, default: currentGain.store_id, cond: !palier
    },
    { type: 'text', component: 'text', name: 'conditions', default: currentGain.conditions, label: "Conditions", size: '100%' },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <Dialog
    open={open}
    onClose={handleExit}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
    fullWidth={true}
    maxWidth={'md'}
  >
    <DialogTitle id="alert-dialog-title">
      {currentGain ? 'Modifier le' : 'Ajouter un'} gain
    </DialogTitle>
    <DialogContent>
      <Box className='manageStoreForm' component="form" name='manageGains'>
        {formFields.map((field) => {
          const checkCond = field.cond || !field.hasOwnProperty('cond')
          return checkCond && <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['manageGains', field.name, 'message']) } }} />
        })}
      </Box>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleExit}>Annuler</Button>
      <Button variant="contained" onClick={handleEditGains}>{currentGain ? 'Enregistrer' : 'Ajouter'} </Button>
    </DialogActions>
  </Dialog>
};

export default EditGainForm;