/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, createRef } from "react";
import { createUseStyles } from 'react-jss';
import { getValue, formatDate, b64toBlob } from '../contexts/Utils';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope } from '@fortawesome/pro-duotone-svg-icons'
import { faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { faLock } from "@fortawesome/pro-solid-svg-icons";
import { faTrashCan } from "@fortawesome/pro-solid-svg-icons";
import { faEye } from "@fortawesome/pro-solid-svg-icons";
import { faDownToBracket } from "@fortawesome/pro-duotone-svg-icons";
import Button from "./Button";
// 
// ─── TEXTFIELD ───────────────────────────────────────
//
export const Text = (opt) => {

  const field = opt.field;
  let defaultValue = getValue(field, ['default']) || field.value || ''

  // FONTAWESOME
  library.add(faEnvelope, faEyeSlash, faLock, faEye)

  const [value, setValue] = useState(defaultValue)
  const [visibility, setVisibility] = useState(false)

  const handleChange = (e) => {
    setValue(e.target.value);
    if (field.handleChange) {
      field.handleChange(e.target.value)
    }
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])



  const changeFieldType = (id) => {
    let password = document.getElementById(id)
    if (password.type === 'password') {
      password.type = 'text'
    } else {
      password.type = 'password'
    }
    setVisibility(!visibility)
  }

  // RESET VALUE INPUT ON RESET PASSWORD
  if (field.reset && value) {
    defaultValue = ''
    setValue(defaultValue)
    field.reset = false
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputStyle: {
      marginTop: '1em',
      display: 'flex',
      fontWeight: '300',
      fontSize: '20px',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: '2px solid var(--secondary)',
      paddingBottom: '5px',
      fontFamily: 'Poppins, sans-serif',

      '& input': {
        border: 'none',
        marginLeft: '5px',
        fontSize: '16px',
        padding: '5px 10px',
        background: 'none',
        width: '100%'
      },

      '& ::placeholder': {
        fontFamily: 'Poppins, sans-serif',
        color: 'var(--grey)'
      }
    },
    start: {
      display: 'flex',
      alignItems: 'center',
      width: '100%'
    },
    iconStart: {
      color: 'var(--orange-primary)',
      fontSize: '1em'
    },
    iconEnd: {
      color: 'var(--grey)',
      fontSize: '1em'
    },
    error: {
      color: '#ED4E4A',
      marginTop: '10px'
    },
    errorField: {
      borderBottom: '2px solid #ED4E4A',
    },
    activeLabel: {
      color: 'var(--orange-secondary)',
      fontWeight: 400
    },
    disabled: {
      color: 'var(--secondary)',
      fontSize: 17,
      fontWeight: 400,

      '& input': {
        color: 'var(--primary)!important'
      }
    }
  })
  const classes = useStyle();

  return <>
    <label className={`${classes.inputStyle} ${field.error ? classes.errorField : ''} ${field.disabled ? classes.disabled : classes.activeLabel}`}>
      {field.label && field.label}
      <span className={classes.start}>
        {field.iconStart && <FontAwesomeIcon className={classes.iconStart} icon={field.iconStart} />}
        <input type={field.type} id={field.id} name={field.name} value={value} onChange={handleChange} required={field.required} placeholder={field.placeholder} disabled={field.disabled} />
      </span>
      {field.iconEnd && <FontAwesomeIcon onClick={(e) => changeFieldType(field.id)} className={classes.iconEnd} icon={`${visibility ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'}`} />}
    </label>
    <span className={classes.error}>{field.error ? field.error : false}</span>
  </>
}

// 
// ─── TEXTAREA ───────────────────────────────────────
//
export const TextArea = (opt) => {

  const field = opt.field;
  const defaultValue = getValue(field, ['default']) || field.value || ''

  const [value, setValue] = useState(defaultValue)

  const handleChange = (e) => {
    setValue(e.target.value);
    if (field.handleChange) {
      field.handleChange(e.target.value)
    }
  };

  useEffect(() => {
    if (value !== defaultValue) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputStyle: {
      marginTop: '1em',
      display: 'flex',
      fontWeight: '300',
      fontSize: '20px',
      width: '100%',
      justifyContent: 'space-between',
      borderBottom: '2px solid var(--orange-primary)',

      '& textarea': {
        border: 'none',
        fontSize: '16px',
        background: 'none',
        fontFamily: 'Poppins, sans-serif'
      }
    },
    error: {
      color: '#ED4E4A',
      marginTop: '10px'
    }
  })
  const classes = useStyle();

  return <>

    <label className={classes.inputStyle} id="text-area-custom">
      {field.label && field.label}
      <textarea placeholder={field.placeholder} name={field.name} value={value} onChange={handleChange} required={field.required}></textarea>
    </label>
    <span className={classes.error}>{field.error ? field.error : false}</span>
  </>
}

// 
// ─── SELECT ───────────────────────────────────────
//
export const SelectField = (opt) => {
  const field = opt.field
  const defaults = getValue(field, ['default'])

  // Define the name and value of select items
  const dataLabel = field.dataLabel || 'name';
  const dataValue = field.dataValue || 'id'

  const [select, selectValue] = useState(defaults);

  const handleChange = (event) => {
    selectValue(event.target.value);
    if (field.handleChange) {
      field.handleChange(event.target.value)
    }
  };

  useEffect(() => {
    if (defaults && select !== defaults) {
      selectValue(defaults)
    }
  }, [defaults])

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    selectCustom: {
      border: '1px solid rgba(246, 152, 108, 0.5)',
      background: 'transparent',
      padding: 5,
      marginTop: 10,

      '&:active': {
        border: '1px solid rgba(246, 152, 108, 1)',
      }
    }
  })
  const classes = useStyle();

  return <>
    <label id="simple-select-label" shrink>{field.label}</label>
    <select
      value={select}
      name={field.name}
      id="simple-select-label"
      onChange={handleChange}
      onClick={field.onSelect}
      className={classes.selectCustom}
      required={field.required}
    >
      <option value='-'>Aucune</option>
      {field.data.map((value) => (
        <option key={value[dataValue]} value={value[dataValue]}>{value[dataLabel]}</option>
      ))}
    </select>
    <span >{field.error ? field.error : false}</span>
  </>
}

//
// ─── DATE AND TIME PICKER ───────────────────────────────────────
//
const DatePicker = (props) => {
  let field = props.field;

  let defaultDate = field.default;
  const dataSet = {};

  if (field.isoDefault || (field.filled && field.default)) {
    defaultDate = field.default
  } else {
    defaultDate = formatDate({ time: field.default, display: 'datetimepicker' }) || null
  }

  Object.entries(field.dataSet || {}).map(([key, value]) => dataSet[key] = value);

  const [date, setDate] = useState(defaultDate);
  var now = new Date();
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset());

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputStyle: {
      marginTop: '1em',
      display: 'flex',
      fontWeight: '400',
      fontSize: '16px',
      width: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderBottom: '2px solid var(--secondary)',
      paddingBottom: '5px',
      fontFamily: 'Poppins, sans-serif',
      color: 'var(--grey)',
      paddingLeft: '15px',


      '& input': {
        border: 'none',
        fontSize: '16px',
        padding: '5px 10px 5px 0',
        background: 'none'
      },
    },
    start: {
      display: 'flex',
      alignItems: 'center',
    },
    error: {
      color: '#ED4E4A',
      marginTop: '10px'
    },
    errorField: {
      borderBottom: '2px solid #ED4E4A',
    },
    disabled: {
      '& input::-webkit-calendar-picker-indicator': {
        opacity: 0,
        WebkitAppearance: 'none'
      },
      '& input': {
        color: 'var(--primary)!important'
      }
    }
  })
  const classes = useStyle();


  return <>
    <label className={`${classes.inputStyle} ${field.error ? classes.errorField : ''} ${field.disabled ? classes.disabled : ''}`}>
      {field.label && field.label}
      <span className={classes.start}>
        {field.iconStart && <FontAwesomeIcon className={classes.iconStart} icon={field.iconStart} />}
        <input type={field.type} id={field.id} name={field.name} value={field.value || date} onChange={(e) => {
          setDate(e.target.value)
          if (field.handleChange) {
            field.handleChange(e.target.value)
          }
        }} required={field.required} placeholder={field.placeholder} defaultValue={field.filled ? now.toISOString().slice(0, 16) : field.default || ''} disabled={field.disabled} />
      </span>
    </label>
    <span className={classes.error}>{field.error ? field.error : false}</span>
  </>
}

// 
// ─── CHECKBOX ───────────────────────────────────────
//
export const CheckboxField = (opt) => {

  const field = opt.field

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [checked, setChecked] = useState(field.checked || false)

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    checkbox: {
      marginTop: '1em',
      display: 'grid',
      gridTemplateColumns: '1em auto',
      gap: '0.8em',
      fontWeight: '300',
      fontSize: '17px',
      width: '100%',
      alignItems: 'center'
    },
    error: {
      color: '#ED4E4A',
      marginTop: '10px'
    },
    errorField: {
      borderBottom: '2px solid #ED4E4A',
    }
  })
  const classes = useStyle();

  return <>
    <label className={classes.checkbox}>
      <input type={field.type} id={field.id} name={field.name} checked={checked} disabled={field.disabled} required={field.required} value={field.id} onChange={handleChange} onClick={field.onClick} className='inputCheckbox' />
      <div dangerouslySetInnerHTML={{ __html: field.text }}></div>
    </label>
    <span className={classes.error}>{field.error ? field.error : false}</span>
  </>
}

// 
// ─── UPLOAD FILE ───────────────────────────────────────
//
export const UploadFile = (opt) => {
  const field = opt.field;
  const [file, setFile] = useState(field.default || '')
  let defaultImg = field.default ? URL.createObjectURL(field.default) : ''
  const [preview, setPreview] = useState(defaultImg || '')
  const [imageError, setImageError] = useState('')
  const [type, setType] = useState(field.type || 'image/png')

  // CROPPING
  const [cropping, setCropping] = useState(false)
  const [cropData, setCropData] = useState(null);
  const cropperRef = createRef();

  // FONTAWESOME
  library.add(faTrashCan, faDownToBracket)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  let useStyle = createUseStyles({
    input: {
      display:
        "none !important"
    },
    label: {
      paddingBottom: '5px',
      marginTop: '5px',
      display: 'block',
      color: 'var(--grey)',
      paddingLeft: '15px',
      '& .primary': {
        width: '45%',
        margin: '0 20px 5px 0',
        padding: '8px 20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        float: 'right'
      }
    },
    error: {
      color: '#d32f2f',
      marginTop: 5,
      display: 'block'
    },
    field: {
      width: '100%',
      border: '1px solid #F32828 !important',
      color: '#F32828 !important'
    },
    fileUploaded: {
      width: 'calc(100% - 10px)',
      alignItems: 'center'
    },
    fileContent: {
      display: 'flex',
      flexDirection: 'column',
      marginRight: 'auto'
    },
    filePreview: {
      width: '50%',
      margin: '10px 15px 0'
    },
    fileIcon: {
      fontSize: '35px !important',
      color: 'var(--orange-primary)'
    },
    fileName: {
      fontSize: 13,
      width: 275,
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      paddingLeft: '15px'
    },
    imageAndDelete: {
      display: 'flex',
      alignItems: 'flex-end'
    },
    dropZone: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 10,
      border: '2px dashed #ccc',
      padding: 10
    },
    warning: {
      color: '#F32828',
      textAlign: 'left',
      fontStyle: 'italic'
    }

  })

  const classes = useStyle();

  useEffect(() => {
    if (field.crop && field.afterCrop && cropData) {
      const newPic = b64toBlob(cropData)
      // On remplace l'input avec l'image crop
      const newFile = new File([newPic], file, { type: type })
      field.afterCrop(newFile)
    }
  }, [cropData])

  const handleChange = (e) => {

    if (e.target.files[0].size < 10000000) {
      let img = URL.createObjectURL(e.target.files[0])
      setPreview(img)
      setType(e.target.files[0].type)
      setFile(e.target.files[0].name)
      if (field.afterCrop) field.afterCrop(e.target.files[0])
    } else {
      setImageError('Image trop lourde, veuillez en sélectionner une autre.')
    }
  }

  const getCropData = () => {
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };

  const handleCrop = (e) => {
    e.preventDefault();
    if (cropping) getCropData();
    setCropping(!cropping);
  }

  return <>
    <span className={classes.label}>{opt.field.label}</span>
    <div className={classes.fileUploaded} style={{ display: file ? 'flex' : 'none', }}>
      <div className={classes.fileContent}>
        <span className={classes.fileName}>{file}</span>
        <div className={classes.imageAndDelete}>
          {field.crop && <>
            {!cropping && <img id="previewImg" className={classes.filePreview} src={cropData ? cropData : preview} alt="profile" />}
            {cropping && <Cropper
              ref={cropperRef}
              initialAspectRatio={1}
              preview=".img-preview"
              src={preview}
              viewMode={1}
              minCropBoxHeight={10}
              minCropBoxWidth={10}
              background={false}
              responsive={true}
              autoCropArea={1}
              checkOrientation={false}
              guides={true}
            />}
          </>}
          <div>
            {field.crop && <Button onClick={handleCrop} className="button fourth" text={cropping ? 'Valider' : 'Rogner'} />}
            <div aria-label="delete" onClick={() => {
              document.querySelector(`input[name='${field.name}']`).value = '';
              setFile(null);
              if (field.handleRemove) {
                field.handleRemove({ name: field.name, filename: field.default })
              }
            }}>
              <FontAwesomeIcon icon="fa-solid fa-trash-can" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className={classes.fileContainer} style={{ display: file ? 'none' : 'block' }}>
      <div className={classes.dropZone}>
        <label htmlFor={`${field.name}-file`} className={classes.label}>
          <input className={classes.input} accept="image/png, image/jpg" id={`${field.name}-file`} type="file" name={field.name} onChange={(e) => { handleChange(e) }} required={field.required ? true : false} />
          <FontAwesomeIcon className="button primary" icon="fa-duotone fa-down-to-bracket" />
        </label>
        {field.warning && <span className={classes.warning}>{field.warning}</span>}
        {(field.error || imageError) && <span className={classes.error}>{imageError || field.error}</span>}
      </div>
    </div>
  </>
}

// 
// ─── GENERATION DES CHAMPS ───────────────────────────────────────
//
const Formfields = (props) => {
  let fields

  switch (props.field.component) {
    case "checkbox":
      fields = CheckboxField(props)
      break;
    case "select":
      fields = SelectField(props)
      break;
    case "textarea":
      fields = TextArea(props)
      break;
    case "file":
      fields = UploadFile(props)
      break;
    case "datepicker":
      fields = DatePicker(props);
      break;
    default:
      fields = Text(props)
      break;
  }

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    inputContainer: {
      display: props.field.hidden ? 'none' : "flex",
      flexDirection: "column"
    }
  })

  const classes = useStyle();

  return <div className={classes.inputContainer + ' formfield_container ' + props.classNameOuter} style={{ width: props.field.size || props.field.component !== 'color' ? '100%' : 'null' }}>
    {fields}
  </div>
}

export default Formfields