// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography,
  Fab
} from "@mui/material";
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';
import { useNotification } from '../../../components/UseNotification';
import { removeHtml, timeStamp } from '../../../contexts/Utils';

// ─── STYLE ────────────────────────────
import { createUseStyles } from "react-jss";

// ─── IMPORTING POP OVER  ───────────────────────────────────────
import AdminAlertPopOver from '../../../components/Modals/Admin/AdminAlertPopOver';

// ─── IMPORTING PNG  ───────────────────────────────────────
import Default from '../../../assets/static/Default.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListOffers = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  const [offers, setOffers] = useState([]);
  const [deletedOffre, setDeletedOffre] = useState(false);
  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState({ open: false, function: '', text: '' });

  // Notification user
  const { notification } = useNotification()

  //
  // ─── SEARCH REQUEST TO GET ALL OFFRES ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchOffres = async () => {
      const getOffres = await Api({
        endpoint: '/offers',
        method: 'GET',
      });

      if (getOffres.success && getOffres.data.length) {
        setOffers(getOffres.data);
      } else {
        setOffers([]);
      }
    };
    fetchOffres();
  }, [deletedOffre]);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& h1': {
        marginBottom: 30,
      },

      '& .MuiFab-root': {
        position: 'fixed',
        right: 35,
        top: 90,
        borderRadius: '4px!important',
        fontWeight: '600!important',

        '& svg': {
          marginRight: 5
        }
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── HANDLE ALERT DIALOG ───────────────────────────────────────
  //
  const handleAlertDialog = (offer_id) => {
    setOpenAlert({ open: true, function: () => deleteOffre(offer_id), text: "Supprimer l'offre ?" })
  };

  // 
  // ─── DELETE OFFRE ───────────────────────────────────────
  //
  const deleteOffre = async (offer_id) => {
    const deleteOneOffre = await Api({
      data: { offer_id },
      method: 'DELETE',
      endpoint: '/offers'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (deleteOneOffre.success) {
      setDeletedOffre(!deletedOffre)
      return notification({ variant: 'success', message: deleteOneOffre.message })
    } else {
      notification({ variant: 'error', message: deleteOneOffre.message })
    }
  };

  let statusColors = {
    1: { label: 'offline', title: "Brouillon" },
    2: { label: 'online', title: "En ligne" },
    3: { label: 'archived', title: "Archivé" },
  }

  // Define the order of display
  const array = {
    1: 2,
    2: 1,
    3: 3
  };
  // Group the offers by status
  const groupedOffers = {};
  offers.forEach(obj => {
    const status = array[obj.status];
    if (!groupedOffers[status]) {
      groupedOffers[status] = [];
    }
    groupedOffers[status].push(obj);
  });

  // Assign titles to each group
  const titles = {
    2: "Brouillon",
    1: "En ligne",
    3: "Archivé"
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Typography variant="h1" component="h1">
      Gérer les offres
    </Typography>
    <div className='rowContainer'>
      {/* EVENTS LIST */}
      {groupedOffers && Object.entries(groupedOffers).map(([index, offers]) => {
        return <>
          <h3>{titles[index]}</h3>
          <div className='gridContainer'>
            {offers.map(value => {
              return <Card sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <CardMedia
                  component="img"
                  loading="lazy"
                  height="140"
                  image={value.thumbnail ? `${process.env.REACT_APP_URL}/img/offers/${value.offer_id}/${value.thumbnail}?${timeStamp()}` : Default}
                  alt={value.thumbnail}
                />
                <CardContent>
                  <div className='cardHeader'>
                    <div className='titreCard'>
                      {value.title}
                    </div>
                  </div>
                  <div className='descriptionClamp'>
                    {removeHtml(value.content)}
                  </div>
                </CardContent>
                <div className='cardFooter'>
                  <div className='statusCard'>
                    <span className={statusColors[value.status].label}>
                      {statusColors[value.status].title}
                    </span>
                  </div>
                  <CardActions className='cardActions'>
                    <Tooltip title='Editer'>
                      <IconButton aria-label="edit" component={Link} to={`/admin/offres/${value.offer_id}/editer`}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Supprimer'>
                      <IconButton aria-label="delete" onClick={() => handleAlertDialog(value.offer_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </div>
              </Card>
            })}
          </div>
        </>
      })}
    </div>
    {/* BOUTON ADD */}
    <Fab variant="extended" className='fab' color="primary" aria-label="add" component={Link} to='/admin/offres/creation'>
      <AddCircleOutlineIcon /> Ajouter une offre
    </Fab>
    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert.open} text={openAlert.text} func={openAlert.function} onClose={() => setOpenAlert(false)} />
  </div>
};

export default ListOffers;