// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';

// SCSS
import './ModalNewPost.scss';
import { timeStamp } from '../../../contexts/Utils';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalNewPost = ({ open, onClose, post }) => {
  // FONTAWESOME
  library.add(faXmark)

  const post_type = post.post_type === 'offer' ? 'offre' : 'actualité'
  const image = post.post_type === 'offer' ? `offers/${post.post_id}/${post.thumbnail}` : `actualities/${post.post_id}/${post.thumbnail}`
  const url = post.post_type === 'offer' ? `/offres/${post.post_id}` : `/actualites/${post.post_id}`

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container-NewPost two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalNewPost">
        <FontAwesomeIcon icon="fa-solid fa-xmark" className='closeIcon' onClick={onClose} />
        <h2>Nouvelle {post_type}</h2>
        <div class="containerNewPost">
          {post.thumbnail && <div class="column1">
            <img class="imageNewPost" src={`${process.env.REACT_APP_URL}/img/${image}?${timeStamp()}`} loading="lazy" alt="Your Image" />
          </div>}
          <div class="columnNewPost">
            <h2 class="titleNewPost">{post.title}</h2>
          </div>
        </div>
        <a class="buttonPost" href={url} onClick={onClose}>Découvrir l'{post_type}</a>
      </div>
    </div>
  </div>
}

export default ModalNewPost;