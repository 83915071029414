// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react'
import { Html5Qrcode } from "html5-qrcode"

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../../../contexts/Api'

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Button from '../../../Button'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'

// BEEP SOUND
import sound from '../../../../assets/music/nice-beep.mp3'
// SCSS
import './ModalReadQrCode.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalReadQrCode = ({ open }) => {

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [valid, setValid] = useState(false)
  const [notValid, setNotValid] = useState(false)
  const [messageScan, setMessageScan] = useState('')
  const [scan, setScan] = useState(true)

  // FONTAWESOME
  library.add(faCircleCheck)

  let currentText = '';

  let html5QrCode

  useEffect(() => {
    html5QrCode = new Html5Qrcode("reader");
    const config = { fps: 10, qrbox: { width: 250, height: 250 }, rememberLastUsedCamera: true, };
    if (scan) html5QrCode.start({ facingMode: "environment" }, config, qrCodeSuccessCallback);
  }, [scan])

  const reset = () => {
    setValid(false)
    setNotValid(false)
    setMessageScan('')
    setScan(true)
  }

  // BEEP SOUND
  let beep = new Audio(sound)
  const playBeep = () => {
    beep.play()
  }

  // CHECK USER VALIDATION & QR ANALYTICS
  const handleCheckUser = async hash => {
    const getUser = await Api({
      endpoint: '/analytics',
      data: { user_to_check: hash, },
      method: 'POST',
    });
    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (getUser.success) {
      setMessageScan(getUser.message)
      setValid(true)
      setNotValid(false)
    } else {
      setMessageScan(getUser.message)
      setNotValid(true)
      setValid(false)
    }
  }

  // STOP SCAN TO HAVE ONLY ONE REQUEST TO API
  const StopQR = () => {
    html5QrCode.stop()
    setScan(false)
  }

  // ON SUCESS SCAN
  const qrCodeSuccessCallback = (decodedText, decodedResult) => {
    if (currentText !== decodedText) {
      currentText = decodedText;
      playBeep()
      StopQR()
      handleCheckUser(decodedText)
    }
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalQr">
        <div className='qrContainer'>
          <div id="reader" style={{ width: '300px' }}></div>
          {valid && <div>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>}
          {notValid && <div>
            <svg className="crossmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
              <path className="cross__path cross__path--right animateElement" fill="none" d="M16,16 l20,20" />
              <path className="cross__path cross__path--left animateElement" fill="none" d="M16,36 l20,-20" />
            </svg>
          </div>}
          <div id="result">{messageScan}</div>
          {(valid || notValid) && <Button className="button fourth" text='Scanner à nouveau' onClick={reset} />}
        </div>
      </div>
    </div>
  </div>
}

export default ModalReadQrCode;