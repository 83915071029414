
// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Doughnut } from 'react-chartjs-2';

import './Analytics.scss'

// CHANGE THE SIZE BETWEEN LEGEND AND CHART
const plugin = {
  id: "increase-legend-spacing",
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;
    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 20;
    }
  }
};

ChartJS.register(ArcElement, Tooltip, Legend, plugin);

const DoughnutAdmin = ({ data }) => {

  const total = data.reduce((sum, value) => sum + value.number, 0);

  const feed = {
    labels: data.map(label => { return label.range }),
    datasets: [
      {
        data: data.map(value => ((value.number / total) * 100).toFixed(1)),
        backgroundColor: ['#ffbf69', '#b56576', '#b392ac'],
        borderWidth: 1,
        datalabels: {
          color: '#ffff',
          font: {
            size: '15px',
            weight: 'bold'
          }
        }
      },
    ],
    options: {
      plugins: {
        datalabels: {
          formatter: function (value, context) {
            return value + '%';
          }
        },
        legend: {
          display: true,
          labels: {
            padding: 20
          }
        },
      },
      responsive: true,
      maintainAspectRatio: true,
    }
  };

  return <div style={{ width: '350px', height: '350px', position: 'relative' }}>
    <Doughnut
      data={feed}
      options={feed.options}
      plugins={[ChartDataLabels]}
    />
  </div>
}

export default DoughnutAdmin;