// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react';
import { createUseStyles } from "react-jss";

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import { Typography, Paper, Button } from "@mui/material";

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../contexts/Api';
import { useNotification } from '../../components/UseNotification';
import Formfields from '../../components/Admin/FormfieldsBack';

// SCSS
import './Admin.scss'
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Admin = () => {
  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,

      '& span': {
        width: 'fit-content'
      }
    },
  })
  const classes = useStyle();

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [homeBanner, setHomeBanner] = useState('')

  // Admin notification
  const { notification } = useNotification()

  //
  // ─── GET DASHBOARD DATA ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchHomeBanner = async () => {
      const getHomeBanner = await Api({
        endpoint: '/home',
        method: 'GET',
      });

      if (getHomeBanner.success) {
        setHomeBanner(getHomeBanner.data);
      } else {
        setHomeBanner('');
      }
    };
    fetchHomeBanner();
  }, []);

  // 
  // ─── UPLOAD FILE ───────────────────────────────────────
  //
  const uploadFile = async (event) => {
    const element = event.target
    // 
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const formData = new FormData();
    if (element.files[0]) {
      formData.append('file', element.files[0], `${element.name}-${element.files[0].name}`);
    }
    formData.append('id', 'banner')
    formData.append('file', 'home')
    formData.append('type', element.name)
    // 
    // ─── API REQUEST FOR FILE UPLOAD ───────────────────────────────────────
    //
    const params = {
      data: formData,
      endpoint: '/files',
      method: 'POST',
      removeHeader: true
    }
    const addFile = await Api(params);
    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    if (addFile && addFile.success) {
      return notification({ variant: 'success', message: addFile.message })
    } else {
      notification({ variant: 'error', message: addFile.message })
    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'text', component: 'file', name: 'banner', fileName: homeBanner, link: `/home/banner/${homeBanner}`, label: "Modifier la bannière page d'accueil (maximum 8mo)", handleChange: uploadFile },
  ];

  // 
  // ─── RESET DES CGU (HORS MANAGER) ───────────────────────────────────────
  //
  const handleReset = async () => {
    const resetCgu = await Api({
      endpoint: '/users',
      method: 'PUT',
      data: { fields: { cgu: 0 }, role: 2, resetCgu: true },
    });

    if (resetCgu.success) {
      return notification({ variant: 'success', message: resetCgu.message })
    } else {
      notification({ variant: 'error', message: resetCgu.message })
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return homeBanner &&
    <div className={classes.container}>
      <Typography variant="h1" component="h1">
        Bienvenue !
      </Typography>
      {userData.role !== 4 && <Paper elevation={1} sx={{ padding: 5 }}>
        {/* TO DO ADD DASHBOARD */}
        {formFields.map((field) => {
          return <Formfields key={field.name} field={field} />
        })}
      </Paper>}
      {userData.role !== 4 && <Paper elevation={1} sx={{ padding: 5, marginTop: 5 }}>
        <Typography variant="h5" component="h5" sx={{ marginBottom: 2 }}>Réinitialiser les cgu pour les utilisateurs de l'application:</Typography>
        <Button variant="contained" onClick={handleReset}>Réinitialiser les cgu</Button>
      </Paper>}
    </div>
};

export default Admin;