// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { createUseStyles } from 'react-jss';
import Cookies from "universal-cookie";

// SVG
import LogoBK from '../assets/static/LOGOBK.png'
import { Logout } from '../contexts/Utils';
import LogoutIcon from '@mui/icons-material/Logout';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Header = () => {
  // redirect the user
  let navigate = useNavigate();
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: 70,
      padding: 20,
      zIndex: 20,
      position: 'relative',

      '& img': {
        height: 75,
      }
    },
    logout: {
      position: 'fixed',
      top: 40,
      right: 30
    }
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.header}>
    <div className={classes.logoBK} onClick={() => navigate('/')}>
      <img loading="lazy" src={LogoBK} alt='Logo BK' />
      {userData.role === 3 && < LogoutIcon className={classes.logout} onClick={Logout} />}
    </div>
  </div>
};

export default Header;