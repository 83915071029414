// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react';
import Cookies from 'universal-cookie';
import { timeStamp } from '../../../../contexts/Utils';
// 
// ─── IMPORTING SVG ───────────────────────────────────────
//
import BK from '../../../../assets/static/LOGOBK.png'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons'

// SCSS
import './ModalQrCode.scss'
import Api from '../../../../contexts/Api';
import Button from '../../../Button';
import ModalNotScanned from '../NotScanned/ModalNotScanned';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalQrCode = ({ open }) => {
  // STATE
  const [showScanned, setShowScanned] = useState(false)

  // FONTAWESOME
  library.add(faCircleCheck)

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);
  let date = btoa(new Date(new Date().toString().split('GMT')[0] + ' UTC').toISOString().split('T')[0])
  let uuid = btoa(userData.user_id)

  // TODO : HANDLE QR HASH GENERATOR
  let hash = uuid + '&' + date


  // 
  // ─── TRACKING USER POP UP SCAN ───────────────────────────────────────
  //
  useEffect(() => {
    const trackingUserPopUpScan = async () => {
      await Api({ endpoint: '/tracking', method: 'POST' })
    }
    trackingUserPopUpScan()
  }, [])

  // Show modal if modal commentaire is not open, hide modal if modal commentaire is open
  showScanned ? open = false : open = true

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    {/* POP UP SCAN */}
    {showScanned && <ModalNotScanned open={showScanned} onClose={() => setShowScanned(false)} />}
    <div className={`modal-container two ${open ? '' : showScanned ? 'out' : 'out'}`}>
      <div className="modal-background">
        <div className="modalQrUser">
          <div className='user_image'>
            <img loading="lazy" src={`${process.env.REACT_APP_URL}/img/users/${userData.user_id}/${userData.thumbnail}?${timeStamp()}`} alt={userData.name} />
          </div>
          <div className='userInfosModal'>
            <h3 className='nameUser'>{userData.name}</h3>
            <h2 className='firstNameUser'>{userData.firstname}</h2>
            <p className='reduction'>30%</p>
            <p className='reductionText'>valable sur place ou à emporter</p>
          </div>
          <div style={{ 'margin-bottom': 10 }} className='qrContainer'>
            {/* Arrive bientôt... */}
            <QRCodeSVG
              size={200}
              value={hash}
              level={"L"}
              bgColor={"#f6f4f3"}
              imageSettings={{
                src: BK,
                x: undefined,
                y: undefined,
                height: 45,
                width: 50,
                excavate: false,
              }}
            />
          </div>
          {/* COMMENTAIRE SCAN */}
          <Button className="button cta" text="Je n’ai pas été scanné" onClick={() => setShowScanned(true)} />
        </div>
      </div>
    </div>
  </>
}

export default ModalQrCode;