import { useState, useEffect } from "react";
import { Outlet, useLocation } from 'react-router-dom';
import Api from "../../contexts/Api";
import { Logout } from "../../contexts/Utils";

const AuthChecker = () => {
  let location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  // 
  // ─── CHECK IF USER IS CORRECTLY AUTHENTICATED ───────────────────────────────────────
  //
  useEffect(() => {
    const checkUserAuthentication = async () => {
      const params = { endpoint: '/auth/authenticate' }
      const isAuthenticated = await Api(params);

      if (!isAuthenticated.success) {
        Logout();
      } else {
        setIsAuthenticated(isAuthenticated.success);
      }
    }
    checkUserAuthentication();

  }, [location]);

  // 
  // ─── DISPLAY APP PAGES IF USER IS AUTHENTICATED ───────────────────────────────────────
  //
  if (isAuthenticated) {
    return <Outlet />
  }
}

export default AuthChecker;