// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  TextField,
  InputAdornment,
  Paper,
} from "@mui/material";
// ICONS
import SearchIcon from '@mui/icons-material/Search';
// THEME
import { useTheme } from '@mui/material/styles';
// ICONS
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

// ─── UTILS ────────────────────────────
import { formatDate } from '../../../contexts/Utils';

// ─── STYLE ────────────────────────────
import { createUseStyles } from "react-jss";
import Formfields from '../FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AnalyticsTable = ({ analytics, name }) => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // ANALYTICS
  const [analyticsSearch, setAnalyticsSearch] = useState({ all: analytics, filtered: [] })
  // TABLE PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,

      '& .MuiPaper-rounded': {
        marginBottom: 30
      }
    },
    used: {
      color: '#3d5a80',
      background: '#e0fbfc',
      padding: '3px 6px'
    },
    won: {
      color: 'rgb(34, 154, 22)',
      background: 'rgb(228, 248, 221)',
      padding: '3px 6px'
    },
    inputSearch: {
      '& div': {
        background: "white"
      },
      '& label': {
        fontSize: 15,
        top: -5
      }
    }
  })
  const classes = useStyle();

  //
  // ─── PAGINATION ───────────────────────────────────────
  //
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="première page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="page précédente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="page suivante"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="dernière page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - analytics.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  //
  // ─── HANDLE SEARCH ───────────────────────────────────────
  //
  const onSearchInput = (inputSearch) => {
    let search = analyticsSearch.all.filter(value => {
      //search by nom prenom, enseigne, boutique, label, et status du gain
      return (
        value.label.toLowerCase().includes(inputSearch) ||
        value.userName.toLowerCase().includes(inputSearch) ||
        value.userFirstname.toLowerCase().includes(inputSearch) ||
        value.brandName.toLowerCase().includes(inputSearch) ||
        value.storeName.toLowerCase().includes(inputSearch) ||
        window._DATA.types.gain_status[value.status].label.toLowerCase().includes(inputSearch))
    })
    if (!search.length) search = [{ none: true }]
    setAnalyticsSearch({ ...analyticsSearch, filtered: search })
  }

  let analyticsList = analyticsSearch.all
  if (analyticsSearch.filtered.length) {
    analyticsList = analyticsSearch.filtered
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className={classes.inputSearch}>
      <div className='maxHeight'>
        <Formfields field={{ type: 'text', component: 'text', name: 'user_id', placeholder: 'Rechercher', size: '100%', handleChange: (e) => onSearchInput(e), variant: 'outlined' }} />
      </div>

    </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: '#e17f51' }}>
          <TableRow sx={{ '& th': { color: 'white' } }}>
            {name && <TableCell>Nom</TableCell>}
            <TableCell>Salarié {!name && "scanné"}</TableCell>
            <TableCell>Enseigne</TableCell>
            <TableCell>Boutique</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? analyticsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : analyticsList
          ).map((analytic) => {
            return analytic.none ? <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6}>Pas de résultats</TableCell>
            </TableRow> : <TableRow
              hover
              key={analytic.gain_id + analytic.time_create}
            >
              {name && <TableCell component="th" scope="row">
                {analytic.label}
              </TableCell>}
              <TableCell>{analytic.userName && analytic.userName + ' ' + analytic.userFirstname}</TableCell>
              <TableCell>{analytic.brandName ? analytic.brandName : '-'}</TableCell>
              <TableCell>{analytic.storeName ? analytic.storeName : '-'}</TableCell>
              <TableCell><span className={analytic.status === 2 ? classes.used : classes.won}>{window._DATA.types.gain_status[analytic.status].label}</span></TableCell>
              <TableCell>{formatDate({ time: analytic.time_create, display: 'date' })}</TableCell>
            </TableRow>
          })}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Tous', value: -1 }]}
              colSpan={6}
              count={analytics.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'Lignes par page',
                },
                native: true,
              }}
              labelRowsPerPage={"Lignes par page"}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + ' sur ' + count
                }
              }
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </>
};

export default AnalyticsTable;