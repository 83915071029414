import { Outlet } from "react-router-dom";

//SVG
import LogoBK from '../../assets/static/LOGOBK.png'

// SCSS

import './AuthLayout.scss'

const AuthLayout = () => {

  return <div className='container'>
    <img className="logoBk" loading="lazy" src={LogoBK} alt="Logo" />
    <Outlet />
  </div>
}

export default AuthLayout
