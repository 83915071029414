// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Avatar,
  Box,
  IconButton,
  Menu,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Divider,
  Tabs,
  Tab,
  Tooltip,
  Typography,
  Fab,
  Button
} from "@mui/material";
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import StoreIcon from '@mui/icons-material/Store';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';
import { useNotification } from '../../../components/UseNotification';
import { timeStamp } from '../../../contexts/Utils';
// ─── STYLE ────────────────────────────
import { createUseStyles } from "react-jss";

// ─── IMPORTING POP OVER  ───────────────────────────────────────
import AdminAlertPopOver from '../../../components/Modals/Admin/AdminAlertPopOver'
import EditStore from '../../../components/Modals/Admin/Stores/EditStore';
import EditBrand from '../../../components/Modals/Admin/Brands/EditBrand';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListBrands = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  const [brands, setBrands] = useState([]);

  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState({ open: false, function: '', text: '' });
  // TABS
  const [value, setValue] = useState(0);
  // MENNU ANCHOR
  const [anchorEl, setAnchorEl] = useState(null);

  // BRANDS
  // Add brand popover
  const [openAddBrand, setOpenAddBrand] = useState(false);
  // Delete brand & stores
  const [deleteBrand, setDeleteBrand] = useState(false)

  // STORES
  // Manage store popover
  const [openManageStore, setOpenManageStore] = useState(false);
  // Delete store
  const [deleteStore, setDeleteStore] = useState(false)

  // Set data for store & brand form
  const [defaultData, setDefaultData] = useState({})

  // Notification user
  const { notification } = useNotification()

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& h1': {
        marginBottom: 30,
      },

      '& .MuiFab-root': {
        position: 'fixed',
        right: 35,
        top: 90,
        borderRadius: '4px!important',
        fontWeight: '600!important',

        '& svg': {
          marginRight: 5
        }
      },

      '& .MuiTabs-scrollButtons.Mui-disabled': {
        opacity: 0.3
      },
    },
    headerTabPanel: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: 20
    },
    addBusiness: {
      display: 'flex',
      alignItems: 'center',
      gap: 5,
      borderRadius: 4,
      padding: 10,
      fontSize: 15,
      backgroundColor: 'rgb(248, 249, 250)',
      color: '#344866',
      width: 'fit-content',
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: 'var(--orange-primary)',
        color: 'white',
      }
    },
    dotsMenu: {
      '& svg': {
        marginRight: 10
      }
    },
    customTab: {
      alignItems: 'center!important',
      flexDirection: 'row!important',
      justifyContent: 'flex-start!important',

      '& .MuiTab-iconWrapper': {
        marginBottom: 0,
        marginRight: 10
      },

      '& .MuiAvatar-img': {
        objectFit: 'contain'
      }
    }

  })
  const classes = useStyle();

  //
  // ─── SEARCH REQUEST TO GET ALL BRANDS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchBrands = async () => {
      const params = {
        endpoint: '/brands',
        query: { store: true },
        method: 'GET',
      }
      const getBrands = await Api(params);

      if (getBrands.success && getBrands.data.length) {
        setBrands(getBrands.data);
      } else {
        setBrands([]);
      }
    };
    fetchBrands();
  }, [openAddBrand, openManageStore, deleteBrand, deleteStore]);

  // 
  // ─── HANDLE MANAGE BRAND POP OVER ───────────────────────────────────────
  //
  const handleManageBrandPopOver = (props) => {
    setDefaultData(props)
    setOpenAddBrand(true)
  }

  // 
  // ─── HANDLE MANAGE STORE POP OVER ───────────────────────────────────────
  //
  const handleManageStorePopOver = (props) => {
    setDefaultData(props)
    setOpenManageStore(true)
  }

  // 
  // ─── HANDLE ALERT DIALOG ───────────────────────────────────────
  //
  const handleAlertDialogBrands = (brand_id) => {
    setOpenAlert({ open: true, function: () => handleDeleteBrand({ brand_id }), text: "Supprimer l'enseigne et les boutiques reliées ?" })
  };

  const handleAlertDialogStore = (brand_id, store_id) => {
    setOpenAlert({ open: true, function: () => handleDeleteStore({ brand_id, store_id }), text: "Supprimer la boutique ?" })
  };

  // 
  // ─── DELETE BRAND & STORES LINKED ───────────────────────────────────────
  //
  const handleDeleteBrand = async (opt) => {
    const params = {
      endpoint: '/brands',
      data: { brand_id: opt.brand_id },
      method: 'DELETE',
    }
    const deleteBrandAndStore = await Api(params)

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (deleteBrandAndStore.success) {
      setDeleteBrand(!deleteBrand)
      setAnchorEl(null)
      return notification({ variant: 'success', message: deleteBrandAndStore.message })
    } else {
      notification({ variant: 'error', message: deleteBrandAndStore.message })
    }
  };

  // 
  // ─── DELETE STORES ───────────────────────────────────────
  //
  const handleDeleteStore = async (opt) => {
    const params = {
      endpoint: '/stores',
      data: { brand_id: opt.brand_id, store_id: opt.store_id },
      method: 'DELETE',
    }
    const deletedStore = await Api(params)

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (deletedStore.success) {
      setDeleteStore(!deleteStore)
      return notification({ variant: 'success', message: deletedStore.message })
    } else {
      notification({ variant: 'error', message: deletedStore.message })
    }
  };

  // HANDLE MENU DOTS
  const handleMenuDots = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const openMenuDots = Boolean(anchorEl);

  // TABPANEL FUNCTIONS
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const a11yProps = index => {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Typography variant="h1" component="h1">
      Gérer les enseignes
    </Typography>
    <Box sx={{ display: 'flex' }}>
      <Box sx={{
        flexGrow: 1, bgcolor: 'white', display: 'flex', maxHeight: window.innerHeight > 800 ? 700 : 500
      }}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          scrollButtons="auto"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs brands"
          sx={{ borderRight: 1, borderColor: 'divider', width: 250 }}
        >
          {brands && brands.map((brand, index) => {
            return <Tab key={brand.brand_id} label={brand.name} {...a11yProps(index)} className={classes.customTab} icon={<Avatar alt="logo" variant='square' src={`${process.env.REACT_APP_URL}/img/brands/${brand.brand_id}/${brand.logo}?${timeStamp()}`} />} />
          })}
        </Tabs>
      </Box>
      {brands && brands.map((brand, index) => {
        return <div
          key={index}
          role="tabpanel"
          hidden={value !== index}
          id={`vertical-tabpanel-${index}`}
          aria-labelledby={`vertical-tab-${index}`}
          style={{ width: '100%', paddingLeft: 25, paddingTop: 34, background: 'white' }}
        >
          {value === index && (
            <Box>
              <div className={classes.headerTabPanel}>
                <div className={classes.headerTabPanel}>
                  <h2>{brand.name}</h2>
                  {/* MENU TROIS PETITS POINT */}
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openMenuDots ? 'long-menu' : undefined}
                    aria-expanded={openMenuDots ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={handleMenuDots}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    id="long-menu"
                    MenuListProps={{
                      'aria-labelledby': 'long-button',
                    }}
                    anchorEl={anchorEl}
                    open={openMenuDots}
                    onClose={() => setAnchorEl(null)}
                    PaperProps={{
                      style: {
                        maxHeight: 48 * 4.5,
                        width: '17%',
                      },
                    }}
                  >
                    <MenuItem onClick={() => handleManageBrandPopOver(brand)} disableRipple className={classes.dotsMenu}>
                      <StorefrontIcon />
                      Modifier l'enseigne
                    </MenuItem>
                    <MenuItem onClick={() => handleAlertDialogBrands(brand.brand_id)} disableRipple className={classes.dotsMenu}>
                      <DeleteIcon />
                      Supprimer l'enseigne
                    </MenuItem>
                  </Menu>
                </div>
                <div onClick={() => handleManageStorePopOver(brand.brand_id)} className={classes.addBusiness}>
                  <AddBusinessIcon />
                  Ajouter une boutique
                </div>
              </div>

              <List className='listEnseignes' style={{ maxHeight: window.innerHeight > 800 ? 600 : 400, overflow: 'auto' }} >
                {brand && brand.stores.map(store => {
                  return <div key={store.store_id}>
                    <ListItem disablePadding secondaryAction={<>
                      <Tooltip title='Editer'>
                        <IconButton aria-label="edit" onClick={() => handleManageStorePopOver(store)}>
                          <EditIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title='Supprimer'>
                        <IconButton aria-label="delete" onClick={() => handleAlertDialogStore(brand.brand_id, store.store_id)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                    }>
                      <ListItemIcon>
                        <StoreIcon />
                      </ListItemIcon>
                      <ListItemText
                        primary={store.name}
                      />
                    </ListItem>
                    <Divider sx={{ margin: '10px 0' }} />
                  </div>
                })}
              </List>
            </Box>
          )}
        </div>
      })}
    </Box>
    {/* BOUTON ADD */}
    <Fab variant="extended" className='fab' color="primary" aria-label="add" onClick={() => setOpenAddBrand(true)}>
      <AddCircleOutlineIcon />Ajouter une enseigne
    </Fab>
    {/* ADD OR EDIT BRAND POPOVER */}
    <EditBrand open={openAddBrand} default={defaultData} onClose={() => { setDefaultData({}); setOpenAddBrand(false) }} />
    {/* ADD OR EDIT STORE POPOVER */}
    {/* Ajout du object keys qui permet d'attendre d'avoir toutes les données avant d'afficher le form */}
    {Boolean(Object.keys(defaultData).length) && <EditStore open={openManageStore} default={defaultData} onClose={() => { setDefaultData({}); setOpenManageStore(false) }} />}
    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert.open} text={openAlert.text} func={openAlert.function} onClose={() => setOpenAlert(false)} />
  </div>
};

export default ListBrands;