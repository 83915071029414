// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Button from '../../components/Button'
import Formfields from '../../components/Formfields'
import Hive from '../../components/Hive/Hive'
import GeoSearch from '../../components/GeoSearch'

// 
// ─── UTILS ───────────────────────────────────────
//
import { getValue, prepareFormData } from '../../contexts/Utils'
import Api from '../../contexts/Api'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCircleCheck } from '@fortawesome/pro-light-svg-icons'

// SCSS
import './Register.scss'
import Modal from '../../components/Modals/Modal';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Register = () => {
  //
  // ─── INITIALISATION DES STATES ───────────────────────────────────────
  //
  // Form
  const [form, setForm] = useState({});
  // Formfields errors
  const [errors, setErrors] = useState({})
  // Register Form
  const [registerForm, setRegisterForm] = useState(1)
  // Register Button
  const [disabledButton, setDisabledButton] = useState(true)
  // Steps
  const [activeStep, setActiveStep] = useState(0)
  // Register Modal
  const [modal, setModal] = useState(false)
  // Not valid
  const [notValid, setNotValid] = useState(false)

  // redirect the user
  let navigate = useNavigate();

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // Check if user is already logged in. Redirect if true
  useEffect(() => {
    if (userData) navigate("/")
  }, [userData])

  //
  // ─── INITIALISATION FONTAWESOME ───────────────────────────────────────
  //
  library.add(faCircleCheck)

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  let date = new Date().toISOString().split('T')[0];

  const formFieldsInfos = [
    { type: "text", component: "text", default: form.name ? form.name : "", name: "name", placeholder: 'Nom *', required: true },
    { type: "text", component: "text", default: form.firstname ? form.firstname : "", name: "firstname", placeholder: 'Prénom *', required: true },
    { type: "tel", component: "text", default: form.phone ? form.phone : "", name: "phone", placeholder: 'Numéro de téléphone *', required: true },
    { type: "email", component: "text", default: form.email ? form.email : "", name: "email", placeholder: 'E-mail *', required: true },
    { type: "date", component: "datepicker", default: form.birth_date ? form.birth_date : null, name: "birth_date", label: 'Date de naissance *', required: true },
    { type: "password", component: "text", default: form.password ? form.password : "", name: "password", placeholder: 'Choix du mot de passe *', required: true },
    { type: "password", component: "text", default: form.confirmpassword ? form.confirmpassword : "", name: "confirmpassword", placeholder: 'Confirmation du mot de passe *', required: true },
    { type: 'text', component: 'file', afterCrop: (file) => setForm({ ...form, user_image: file }), name: 'user_image', label: "Ajouter une photo (maximum 8mo) *", crop: true, warning: 'La photo doit être à visage dégagé et de face.', required: true }
  ]

  const formFieldsLocalisation = [
    { type: 'checkbox', name: 'cgu', component: "checkbox", text: "Je reconnais avoir pris connaissance des <a class='cgu' href='https://bk-fidelite.fr/file/BK&A-BK-FIDELITE-CGU.pdf' target='_blank'>conditions générales d’utilisation</a> de l’application et les accepter sans réserve*", required: true },
    { type: 'checkbox', name: 'pdc', component: "checkbox", text: "Je reconnais avoir pris connaissance de la <a class='cgu' href='/politique-de-confidentialite' target='_blank'>politique de confidentialité</a>*", required: true },
    { type: 'checkbox', name: 'newsletter', component: "checkbox", text: "J'accepte de recevoir les offres du Groupe BK" }
  ]

  // 
  // ─── HANDLE REGISTRATION ───────────────────────────────────────
  //
  const handleChange = async (dataFields) => {
    setNotValid(false)
    //
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const file = dataFields.user_image

    const formData = new FormData();
    if (file) {
      formData.append('file', file)
      delete dataFields.user_image
    }
    formData.append('fields', JSON.stringify(dataFields))

    const addUser = await Api({
      data: formData,
      method: 'POST',
      endpoint: '/auth/register',
      removeHeader: true
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (!addUser || !addUser.success) {
      console.log('error')
      setNotValid(true)
    }
    setActiveStep(activeStep + 1);
  }

  // 
  // ─── HANDLE STEPS AND FORM DATA ───────────────────────────────────────
  // 
  const handleNext = async () => {
    const data = prepareFormData({ formId: 'registrationForm1' });

    if (data.fields.store_id === '-') { setErrors({ ...errors, 'registrationForm1': { store_id: { error: true, message: 'Veuillez sélectionner une boutique' } } }); return }

    let password = getValue(data, ['fields', 'password'])
    let confirmpassword = getValue(data, ['fields', 'confirmpassword'])

    let email = getValue(data, ['fields', 'email'])
    let phone = getValue(data, ['fields', 'phone'])

    if (activeStep === 1) {
      let test = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
      if (!test) {
        setErrors({ ...errors, 'registrationForm1': { email: { error: true, message: 'Email invalide.' } } })
      } else {
        const check = await Api({
          method: 'GET',
          query: { email, phone },
          endpoint: '/auth/register/users',
        })
        if (!check.success) setErrors({ ...errors, 'registrationForm1': { ...errors.registrationForm1, ...check.fields } });
        else if (data.errors) {
          setErrors({ ...errors, 'registrationForm1': { ...data.errors } })
        } else if (password !== confirmpassword) {
          setErrors({ ...errors, 'registrationForm1': { confirmpassword: { error: true, message: 'Les mots de passe ne sont pas identiques' } } })
        } else {
          if (Object.keys(errors).length) {
            setErrors({});
          }
          delete data.fields.user_image
          setForm({ ...form, ...data.fields });
          setActiveStep(activeStep + 1);
        }
      }
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      setForm({ ...form, ...data.fields });
      setActiveStep(activeStep + 1);
    }
    setRegisterForm(2)
  };

  const handleFinish = () => {
    const data = prepareFormData({ formId: 'registrationForm' });

    if (data.errors) {
      setErrors({ ...errors, 'registrationForm': { ...data.errors } })
    } else {
      handleChange({ ...form, ...data.fields });
    }
  };

  // 
  // ─── USER INFOS COMPONENTS ───────────────────────────────────────
  //
  const userInfos = <>
    <h3>Vos coordonnées</h3>
    <form name='registrationForm1' id='registrationForm1'>
      {formFieldsInfos.map((field) => (<Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['registrationForm1', field.name, 'message']) } }} />))}
    </form>
    <div className='buttonContainer'>
      <Button className="button secondary" text='Précedent' onClick={() => { setRegisterForm(1); setActiveStep(0) }} />
      <Button className="button fourth" text='Suivant' onClick={() => setModal(true)} />
    </div>
    {modal && <Modal data={{ title: 'Attention', text: 'Une fois confirmé, vous ne pourrez plus modifier vos informations de profil (Nom, Prénom, date de naissance et photo de profil) sans une demande auprès du service RH.' }} open={modal} onClose={() => setModal(false)} onConfirm={() => { handleNext(); setModal(false) }} />}
  </>

  const localisationInfos = <>
    <h3>D'où venez-vous ?</h3>
    <form name='registrationForm' id='registrationForm'>
      <GeoSearch
        className='geoSearch'
        shrink={true}
        required={true}
        error={getValue(errors, ['registrationForm', 'city', 'message'])}
      />
      {formFieldsLocalisation.map((field) => (<Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['registrationForm', field.name, 'message']) } }} />))}
    </form>
    <div className='buttonContainer'>
      <Button className="button secondary" text='Précedent' onClick={() => setActiveStep(activeStep - 1)} />
      <Button className="button fourth" text='Valider' onClick={handleFinish} />
    </div>
  </>

  const validationDiv = <div className='confirmation'>
    <h2>Votre demande d'inscription sera traitée dans les plus brefs délais par nos services.</h2>
    <h3>Un email vous sera envoyé une fois votre compte validé.</h3>
    <div>
      <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
        <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
        <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </svg>
    </div>
    <Button className="button fourth" text='Retour' onClick={() => navigate('/connexion')} />
  </div>

  const errorDiv = <div className='confirmation'>
    <h2>Une erreur est survenue.</h2>
    <h3>Veuillez réessayer et vérifier les informations fournies.</h3>
    <div>
      <svg className="crossmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
        <path className="cross__path cross__path--right animateElement" fill="none" d="M16,16 l20,20" />
        <path className="cross__path cross__path--left animateElement" fill="none" d="M16,36 l20,-20" />
      </svg>
    </div>
    <Button className="button fourth" text='Retour' onClick={() => navigate('/inscription')} />
  </div>

  // 
  // ─── STEPPER ───────────────────────────────────────
  //
  const steps = [
    { id: 1, titre: 'informations', component: userInfos },
    { id: 2, titre: 'localisation', component: localisationInfos },
    { id: 3, titre: 'validation', component: notValid ? errorDiv : validationDiv }
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='container'>
    {registerForm === 1 && <form name='registrationForm1' id='registrationForm1' className='registrationForm1'>
      <p style={{ marginBottom: 5 }}>Sélectionner votre entreprise</p>
      <Hive defaultBrand={form.brand_id || ""} error={errors} defaultStore={form.store_id || ""} onSelect={() => setDisabledButton(false)} />
      <div className='buttonContainer'>
        <Button className='button secondary' text="Retour" onClick={() => navigate(-1)} />
        <Button className={`button ${disabledButton ? 'secondary' : 'fourth'}`} disabled={disabledButton} text="S'inscrire" onClick={handleNext} />
      </div>
    </form>
    }
    {registerForm === 2 && <div className='register2'>
      {/* STEP HEADER */}
      <div className='stepsHeader'>
        {steps.map(stepHeader => {
          return <div key={stepHeader.id} className={`${stepHeader.id === activeStep && 'selected'}`}>{stepHeader.titre}</div>
        })}
      </div>
      {/* USER REGISTRATION FORM */}
      {steps.map(stepRegister => {
        return stepRegister.id === activeStep && stepRegister.component
      })}
    </div>}
  </div>
};

export default Register;