import { Outlet } from "react-router-dom";
import Header from "./Header";
import NavigationFooter from "./NavigationFooter/NavigationFooter";

const Layout = () => {

  return <main className='App' style={{ paddingBottom: '100px' }}>
    <Header />
    <Outlet />
    {window.innerWidth < 500 && <NavigationFooter />}
  </main>
}

export default Layout
