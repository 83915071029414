// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Tooltip,
  IconButton,
  Typography,
  Fab
} from "@mui/material";
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';
import { useNotification } from '../../../components/UseNotification';
import { removeHtml, timeStamp } from '../../../contexts/Utils';

// ─── STYLE ────────────────────────────
import { createUseStyles } from "react-jss";

// ─── IMPORTING POP OVER  ───────────────────────────────────────
import AdminAlertPopOver from '../../../components/Modals/Admin/AdminAlertPopOver'

// ─── IMPORTING PNG  ───────────────────────────────────────
import Default from '../../../assets/static/Default.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListActualities = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  const [actualities, setActualities] = useState([]);
  const [deletedActuality, setDeletedActuality] = useState(false);
  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState({ open: false, function: '', text: '' });

  // Notification user
  const { notification } = useNotification()

  //
  // ─── SEARCH REQUEST TO GET ALL ACTUALITES ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchActualities = async () => {
      const getActualities = await Api({
        endpoint: '/actualities',
        method: 'GET',
      });

      if (getActualities.success && getActualities.data.length) {
        setActualities(getActualities.data);
      } else {
        setActualities([]);
      }
    };
    fetchActualities();
  }, [deletedActuality]);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      '& h1': {
        marginBottom: 30,
      },

      '& .MuiFab-root': {
        position: 'fixed',
        right: 35,
        top: 90,
        borderRadius: '4px!important',
        fontWeight: '600!important',

        '& svg': {
          marginRight: 5
        }
      }
    }
  })
  const classes = useStyle();

  // 
  // ─── HANDLE ALERT DIALOG ───────────────────────────────────────
  //
  const handleAlertDialog = (actuality_id) => {
    setOpenAlert({ open: true, function: () => deleteActuality(actuality_id), text: "Supprimer l'actualité ?" })
  };

  // 
  // ─── DELETE ACTUALITY ───────────────────────────────────────
  //
  const deleteActuality = async (actuality_id) => {
    const deleteActuality = await Api({
      data: { actuality_id },
      method: 'DELETE',
      endpoint: '/actualities'
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (deleteActuality.success) {
      setDeletedActuality(!deletedActuality)
      return notification({ variant: 'success', message: deleteActuality.message })
    } else {
      notification({ variant: 'error', message: deleteActuality.message })
    }
  };

  let statusColors = {
    1: { label: 'offline', title: "Brouillon" },
    2: { label: 'online', title: "En ligne" },
    3: { label: 'archived', title: "Archivé" },
  }

  // Define the order of display
  const array = {
    1: 2,
    2: 1,
    3: 3
  };
  // Group the actualities by status
  const groupedActualities = {};
  actualities.forEach(obj => {
    const status = array[obj.status];
    if (!groupedActualities[status]) {
      groupedActualities[status] = [];
    }
    groupedActualities[status].push(obj);
  });

  // Assign titles to each group
  const titles = {
    2: "Brouillon",
    1: "En ligne",
    3: "Archivé"
  };


  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <Typography variant="h1" component="h1">
      Gérer les actualités
    </Typography>
    <div className='rowContainer'>
      {/* EVENTS LIST */}
      {groupedActualities && Object.entries(groupedActualities).map(([index, actualities]) => {
        return <>
          <h3>{titles[index]}</h3>
          <div className='gridContainer'>
            {actualities.map(value => {
              return <Card sx={{
                display: 'flex',
                flexDirection: 'column',
              }}>
                <CardMedia
                  component="img"
                  loading="lazy"
                  height="170"
                  image={value.thumbnail ? `${process.env.REACT_APP_URL}/img/actualities/${value.actuality_id}/${value.thumbnail}?${timeStamp()}` : Default}
                  alt={value.thumbnail}
                />
                <CardContent>
                  <div className='cardHeader'>
                    <div className='titreCard'>
                      {value.title}
                    </div>
                  </div>
                  <div className='descriptionClamp'>
                    {removeHtml(value.content)}
                  </div>
                </CardContent>
                <div className='cardFooter'>
                  <div className='statusCard'>
                    <span className={statusColors[value.status].label}>
                      {statusColors[value.status].title}
                    </span>
                  </div>
                  <CardActions className='cardActions'>
                    <Tooltip title='Editer'>
                      <IconButton aria-label="edit" component={Link} to={`/admin/actualites/${value.actuality_id}/editer`}>
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title='Supprimer'>
                      <IconButton aria-label="delete" onClick={() => handleAlertDialog(value.actuality_id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </CardActions>
                </div>
              </Card>
            })}
          </div>
        </>
      })}
    </div>
    {/* BOUTON ADD */}
    <Fab variant="extended" className='fab' color="primary" aria-label="add" component={Link} to='/admin/actualites/creation'>
      <AddCircleOutlineIcon /> Ajouter une actualité
    </Fab>
    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert.open} text={openAlert.text} func={openAlert.function} onClose={() => setOpenAlert(false)} />
  </div>
};

export default ListActualities;