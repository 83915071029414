// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from "react";
import { Route, Routes } from 'react-router-dom';
import Cookies from "universal-cookie";

import AuthChecker from "./AuthChecker";
import AppData from './UseData';

// AUTH
import Login from "../../views/Login"
import ForgotPassword from "../../views/ForgotPassword";
import Register from "../../views/Register/Register";
import ResetPassword from "../../views/ResetPassword";
import AuthLayout from "../AuthLayout/AuthLayout";

// CLIENT
import Home from '../../views/Home';
import Account from "../../views/Account";
import NotFound from '../../views/NotFound';
import Layout from "../Layout";
import Offers from "../../views/Offers/Offers";
import Offer from "../../views/Offers/Offer";
import Gains from "../../views/Gains/Gains";
import Actuality from "../../views/Actuality";

// ADMIN
import AdminLayout from "../Admin/AdminLayout";
import Admin from "../../views/Admin/Admin";
// Actu
import ListActualities from "../../views/Admin/Actualities/ListActualities";
import AddActuality from "../../views/Admin/Actualities/AddActuality";
import EditActuality from "../../views/Admin/Actualities/EditActuality";
// Offres
import ListOffers from "../../views/Admin/Offers/ListOffers";
import AddOffer from "../../views/Admin/Offers/AddOffer";
import EditOffer from "../../views/Admin/Offers/EditOffer";
// Enseignes & boutiques
import ListBrands from "../../views/Admin/Brands/ListBrands";
// Utilisateurs
import ListUsers from "../../views/Admin/Users/ListUsers";
import EditUser from "../../views/Admin/Users/EditUser";
import UserDetails from "../../views/Admin/Users/UserDetails";
// Gains
import ViewGains from "../../views/Admin/Gains/ViewGains";
// Analytics
import ListAnalytics from "../../views/Admin/Analytics/ListAnalytics";

// META TITLE
import MetaView from "../MetaView";
import DetailsBrand from "../../views/Admin/Analytics/DetailsBrand";
import Brands from "../../views/Brands";

// LEGALS
import Legals from "../../views/Legals";
import ViewRequests from "../../views/Admin/Requests/ViewRequests";


// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Router = () => {

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};

  // ROLES
  // Super Admin - 1
  // Salarié - 2
  // Manager - 3
  // RH - 4
  // Compta - 5
  // Marketing - 6
  // GroupeBk (accès aux utilisateurs du groupe bk) - 7
  // BkAmbitions (accès aux utilisateurs du groupe bk ambitions) - 8

  const routerArray = [
    {
      routes: [
        { path: '/', component: <Home />, title: 'Accueil' },
        { path: '/gains', component: <Gains />, title: 'Gains' },
        { path: '/offres', component: <Offers />, title: 'Offres' },
        { path: '/offres/:offre_id', component: <Offer />, title: 'Détail offre' },
        { path: '/actualites/:actuality_id', component: <Actuality />, title: 'Détail actualité' },
        { path: '/mon-compte', component: <Account />, title: 'Mon compte' },
        { path: '/enseignes', component: <Brands />, title: 'Enseignes' },
        { path: '/politique-de-confidentialite', component: <Legals />, title: 'Politique de confidentialité' }
      ],
      layout: <Layout />
    },
    {
      routes: [
        { path: '/admin', component: <Admin />, role: [1, 4, 5, 6, 7, 8] },
        { path: '/admin/actualites', component: <ListActualities />, role: [1, 6] },
        { path: '/admin/actualites/creation', component: <AddActuality />, role: [1, 6] },
        { path: '/admin/actualites/:actuality_id/editer', component: <EditActuality />, role: [1, 6] },
        { path: '/admin/offres', component: <ListOffers />, role: [1, 6] },
        { path: '/admin/offres/creation', component: <AddOffer />, role: [1, 6] },
        { path: '/admin/offres/:offer_id/editer', component: <EditOffer />, role: [1, 6] },
        { path: '/admin/enseignes', component: <ListBrands />, role: [1, 6] },
        { path: '/admin/gains', component: <ViewGains />, role: [1] },
        { path: '/admin/gains/:gain_id/editer', component: <EditUser />, role: [1,] },
        { path: '/admin/utilisateurs', component: <ListUsers />, role: [1, 4, 6, 7, 8] },
        { path: '/admin/utilisateurs/:user_id', component: <UserDetails />, role: [1, 4, 6, 7, 8] },
        { path: '/admin/utilisateurs/:user_id/editer', component: <EditUser />, role: [1, 4, 6, 7, 8] },
        { path: '/admin/analytics', component: <ListAnalytics />, role: [1, 5, 6] },
        { path: '/admin/analytics/:brand_id', component: <DetailsBrand />, role: [1] },
        { path: '/admin/requetes', component: <ViewRequests />, role: [1, 5, 6] },
      ],
      layout: <AdminLayout />
    }
  ]

  const authRouterArray = [
    {
      routes: [
        { path: '/connexion', component: <Login />, title: 'Connexion' },
        { path: '/inscription', component: <Register />, title: 'Inscription' },
        { path: '/mot-de-passe-oublie', component: <ForgotPassword />, title: 'Mot de passe oublié' },
        { path: '/reinitialiser-le-mot-de-passe/:token', component: <ResetPassword />, title: 'Réinitialiser le mot de passe' },
        { path: '/politique-de-confidentialite', component: <Legals />, title: 'Politique de confidentialité' }
      ],
      layout: <AuthLayout />
    },
  ]

  // 
  // ─── DECLARATION OF ALL NECESSARY ROUTES ───────────────────────────────────────
  //
  return <Routes>

    {authRouterArray.map((router) => {
      return <Route key={router} element={router.layout}>
        {router.routes.map((route) => {
          return <Route key={route.path} path={route.path} element={route.title ? <MetaView title={route.title}>{route.component}</MetaView> : route.component} />
        })}
      </Route>
    })}

    <Route element={<AuthChecker />}>
      <Route element={<AppData />}>
        {routerArray.map((router) => {
          return <Route path="/" key={router} element={router.layout}>
            {userData && router.routes.map((route) => {
              if (!route.hasOwnProperty('role') || (route.role && route.role.includes(parseInt(userData.role))) || !Object.keys(userData).length) {
                return <Route key={route.path} path={route.path} element={route.title ? <MetaView title={route.title}>{route.component}</MetaView> : route.component} />
              }
            })}
          </Route>
        })}
      </Route>
    </Route>
    <Route path="*" element={<NotFound />} />
  </Routes >
};

export default Router