// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { useNavigate } from 'react-router-dom';

// 
// ─── IMPORTING MATERIAL UI ───────────────────────────────────────
//
import Typography from '@mui/material/Typography';
import WestIcon from '@mui/icons-material/West';
// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const TitleForm = (props) => {

  // Go back
  const navigate = useNavigate()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='addAndManageHeader'>
    <WestIcon onClick={() => navigate(-1)} />
    <Typography id="form-title" variant="h2" component="h2">
      {props.text}
    </Typography>
  </div>
}

export default TitleForm;