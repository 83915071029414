// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { createUseStyles } from 'react-jss';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Title from '../components/Title';
import AccountInfos from '../components/AccountInfos/AccountInfos';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Account = () => {
  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    accountContainer: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto'
    },
  })
  const classes = useStyle();

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className={classes.accountContainer}>
      <AccountInfos />
    </div>
  </>
};

export default Account;