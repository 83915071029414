// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { formatDate } from '../../../contexts/Utils';
//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Typography,
  TextField,
} from "@mui/material";
// ICONS
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// THEME
import { useTheme } from '@mui/material/styles';

// ─── UTILS ────────────────────────────
import Api from '../../../contexts/Api';

// SCSS
import '../Users/AdminUsers.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ViewRequests = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // REQUETES
  const [requests, setRequests] = useState({ requests: [], filtered: [] });

  // TABLE PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  //
  // ─── SEARCH REQUEST TO GET ALL REQUESTS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchRequests = async () => {
      const getRequests = await Api({
        endpoint: '/requests',
        method: 'GET',
      });

      if (getRequests.success && getRequests.data.length) {
        setRequests({ requests: getRequests.data, filtered: [] });
      }
    };
    if (!requests.requests.length) fetchRequests();
  }, [requests]);

  const onSearchInput = (e) => {
    const search = e.target.value
    let filteredRequestsList = requests.requests.filter(request => {
      return request.user_firstname.toLowerCase().includes(search.toLowerCase()) || request.user_name.toLowerCase().includes(search.toLowerCase()) || request.brand_name.toLowerCase().includes(search.toLowerCase()) || request.store_name.toLowerCase().includes(search.toLowerCase()) || request.content.toLowerCase().includes(search.toLowerCase())
    })
    if (!filteredRequestsList.length) filteredRequestsList = [{ none: true }]
    setRequests({ ...requests, filtered: filteredRequestsList })
  }

  let requestsList = requests.requests
  if (requests.filtered.length) requestsList = requests.filtered

  //
  // ─── UPDATE THE VIEW COLUMNS OF THE REQUESTS ───────────────────────────────────────
  //
  useEffect(() => {

    // Function to update requests and update view status
    const updateRequests = async (filteredRequests) => {
      const update = await Api({
        endpoint: '/requests',
        method: 'PUT',
        data: { requestsViewed: filteredRequests }
      });

      if (update.success) {
        // Remove the counter div (id : newRequests)
        const counter = document.getElementById('newRequests');
        if (counter) counter.remove();
      }
    };

    // Set a timeout to delay the API call
    const timeoutId = setTimeout(() => {
      const filteredRequests = requests.requests
        .filter(request => request.view === 0)
        .map(request => request.id);
      updateRequests(filteredRequests);
    }, 3000); // Adjust the delay (in milliseconds) as needed

    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [requests]);



  //
  // ─── PAGINATION ───────────────────────────────────────
  //
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="première page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="page précédente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="page suivante"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="dernière page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - requestsList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return requests && <div className='containerAdminUser'>
    <Typography variant="h1" component="h1">Voir les commentaires</Typography>
    <div className='inputSearchTable'>
      <TextField className='searchInput' id="outlined-basic" label="Rechercher" variant="outlined"
        onChange={onSearchInput}
        sx={{ width: 300 }}
      />
    </div>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ background: '#e17f51' }}>
          <TableRow sx={{ '& th': { color: 'white' } }}>
            <TableCell>Date</TableCell>
            <TableCell>Salarié</TableCell>
            <TableCell>Enseigne</TableCell>
            <TableCell>Boutique</TableCell>
            <TableCell>Commentaire</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? requestsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : requestsList
          ).map((request, key) => {
            console.log('request ', request)
            return request.none ? <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7}>Pas de résultats</TableCell>
            </TableRow> : <TableRow
              hover
              key={key}
            >
              <TableCell>{formatDate({ time: request.time_create, display: 'date' })}</TableCell>
              <TableCell>{request.user_firstname} {request.user_name}</TableCell>
              <TableCell>{request.brand_name}</TableCell>
              <TableCell>{request.store_name}</TableCell>
              <TableCell>{request.content}</TableCell>
            </TableRow>
          })}

          {emptyRows > 0 && (
            <TableRow style={{ height: 53 * emptyRows }}>
              <TableCell colSpan={7} />
            </TableRow>
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25, { label: 'Tous', value: -1 }]}
              colSpan={7}
              count={requestsList.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'Lignes par page',
                },
                native: true,
              }}
              labelRowsPerPage={"Lignes par page"}
              labelDisplayedRows={
                ({ from, to, count }) => {
                  return '' + from + '-' + to + ' sur ' + count
                }
              }
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  </div>
};

export default ViewRequests;