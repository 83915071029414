// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Typography
} from "@mui/material";
//
// ─── UTILS ────────────────────────────
//
import Api from '../../../contexts/Api';
//
// ─── STYLE ────────────────────────────
//
import { createUseStyles } from "react-jss";

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import AnalyticsTable from '../../../components/Admin/Analytics/AnalyticsTable';
import DoughnutAdmin from '../../../components/Admin/Analytics/DoughnutAdmin';
import BarAdmin from '../../../components/Admin/Analytics/BarAdmin';
import MarketingAnalyticsTable from '../../../components/Admin/Analytics/MarketingAnalyticsTable';
import Export from '../../../components/Admin/Export';
import UsersBar from '../../../components/Admin/Analytics/UsersBar';
import { Link } from 'react-router-dom';
import { timeStamp } from '../../../contexts/Utils';
import BlogTable from '../../../components/Admin/Analytics/BlogTable';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListAnalytics = () => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // ANALYTICS USER REGISTERED
  const [analyticsUsers, setAnalyticsUsers] = useState([]);
  // ANALYTICS POINTS USERS
  const [userPoints, setUserPoints] = useState([]);
  // ANALYTICS GAINS LOYALTY UTILISES
  const [gainsPoints, setGainsPoints] = useState([]);
  // ANALYTICS GAINS COMPTA
  const [gainsCompta, setGainsCompta] = useState([]);
  // BRANDS
  const [brands, setBrands] = useState([]);
  // TRACKING ACTU OFFER
  const [trackingActuOffer, setTrackingActuOffer] = useState([]);
  // OFFERS
  const [offers, setOffers] = useState([]);
  // ACTUALITIES
  const [actualities, setActualities] = useState([]);
  // LOADING
  const [loading, setLoading] = useState(true);

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      gap: 30,

      '& .MuiPaper-rounded': {
        marginBottom: 30
      },

      '& h1': {
        marginBottom: 10
      },

      '& h2': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'
      }
    },
    gridContainer: {
      display: 'flex',
      gap: 30,
      alignItems: 'stretch'
    },
    containerBoxLeft: {
      width: window.innerWidth < 1500 ? '20%' : '15%',
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
    },
    containerDoughnutsBoxLeft: {
      width: '40%',
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
    },
    containerBoxRight: {
      width: window.innerWidth < 1500 ? '80%' : '85%',
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
    },
    containerAllPage: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      padding: '25px 30px',
      gap: 20,
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 2px 44px rgba(0, 0, 0, 0.05)',
      borderRadius: 7,

      '& .text': {
        fontSize: 16,
        fontWeight: 400,
        opacity: 0.5
      },

      '& .number': {
        fontSize: 70,
        color: '#E17F51',
        fontWeight: 900,
        margin: '0 auto'
      },

      '& .MuiInputBase-root': {
        padding: 0,
      },

      '& fieldset': {
        border: '1px solid #999999',
        borderRadius: 3
      }
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '25px 30px',
      gap: 20,
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 2px 44px rgba(0, 0, 0, 0.05)',
      borderRadius: 7,

      '& .text': {
        fontSize: 16,
        fontWeight: 400,
        opacity: 0.5
      },

      '& .number': {
        fontSize: 70,
        color: '#E17F51',
        fontWeight: 900,
        margin: '0 auto'
      },

      '& .MuiInputBase-root': {
        padding: 0,
      },

      '& fieldset': {
        border: '1px solid #999999',
        borderRadius: 3
      }
    },
    boxSimple: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      gap: 20,
    },
    brandContainer: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(75px, 1fr))',
      gap: 10,
    },
    brandLink: {
      display: 'flex',
      width: 75,
      height: 75,
      padding: 12,
      gap: 10,
      background: '#F3F4FA',
      borderRadius: 3,

      '& img': {
        width: '100%',
        height: '100%',
        objectFit: 'contain'
      }
    },
    used: {
      color: '#3d5a80',
      background: '#e0fbfc',
      padding: '3px 6px'
    },
    won: {
      color: 'rgb(34, 154, 22)',
      background: 'rgb(228, 248, 221)',
      padding: '3px 6px'
    },
    doughnutContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      marginTop: 30
    },
    doughnutCol: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      alignItems: 'center',
      width: '100%'
    }
  })
  const classes = useStyle();

  //
  // ─── REQUEST TO GET ALL NECESSARY DATA ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = () => {
      Promise.all([
        Api({ endpoint: '/brands', query: { fields: ['name', 'brand_id'], orderBy: 'name', brands_to_exclude: ["a7a5d846-d8db-4533-a6f4-3e71735c1f8d", "51914131-4943-49b3-955f-53738211fe29"] }, method: 'GET' }),
        Api({ endpoint: '/analytics/loyalty', method: 'GET' }),
        Api({ endpoint: '/analytics', query: { fields: ['analytics.time_create', 'COALESCE(analytics.label, "") as label', 'analytics.gain_id', 'analytics.status', 'analytics.loyalty', 'COALESCE(brands.name, "") as brandName', 'COALESCE(stores.name, "") as storeName', 'COALESCE(users.name, "") as userName', 'COALESCE(users.firstname, "") as userFirstname'], join_query: 'LEFT JOIN brands ON brands.brand_id=analytics.brand_id LEFT JOIN stores ON stores.store_id=analytics.store_id LEFT JOIN users on users.user_id=analytics.user_id', loyalty: 0, status: 1 }, method: 'GET' }),
        Api({ endpoint: '/analytics/users_points', method: 'GET' }),
        Api({ endpoint: '/users', query: { fields: ['COUNT(users.user_id) as utilisateurs'], status: 2, role: 2, removeAnalytics: true }, method: 'GET' }),
        Api({ endpoint: '/tracking_actu_offer', method: 'GET' }),
        Api({ endpoint: '/offers', query: { fields: ['title', 'offer_id'] }, method: 'GET' }),
        Api({ endpoint: '/actualities', query: { fields: ['title', 'actuality_id'] }, method: 'GET' }),
      ])
        .then(([brandReq, loyaltyReq, comptaReq, userPointsReq, usersReq, trackingReq, offersReq, actualitiesReq]) => {
          if (brandReq.success) setBrands(brandReq.data);
          if (loyaltyReq.success) setGainsPoints(loyaltyReq.data);
          if (comptaReq.success) setGainsCompta(comptaReq.data);
          if (userPointsReq.success) setUserPoints(userPointsReq.data);
          if (usersReq.success) setAnalyticsUsers(usersReq.data);
          if (trackingReq.success) setTrackingActuOffer(trackingReq.data);
          if (offersReq.success) setOffers(offersReq.data);
          if (actualitiesReq.success) setActualities(actualitiesReq.data);
          setLoading(false);
        });
    }

    setBrands([]);
    setGainsPoints([]);
    setGainsCompta([]);
    setUserPoints([]);
    setAnalyticsUsers([]);
    setTrackingActuOffer([]);
    fetchData();
  }, []);

  console.log('trackingActuOffer', trackingActuOffer)

  const trackingActualities = trackingActuOffer.filter(tracking => tracking.type === 1) ?? [];
  const trackingOffers = trackingActuOffer.filter(tracking => tracking.type === 2) ?? [];
  const actualitiesWithViews = actualities.map(actuality => {
    let views = 0;
    let percentage = 0;
    const tracked = trackingActualities.find(trackedItem => {
      return trackedItem.type_id === actuality.actuality_id;
    });
    if (tracked) { views = tracked.views; percentage = tracked.views / analyticsUsers[0].utilisateurs * 100; }
    return {
      ...actuality,
      views,
      percentage
    };
  });
  const offersWithViews = offers.map(offer => {
    let views = 0;
    let percentage = 0;
    const tracked = trackingOffers.find(trackedItem => {
      return trackedItem.type_id === offer.offer_id;
    });
    if (tracked) { views = tracked.views; percentage = tracked.views / analyticsUsers[0].utilisateurs * 100; }
    return {
      ...offer,
      views,
      percentage
    };
  });

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return analyticsUsers.length && <div className={classes.container}>
    <div>
      <Typography variant="h1" component="h1">Statistiques</Typography>
    </div>
    <div className={classes.gridContainer}>
      <div className={classes.containerBoxLeft}>
        <div className={classes.box}>
          <Typography variant="h2" component="h2">Inscrits</Typography>
          <div className='text'>Nombre d’inscrits au programme de fidélité</div>
          <span className='number'>{analyticsUsers[0].utilisateurs}</span>
        </div>
        {userData.role === 1 && <div className={classes.box} style={{ height: '100%', justifyContent: 'flex-start' }}>
          <Typography variant="h2" component="h2">Enseignes</Typography>
          <div className={classes.brandContainer}>
            {brands.map(brand => {
              return <Link className={classes.brandLink} to={`/admin/analytics/${brand.brand_id}`} key={brand.brand_id}>
                <img src={`${process.env.REACT_APP_URL}/img/brands/${brand.brand_id}/${brand.logo}?${timeStamp()}`} alt='logo' />
              </Link>
            })}
          </div>
        </div>}
      </div>
      <div className={classes.containerBoxRight}>
        <div className={classes.box}>
          <Typography variant="h2" component="h2">Inscrits par enseigne</Typography>
          <UsersBar />
        </div>
        <div className={classes.box}>
          <Typography variant="h2" component="h2">Passage en caisse</Typography>
          <BarAdmin brands={brands} />
        </div>
      </div>
    </div>
    <div className={classes.gridContainer}>
      <div className={classes.containerDoughnutsBoxLeft}>
        <div className={classes.box} style={{ height: '100%', justifyContent: 'flex-start' }}>
          <Typography variant="h2" component="h2" sx={{ margin: '0 auto' }}>Utilisation des paliers</Typography>
          <div className='doughnutContainer'>
            <div className='text'>Points de fidélité des utilisateurs</div>
            {userPoints.length ? <DoughnutAdmin data={userPoints} /> : <div>Pas de données d'analytics pour le moment.</div>}
          </div>
          <div className='separator'></div>
          <div className='doughnutContainer'>
            <div className='text'>Gains paliers utlisés</div>
            {gainsPoints.length ? <DoughnutAdmin data={gainsPoints} /> : <div>Pas de données d'analytics pour le moment.</div>}
          </div>
          <div className='doughnutContainer'>
            <Export data={{ users: userPoints, gains: gainsPoints }} date={{ start: '', end: '' }} type='palier' />
          </div>

        </div>
      </div>
      <div className={classes.containerBoxRight}>
        <div className={classes.box}>
          <Typography variant="h2" component="h2">Gains</Typography>
          {[1, 5].includes(userData.role) && Boolean(gainsCompta.length) ?
            <AnalyticsTable analytics={gainsCompta} name={true} />
            :
            <div>Pas de données d'analytics pour le moment.</div>}
        </div>
        <div className={classes.box}>
          <Typography variant="h2" component="h2">Gains utilisés</Typography>
          {[1, 6].includes(userData.role) &&
            <MarketingAnalyticsTable brands={brands} />
          }
        </div>
      </div>
    </div>
    {[1].includes(userData.role) && <div className={classes.containerAllPage}>
      <div className={classes.boxSimple}>
        <Typography variant="h2" component="h2">Actualités</Typography>
        {Boolean(trackingActualities.length) ?
          <BlogTable type_title={'Actualité'} tracking={actualitiesWithViews} />
          :
          <div>Pas de données d'analytics pour le moment.</div>}
      </div>
      <div className='separator_vertical'></div>
      <div className={classes.boxSimple}>
        <Typography variant="h2" component="h2">Offres</Typography>
        {Boolean(trackingOffers.length) ?
          <BlogTable type_title={'Offre'} tracking={offersWithViews} />
          :
          <div>Pas de données d'analytics pour le moment.</div>}
      </div>
    </div>}
  </div>
};

export default ListAnalytics;