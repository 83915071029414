// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Api from '../../../contexts/Api';

import EditActualityForm from '../../../components/Admin/EditActualityForm';
import TitleForm from '../../../components/Admin/TitleForm';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditActuality = () => {
  const params = useParams();

  const id = params.actuality_id

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [actuality, setActuality] = useState({})

  //
  // ─── SEARCH REQUEST TO GET ACTUALITY ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchActuality = async () => {
      const getActuality = await Api({
        endpoint: '/actualities',
        query: { actuality_id: id },
        method: 'GET',
      });

      if (getActuality.success) {
        setActuality(getActuality.data);
      } else {
        setActuality({});
      }
    };
    if (!Object.keys(actuality).length) fetchActuality();
  }, [actuality, id]);
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='containerAdmin'>
    <TitleForm text="Modifier l'actualité" />
    {Object.keys(actuality).length && <EditActualityForm update={true} default={actuality} />}
  </div>
};

export default EditActuality;