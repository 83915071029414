// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { counter } from '@fortawesome/fontawesome-svg-core';
import React from 'react'

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Button from '../Button';

// SCSS
import './Modal.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Modal = ({ onClose, onConfirm, data }) => {

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className="overlay"></div>
    <div className="modal">
      <h2>{data.title}</h2>
      {data.text && <p>{data.text}</p>}
      {!data.counter && <div className='buttonContainer'>
        <Button className="button secondary" text='Annuler' onClick={onClose} />
        <Button className="button fourth" text='Confirmer' onClick={onConfirm} />
      </div>}

    </div>
  </>
}

export default Modal;