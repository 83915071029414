// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React, { useEffect, useState } from 'react';
import { Link, Outlet } from "react-router-dom";
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from 'universal-cookie';

//
//  ─── IMPORT UTILS ───────────────────────────────────────
//
import { Logout } from '../../contexts/Utils';

//
//  ─── ICONES POUR L'OVERLAY ───────────────────────────────────────
//
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import AppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

//
// ─── ICONES POUR LES MENUS ───────────────────────────────────────
//
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LogoutIcon from '@mui/icons-material/Logout';
import StoreIcon from '@mui/icons-material/Store';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import ArticleIcon from '@mui/icons-material/Article';
import DiscountIcon from '@mui/icons-material/Discount';
import HomeIcon from '@mui/icons-material/Home';
import RedeemIcon from '@mui/icons-material/Redeem';
import BarChartIcon from '@mui/icons-material/BarChart';
import MessageIcon from '@mui/icons-material/Message';

import { ThemeProvider, createTheme } from '@mui/material/styles';

import LogoBK from '../../assets/static/LOGOBK.png'

import Api from '../../contexts/Api';


// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AdminLayout = () => {

  const [newRequests, setNewRequests] = useState(0);

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};

  // redirect
  let navigate = useNavigate();

  // url
  const url = useLocation();

  // GET REQUESTS WITH VIEW 0
  const getRequests = async () => {
    const requests = await Api({
      endpoint: '/requests',
      method: 'GET',
      query: { view: 0 },
    });

    if (requests.success && requests.data.length) {
      setNewRequests(requests.data.length);
    }
  }

  useEffect(() => {
    if (userData.role === 1) {
      getRequests();
    }
  }, [userData.role])


  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //

  const theme = createTheme({
    palette: {
      primary: {
        light: '#e17f51',
        main: '#e17f51',
        dark: '#f6986c',
        contrastText: '#FFF',
      },
      secondary: {
        light: '#ff7961',
        main: '#f44336',
        dark: '#ba000d',
        contrastText: '#000',
      },
    },
    typography: {
      h1: {
        fontSize: '3rem',
        marginBottom: '40px',
        fontWeight: 'bold'
      },
      h2: {
        fontSize: '2rem',
        fontWeight: 'bold'
      },
      h3: {
        fontSize: '2.5rem',
        margin: '30px 0px'
      }
    },
    components: {
      MuiFormControl: {
        styleOverrides: {
          root: {
            margin: '10px 0px',
          }
        }
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            padding: '5px 0px 0px 0px',
          }
        }
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            fontSize: '20px',
          }
        }
      },
      MuiListItemIcon: {
        styleOverrides: {
          root: {
            minWidth: '40px',
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: '5px',
          }
        }
      }
    }
  });

  // css pour la sidebar
  const drawerWidth = 175;

  // Tableau pour les différents liens menu
  const menu = [
    {
      text: 'Accueil',
      icon: <HomeIcon />,
      url: '/admin',
      role: [1, 4, 5, 6, 7, 8]
    },
    {
      text: 'Actualités',
      icon: <ArticleIcon />,
      url: 'actualites',
      role: [1, 6]
    },
    {
      text: "Offres",
      icon: <DiscountIcon />,
      url: 'offres',
      role: [1, 6]
    },
    {
      text: "Enseignes",
      icon: <StoreIcon />,
      url: 'enseignes',
      role: [1, 6]
    },
    {
      text: "Gains",
      icon: <RedeemIcon />,
      url: 'gains',
      role: [1]
    },
    {
      text: "Utilisateurs",
      icon: <Diversity3Icon />,
      url: 'utilisateurs',
      role: [1, 4, 6, 7, 8]
    },
    {
      text: "Analytics",
      icon: <BarChartIcon />,
      url: 'analytics',
      role: [1, 5, 6]
    },
    {
      text: "Requêtes",
      icon: <MessageIcon />,
      url: 'requetes',
      role: [1],
      new: newRequests
    },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <Box sx={{ display: 'flex', m: 2 }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar sx={{ paddingLeft: '25px' }}>
            <div>
              <img loading="lazy" src={LogoBK} style={{ width: 60 }} alt='Logo BK' />
            </div>

            <Typography variant="h6" noWrap component="div" sx={{ paddingLeft: '130px!important' }}>
              Dashboard gestion BK fidélité
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
          }}
        >
          <Toolbar />
          <Box sx={{ overflow: 'auto' }}>
            <List>
              {menu.map((single, index) => {
                return single.role.includes(userData.role) && <ListItem key={single.text} button component={Link} to={index > 0 ? `admin/${single.url}` : single.url} selected={index > 0 ? url.pathname.includes(single.url) : url.pathname === single.url} disablePadding>
                  <ListItemButton>
                    <ListItemIcon>
                      {single.icon}
                    </ListItemIcon>
                    <ListItemText primary={single.text} />
                    {(Boolean(single.new) && single.new > 0) && <span id="newRequests" className='counterRequests'>{single.new}</span>}
                  </ListItemButton>
                </ListItem>
              })}
            </List>
            <Divider />
            <List>
              <ListItem disablePadding button component={Link} to="/">
                <ListItemButton>
                  <ListItemIcon>
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText primary="Retour" />
                </ListItemButton>
              </ListItem>

              <ListItem disablePadding button onClick={Logout}>
                <ListItemButton>
                  <ListItemIcon>
                    <LogoutIcon />
                  </ListItemIcon>
                  <ListItemText primary="Déconnexion" />
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </ThemeProvider>
    </Box>
  )
};

export default AdminLayout;