// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Cookies from 'universal-cookie';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import ModalQrCode from '../Modals/Qr/User/ModalQrCode';
import ModalReadQrCode from '../Modals/Qr/Scan/ModalReadQrCode';

// 
// ─── IMPORTING SVG ───────────────────────────────────────
//
import background from '../../assets/static/navbar-70.svg'
import { ReactComponent as ScanQr } from '../../assets/static/scanQr.svg'
// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/pro-regular-svg-icons'
import { faGift } from '@fortawesome/pro-regular-svg-icons'
import { faQrcode } from '@fortawesome/pro-solid-svg-icons'
import { faXmark } from '@fortawesome/pro-solid-svg-icons'
import { faLock } from '@fortawesome/pro-regular-svg-icons'
import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { faBarcode } from '@fortawesome/pro-regular-svg-icons'
import { faTag } from '@fortawesome/pro-regular-svg-icons';

// SCSS
import './NavigationFooter.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const NavigationFooter = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  // Modal
  const [openModal, setOpenModal] = useState(false)
  const [openModalReader, setOpenModalReader] = useState(false)

  // Fontawesome init
  library.add(faHouse, faTag, faGift, faQrcode, faXmark, faLock, faUser, faBarcode)

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);


  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    {/* MODAL QR */}
    {openModal && <ModalQrCode open={openModal} />}
    {openModalReader && <ModalReadQrCode open={openModalReader} />}
    {[3].includes(userData.role) ? <div className="bottom-appbar">
      <div className="tabs">
        <div className="tab tab--left"></div>
        <div className="tab tab--fab">
          <div className='fab' onClick={() => setOpenModalReader(!openModalReader)}>
            {!openModalReader ? <ScanQr className='scanQr' /> : <FontAwesomeIcon className='qr' icon="fa-solid fa-xmark" />}
          </div>
        </div>
        <div className="tab tab--right"></div>
      </div>
    </div> :
      <div className="bottom-appbar">
        <img loading="lazy" src={background} alt='background-NavBar' className='background-nav' />

        <div className="tabs">
          <div className="tab tab--left">
            <NavLink to='/' end className={({ isActive }) => isActive ? 'active' : ''}>
              <FontAwesomeIcon className='icons' icon="fa-regular fa-house" />Accueil
            </NavLink>
            <NavLink to='/offres' className={({ isActive }) => isActive ? 'active' : ''}>
              <FontAwesomeIcon className='icons' icon="fa-regular fa-tag" />Offres
            </NavLink>
          </div>
          <div className="tab tab--fab">
            {([1, 2, 4].includes(userData.role)) && <div className='fab' onClick={() => setOpenModal(!openModal)}>
              {!openModal ? <FontAwesomeIcon className='qr' icon="fa-solid fa-qrcode" /> : <FontAwesomeIcon className='qr' icon="fa-solid fa-xmark" />}
            </div>}
          </div>
          <div className="tab tab--right">
            <NavLink to='/gains' className={({ isActive }) => isActive ? 'active' : ''}>
              <FontAwesomeIcon className='icons' icon="fa-regular fa-gift" />Gains
            </NavLink>
            <NavLink to='/mon-compte' className={({ isActive }) => isActive ? 'active' : ''}> <FontAwesomeIcon className='icons' icon="fa-regular fa-user" /> Profil</NavLink>
          </div>
        </div>
      </div>}
  </>
};

export default NavigationFooter;