// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react';

import EditOfferForm from '../../../components/Admin/EditOfferForm';
import TitleForm from '../../../components/Admin/TitleForm';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AddOffer = () => {
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='containerAdmin'>
    <TitleForm text='Ajouter une offre' />
    <EditOfferForm />
  </div>
};

export default AddOffer;