// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState } from 'react';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from "@mui/material";

// 
// ─── UTILS ───────────────────────────────────────
//
import { prepareFormData } from '../../../../contexts/Utils';
import Api from '../../../../contexts/Api';
import { useNotification } from '../../../UseNotification';

import Formfields from '../../../Admin/FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditBrand = (props) => {
  const brand = props.default || {}
  const open = props.open
  const onClose = props.onClose

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});

  // Admin notification
  const { notification } = useNotification()

  // 
  // ─── HANDLE ADD BRAND ───────────────────────────────────────
  //
  const handleAddBrand = async () => {
    let data = prepareFormData({ formId: 'manageBrand' })

    if (data.errors) {
      setErrors({ ...errors, 'manageBrand': { ...data.errors } })
    } else {
      //
      // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
      //
      const file = data['fields']['logo_enseigne']
      const formData = new FormData();
      if (file) {
        formData.append('file', file, `logo_enseigne-${file.name}`)
        delete data['fields']['logo_enseigne']
      }
      if (brand) {
        // si update uniquement 
        formData.append('brand_id', brand.brand_id)
        delete data['fields']['logo_enseigne']
      }
      formData.append('fields', JSON.stringify(data.fields))

      const addBrand = await Api({
        data: formData,
        method: Object.keys(brand).length ? 'PUT' : 'POST',
        endpoint: '/brands',
        removeHeader: true
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (addBrand.success) {
        onClose()
        return notification({ variant: 'success', message: addBrand.message })
      } else {
        notification({ variant: 'error', message: addBrand.message })
      }
    }
  }

  // 
  // ─── UPLOAD FILE ───────────────────────────────────────
  //
  const uploadFile = async (event) => {
    const element = event.target
    // 
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const formData = new FormData();
    if (element.files[0]) {
      formData.append('file', element.files[0], `${element.name}-${element.files[0].name}`);
    }
    formData.append('id', brand.brand_id)
    formData.append('file', 'brands')
    formData.append('type', element.name)
    // m
    // ─── API REQUEST FOR FILE UPLOAD ───────────────────────────────────────
    //
    const params = {
      data: formData,
      endpoint: '/files',
      method: 'POST',
      removeHeader: true
    }
    const addFile = await Api(params);
    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    if (addFile && addFile.success) {
      return notification({ variant: 'success', message: addFile.message })
    } else {
      notification({ variant: 'error', message: addFile.message })
    }
  }
  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const optionsLogo = { fileName: brand.logo, link: `/brands/${brand.brand_id}/${brand.logo}` }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle id="alert-dialog-title">
        {Object.keys(brand).length ? "Modifier l'enseigne" : "Ajouter une enseigne"}
      </DialogTitle>
      <DialogContent>
        <Box className='manageStoreForm' component="form" name='manageBrand'>
          <Formfields field={{ type: 'text', component: 'text', name: 'name', label: "Nom de l'enseigne", default: brand.name }} />
          {Boolean(Object.keys(brand).length) && <Formfields field={{ type: 'text', component: 'text', name: 'position', label: "Position de l'enseigne", default: brand.position }} />}
          <Formfields field={{ type: 'text', component: 'file', name: 'logo_enseigne', label: "Logo (maximum 8mo)", ...(Object.keys(brand).length && { handleChange: uploadFile }), ...(Object.keys(brand).length && optionsLogo) }} />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button variant="contained" onClick={handleAddBrand}>{Object.keys(brand).length ? "Enregistrer" : "Ajouter"}</Button>
      </DialogActions>
    </Dialog>
  )
};

export default EditBrand;