// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Title from '../components/Title';
import HeroBanner from '../components/HeroBanner';
import Button from '../components/Button';

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../contexts/Api';
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Actuality = () => {

  // Récupérer l'id de l'actualité
  const params = useParams();

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [actu, setActu] = useState({})

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    offreInfos: {
      paddingLeft: '20px',

      '& h3': {
        fontWeight: '600',
        color: 'var(--grey)'
      },
      '& h5': {
        fontWeight: '300',
        color: 'var(--grey)'
      },
    },
    offreContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 20px',
      minHeight: '200px',
      borderRadius: '30px',
      justifyContent: 'space-around',
      marginTop: '25px',

      '& p': {
        color: 'var(--grey)',
        fontSize: 14
      }
    },
  })
  const classes = useStyle();

  //
  // ─── SEARCH REQUEST TO GET ACTU ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchActu = async () => {
      const getActu = await Api({
        endpoint: '/actualities',
        query: { actuality_id: params.actuality_id },
        method: 'GET',
      });

      if (getActu.success) {
        setActu(getActu.data);
      } else {
        setActu({});
      }
    };
    if (!Object.keys(actu).length) fetchActu();
  }, [actu, params.actuality_id]);

  // 
  // ─── TRACKING USER ACTUALITY ───────────────────────────────────────
  //
  useEffect(() => {
    const trackingUserActuality = async () => {
      await Api({ endpoint: '/tracking_actu_offer', method: 'POST', data: { type: 1, type_id: params.actuality_id } })
    }
    if (userData.role === 2) trackingUserActuality()
  }, [])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return actu && <>
    <Title text={actu.title} return={true} />
    <HeroBanner link={`actualities/${actu.actuality_id}/${actu.banner ? actu.banner : actu.thumbnail}`} />
    <div className={classes.offreInfos}>
      {actu.title && <h3>{actu.title}</h3>}
      {actu.subtitle && <h5>{actu.subtitle}</h5>}
    </div>
    <div className={classes.offreContainer}>
      {actu.content && parse(actu.content)}
      {actu.url && <Button className="button cta" text={actu.cta ? actu.cta : 'Voir plus'} href={actu.url} />}
    </div>
  </>
};

export default Actuality;