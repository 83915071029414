// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'

// 
// ─── UTILS ───────────────────────────────────────
//
import { getValue, prepareFormData, formatDate } from '../../contexts/Utils'
import Api from '../../contexts/Api'
import { Logout, timeStamp } from '../../contexts/Utils'
// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../../components/Formfields'
import Modal from '../Modals/Modal';
import Button from '../Button'
import Title from '../Title'
import { useNotification } from '../UseNotification'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt } from '@fortawesome/pro-solid-svg-icons'
import { faPhone } from '@fortawesome/pro-regular-svg-icons'
import { faEnvelope } from '@fortawesome/pro-regular-svg-icons'

import './AccountInfos.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AccountInfos = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  // Formfields errors
  const [errors, setErrors] = useState({})
  const [user, setUser] = useState({})
  // Update password
  const [modifyPassword, setModifyPassword] = useState(false)
  // Reset password fields
  const [reset, setReset] = useState(false)
  // Modals
  const [modal, setModal] = useState({
    open: false,
    data: {
      title: '',
      text: ''
    },
    onClick: false
  });

  // redirect
  let navigate = useNavigate();

  // Admin notification
  const { notification } = useNotification()

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE) || {};
  const id = userData.user_id

  //
  // ─── SEARCH REQUEST TO GET USER INFOS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUser = async () => {
      const getUser = await Api({
        endpoint: '/users',
        query: { fields: ['name', 'firstname', 'city', 'cp', 'address', 'birth_date', 'email', 'phone'], query_user_id: id },
        method: 'GET',
      });

      if (getUser.success) {
        setUser(getUser.data);
      }
    };
    if (!Object.keys(user).length) fetchUser();
  }, [user, id]);

  // 
  // ─── HANDLE CHANGE INFOS ───────────────────────────────────────
  //
  const handleChangeInfos = async () => {
    let data = prepareFormData({ formId: 'accountInfosForm' })

    // REMOVE FIELDS THAT ARE NOT SUPPOSED TO BE MODIFIED
    delete data.fields.name
    delete data.fields.firstname
    delete data.fields.user_date

    data.user_edit = id

    if (data.errors) {
      setErrors({ ...errors, 'accountInfosForm': { ...errors } })
    } else {
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageUser = await Api({
        data: data,
        method: 'PUT',
        endpoint: '/users',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUser.success) {
        return notification({ variant: 'success', message: manageUser.message })
      } else {
        notification({ variant: 'error', message: manageUser.message })
      }
    }
  }

  // 
  // ─── HANDLE CHANGE PASSWORD ───────────────────────────────────────
  //
  const handleChangePassword = async () => {
    let data = prepareFormData({ formId: 'accountPasswordForm' })

    let newpassword = getValue(data, ['fields', 'password'])
    let confirmpassword = getValue(data, ['fields', 'checkpassword'])

    if (data.errors) {
      setErrors({ ...errors, 'accountPasswordForm': { ...errors } })
    } else if (newpassword !== confirmpassword) {
      setErrors({ ...errors, 'accountPasswordForm': { checkpassword: { error: true, message: 'Les mots de passe ne sont pas identiques' } } })
    } else {
      if (Object.keys(errors).length) {
        setErrors({});
      }
      delete data.fields.checkpassword
      //
      // ─── API REQUEST ───────────────────────────────────────
      //
      const manageUserPassword = await Api({
        data: data,
        method: 'PUT',
        endpoint: '/users/password',
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUserPassword.success) {
        setReset(true)
        return notification({ variant: 'success', message: manageUserPassword.message })
      } else {
        notification({ variant: 'error', message: manageUserPassword.message })
      }
    }
    setReset(false)
  }

  // 
  // ─── HANDLE MODALS ───────────────────────────────────────
  //
  // LOGOUT
  const logoutModal = () => {
    setModal({
      open: true,
      data: {
        title: 'Souhaitez-vous vous déconnecter ?'
      },
      onClick: true
    })
  }
  // DELETE ACCOUNT
  const deleteAccountModal = () => {
    setModal({
      open: true,
      data: {
        title: 'Souhaitez-vous vous supprimer votre compte ?'
      },
      onClick: false
    })
  }

  //
  // ─── ON CONFIRM MODALS ───────────────────────────────────────
  //
  // HANDLE DELETE ACCOUNT
  const deleteAccount = async () => {
    const deleteAccounUser = await Api({
      data: { fields: { deleted: 1 }, email: true, delete: true, user_edit: id },
      method: 'PUT',
      endpoint: '/users',
    })

    if (deleteAccounUser && deleteAccounUser.success) {
      setModal({
        data: {
          title: 'Votre demande de suppression de compte a bien été prise en compte.',
          text: 'Vous allez être déconnecté.',
          counter: true
        },
        open: true
      })
      setTimeout(() => { Logout() }, 3000);
    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    {
      title: 'À propos de moi',
      infos: [
        { label: "Nom", default: user.name },
        { label: "Prénom", default: user.firstname },
        { label: "Date de naissance", default: formatDate({ time: user.birth_date, display: 'date' }) }
      ]
    },
    {
      title: 'Coordonnées',
      fields: [
        { type: "email", component: "text", name: "email", label: "E-mail", default: user.email },
        { type: "tel", component: "text", name: "phone", label: "Numéro de téléphone", default: user.phone }
      ]
    },
    {
      title: 'Adresse',
      fields: [
        { type: "text", component: "text", name: "address", label: "Adresse", default: user.address },
        { type: "text", component: "text", name: "cp", label: "Code postal", default: user.cp },
        { type: "text", component: "text", name: "city", label: "Ville", default: user.city }
      ]
    }
  ]

  const formFieldsPassword = [
    { type: 'password', component: "text", name: "password_old", label: "Ancien mot de passe", iconEnd: true, id: 'password', reset },
    { type: 'password', component: "text", name: "password", label: "Nouveau mot de passe", iconEnd: true, id: 'password2', reset },
    { type: 'password', component: "text", name: "checkpassword", label: "Veuillez répéter le mot de passe", iconEnd: true, id: 'password3', reset },
  ]

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return Object.keys(user).length && <>
    {/* MODALS */}
    {modal.open && <Modal open={modal.open} data={modal.data} onConfirm={modal.onClick ? Logout : deleteAccount} onClose={() => setModal({ open: false })} />}
    {/* ANCRES */}
    <div className='anchors'>
      <a href='#accountInfosForm'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M304 128a80 80 0 1 0 -160 0 80 80 0 1 0 160 0zM96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM49.3 464H398.7c-8.9-63.3-63.3-112-129-112H178.3c-65.7 0-120.1 48.7-129 112zM0 482.3C0 383.8 79.8 304 178.3 304h91.4C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7H29.7C13.3 512 0 498.7 0 482.3z" /></svg>
        Profil
      </a>
      <a href='#modifyPassword'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M36.4 360.9L13.4 439 1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1L73 498.6l78.1-23c12.4-3.6 23.7-9.9 33.4-18.4c1.4-1.2 2.7-2.5 4-3.8L492.7 149.3c21.9-21.9 24.6-55.6 8.2-80.5c-2.3-3.5-5.1-6.9-8.2-10L453.3 19.3c-25-25-65.5-25-90.5 0L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4zm46 13.5c1.7-5.6 4.5-10.8 8.4-15.2c.6-.6 1.1-1.2 1.7-1.8L321 129 383 191 154.6 419.5c-4.7 4.7-10.6 8.2-17 10.1l-23.4 6.9L59.4 452.6l16.1-54.8 6.9-23.4z" /></svg>
        Mot de <br /> passe
      </a>
      <a href='#titleSocialsContainer'>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z" /></svg>
        Contact
      </a>
    </div>
    <Title text="Mon profil" />
    <div className='profilContainer'>
      {/* INFOS USER */}
      <div className='accountContainer'>
        <div className='user_image'>
          <img loading="lazy" src={`${process.env.REACT_APP_URL}/img/users/${id}/${user.thumbnail}?${timeStamp()}`} alt={userData.name} />
        </div>
        <form className='form' name='accountInfosForm' id='accountInfosForm'>
          {formFields.map((form) => {
            return <div key={form.titre}>
              <h2>{form.title}</h2>
              {form.title === 'À propos de moi' ? <div className='infosUserPageContainer'>
                {form.infos.map((info) => {
                  return <div className='infosUserPage'>
                    <span>{info.label}</span>
                    {info.default}
                  </div>
                })}
              </div>
                :
                form.fields.map((field) => {
                  return <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['accountInfosForm', field.name, 'message']) } }} />
                })}
            </div>
          })}
          <Button className="button third" text='Sauvegarder' onClick={handleChangeInfos} />
        </form>
      </div>

      {/* PASSWORD USER */}
      <div className='modifyPassword' id='modifyPassword'>
        <h3>Mot de passe</h3>
        <div className='modify' onClick={() => setModifyPassword(!modifyPassword)}>Modifier</div>
      </div>

      {modifyPassword && <div className='accountContainer'>
        <form className='form' name='accountPasswordForm' id='accountPasswordForm'>
          {formFieldsPassword.map((field) => (<Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['accountPasswordForm', field.name, 'message']) } }} />))}
          {modifyPassword && <Button className="button third" text='Sauvegarder' onClick={handleChangePassword} />}
        </form>
      </div>
      }


      <div className='buttonsUser'>
        <Button className="button third" text='Déconnexion' onClick={logoutModal} />
        <Button className="button secondary" text='Supprimer le compte' onClick={deleteAccountModal} />
      </div>

      <div className='accountContainer'>
        <div className='titleSocialsContainer' id='titleSocialsContainer'><h2 className='titleSocials'>Nous contacter</h2></div>
        <div className='contact'>
          <div>
            <h3><FontAwesomeIcon className='iconsContact' icon={faAt} />Par mail</h3>
            <a href='mailto:marketing@groupebk.com'>marketing@groupebk.com</a>
          </div>
          <div>
            <h3><FontAwesomeIcon className='iconsContact' icon={faPhone} />Par téléphone</h3>
            <a href='tel:0389430430'>03 89 430 430</a>
          </div>
          <div>
            <h3><FontAwesomeIcon className='iconsContact' icon={faEnvelope} />Par courrier</h3>
            <a href='http://maps.google.com/?q=18 avenue de Hollande, 68000, ILLZACH'>18 avenue de Hollande - 68000 ILLZACH</a>
          </div>
        </div>
        <div className='links'>
          <a href='https://bk-fidelite.fr/file/BK&A-BK-FIDELITE-Mentions-l%C3%A9gales.pdf' target="_blank">Mentions légales | </a>
          <a href='/politique-de-confidentialite' target="_blank">Politique de confidentialité | </a>
          <a href='https://bk-fidelite.fr/file/BK&A-BK-FIDELITE-CGU.pdf' target="_blank">CGU</a>
        </div>
      </div>
    </div>
  </>
};

export default AccountInfos;