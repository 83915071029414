// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

//
// ─── MATERIAL UI  ────────────────────────────
//
// COMPONENTS
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
  Paper,
  Tooltip
} from "@mui/material";
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
// THEME
import { useTheme } from '@mui/material/styles';

// ─── UTILS ────────────────────────────
import Api from '../../../../contexts/Api';
import { useNotification } from '../../../UseNotification';
import { formatDate, timeStamp } from '../../../../contexts/Utils';

//
// ─── IMPORT MODAL ────────────────────────────
//
import AdminAlertPopOver from '../../../Modals/Admin/AdminAlertPopOver';

//
// ─── IMPORT FORM ────────────────────────────
//
import EditGainForm from '../../../Modals/Admin/Gains/Gains/EditGainForm';

//
// ─── IMPORT SCSS & PNG ────────────────────────────
//
import './ListGains.scss'
import LogoBK from '../../../../assets/static/LOGOBK.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListGains = ({ brands, brandsFilter }) => {
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // Gains
  const [gains, setGains] = useState({ gains: [], filtered: [] })
  const [clickedGain, setClickedGain] = useState('')
  // FILTRE GAINS
  const [filter, setFilter] = useState({ all: true, filters: '' })
  // TABLE PAGINATION
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  // Manage gains modal
  const [openManageGains, setOpenManageGains] = useState(false)

  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState(false);

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // Notification user
  const { notification } = useNotification()

  //
  // ─── SEARCH REQUEST TO GET ALL GAINS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchGains = async () => {
      const getGains = await Api({
        endpoint: '/gains',
        query: { level: null },
        method: 'GET',
      });

      if (getGains.success && getGains.data.length) {
        setGains({ gains: getGains.data, filtered: [] });
      }
    };
    if (!gains.gains.length) fetchGains()
  }, [gains]);

  // 
  // ─── HANDLE ADD / UPDATE GAIN ───────────────────────────────────────
  // 
  const handleGain = (prop) => {
    let isNewGain = gains.gains.filter(gain => gain.gain_id === prop.gain_id)
    if (prop.store_id === '-') prop.store_id = null
    if (!isNewGain.length) {
      setGains({ ...gains, gains: [...gains.gains, prop] })
    } else {
      let indexGain = gains.gains.findIndex(gain => gain.gain_id === prop.gain_id)
      gains.gains[indexGain] = prop
      setGains({ ...gains })
    }
  }

  // Update list on add / update 
  useEffect(() => {
    updateGainsList()
  }, [openManageGains])

  // FILTER GAINS
  useEffect(() => {
    if (filter.filters !== 0 && filter.filters.length) updateGainsList()
    if (filter.all) setGains({ ...gains, filtered: [] })
  }, [filter]);

  const updateGainsList = () => {
    let filterGainsList = gains.gains.filter(gain => gain.brand_id === filter.filters);
    setGains({ ...gains, filtered: filterGainsList })
  }

  let gainsList = gains.gains.filter(gain => brandsFilter.find(brand => brand.brand_id === gain.brand_id))
  if (gains.filtered.length || (filter.filters !== 0 && filter.filters.length)) {
    gainsList = gains.filtered
  }

  // 
  // ─── DELETE GAIN ───────────────────────────────────────
  //
  const deleteGain = async (gain_id) => {
    //
    // ─── API REQUEST ───────────────────────────────────────
    //
    const deleteGain = await Api({
      data: { gain_id },
      method: 'DELETE',
      endpoint: '/gains',
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (deleteGain.success) {
      // changer le state au lieu de retourner dans le fetch du useEffect
      setGains({ ...gains, gains: gains.gains.filter(gain => gain.gain_id !== gain_id) })
      setClickedGain('')
      return notification({ variant: 'success', message: deleteGain.message })
    } else {
      notification({ variant: 'error', message: deleteGain.message })
    }
  };

  //
  // ─── PAGINATION ───────────────────────────────────────
  //
  const TablePaginationActions = (props) => {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="première page"
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="page précédente"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="page suivante"
        >
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="dernière page"
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </Box>
    );
  }

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - gainsList.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return brands.length && gains && <>
    <form className='formFilterContainer'>
      <ul className='filterContainer'>
        <li onClick={() => setFilter({ all: true, filters: 0 })}>
          <input
            className='filterCheckbox'
            type="checkbox"
            checked={filter.all}
          />
          <label htmlFor="all">Tous</label>
        </li>
        {Object.values(brandsFilter).map((brand, index) => {
          return <li key={index} onClick={() => setFilter({ all: false, filters: brand.brand_id })}>
            <input
              className='filterCheckbox'
              id={brand.brand_id}
              type="checkbox"
              checked={brand.brand_id === filter.filters}
            />
            <label htmlFor={brand.name}>{brand.name}</label>
          </li>
        })}
      </ul>
    </form>
    <div className='gainContainer'>
      {[1, 4].includes(userData.role) && <div className='addGainButton' onClick={() => setOpenManageGains(true)}>
        Ajouter un gain <CardGiftcardIcon />
      </div>}
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1120 }} aria-label="simple table">
          <TableHead sx={{ background: '#e17f51' }}>
            <TableRow sx={{ '& th': { color: 'white' } }}>
              <TableCell></TableCell>
              <TableCell>Enseigne</TableCell>
              <TableCell>Boutique</TableCell>
              <TableCell>Nom</TableCell>
              <TableCell>Conditions</TableCell>
              {/* <TableCell>Date de fin</TableCell> */}
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? gainsList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : gainsList
            ).map((gain) => {
              // Brand (if there's one)
              let brand = brands?.find(e => e.brand_id === gain.brand_id) || []

              return gain.none ? <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5}>Pas de résultats</TableCell>
              </TableRow> : <TableRow
                hover
                key={gain.gain_id}
              >
                <TableCell align='center'>
                  {gain.brand_id ? <img loading="lazy" alt='thumbnail' src={`${process.env.REACT_APP_URL}/img/brands/${gain.brand_id}/${brand.logo}?${timeStamp()}`} className='thumbnailUser' /> :
                    <img alt='thumbnail' src={LogoBK} className='thumbnailUser' />}
                </TableCell>
                <TableCell>{brand.name}</TableCell>
                <TableCell>{gain.store_id ? brand.stores?.find(e => e.store_id === gain.store_id).name : '-'}</TableCell>
                <TableCell component="th" scope="row">
                  {gain.name}
                </TableCell>
                <TableCell>{gain.conditions ? gain.conditions : '-'}</TableCell>
                {/* <TableCell>{gain.time_offline ? formatDate({ time: gain.time_offline, display: 'date' }) : '-'}</TableCell> */}
                <TableCell>{[1, 4].includes(userData.role) && <Tooltip title='Editer'>
                  <IconButton aria-label="edit" onClick={() => { setClickedGain(gain); setOpenManageGains(true) }}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>}{[1, 4].includes(userData.role) && <Tooltip title='Supprimer'>
                  <IconButton aria-label="supprimer" onClick={() => { setClickedGain(gain.gain_id); setOpenAlert(true) }}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>}</TableCell>
              </TableRow>
            })}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={5} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'Tous', value: -1 }]}
                colSpan={5}
                count={gainsList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'Lignes par page',
                  },
                  native: true,
                }}
                labelRowsPerPage={"Lignes par page"}
                labelDisplayedRows={
                  ({ from, to, count }) => {
                    return '' + from + '-' + to + ' sur ' + count
                  }
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>

    {/* ADD OR EDIT GAINS GROUPS MODAL */}
    <EditGainForm open={openManageGains} data={{ brands, currentGain: clickedGain }} onUpdate={handleGain} onClose={() => { setOpenManageGains(false); setClickedGain('') }} />
    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert} text='Etes-vous sûr de supprimer ce gain ?' func={() => deleteGain(clickedGain)} onClose={() => { setOpenAlert(false); setClickedGain('') }} />
  </>
};

export default ListGains;