// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';

//
// ─── MATERIAL UI COMPONENTS ────────────────────────────
// 
import {
  IconButton,
  Tooltip,
} from "@mui/material";
// ICONS
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import StoreIcon from '@mui/icons-material/Store';

//
// ─── UTILS ────────────────────────────
//
import Api from '../../../../contexts/Api';
import { useNotification } from '../../../UseNotification';

// ─── IMPORTING POP OVER  ───────────────────────────────────────
import AdminAlertPopOver from '../../../Modals/Admin/AdminAlertPopOver';

//
// ─── IMPORT FORM ────────────────────────────
//
import EditGainsGroups from '../../../Modals/Admin/Gains/Groups/EditGainsGroups';

//
// ─── IMPORT SCSS ────────────────────────────
//
import './ListGainsGroups.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ListGainsGroups = ({ brands, onFilter }) => {

  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // Groupes
  const [groups, setGroups] = useState({})
  const [clickedGroup, setClickedGroup] = useState('')
  // Manage gains groups modal
  const [openManageGainsGroups, setOpenManageGainsGroups] = useState(false)
  // ALERT DIALOG
  const [openAlert, setOpenAlert] = useState(false);
  // Active class
  const [isActive, setIsActive] = useState(1)

  // Notification user
  const { notification } = useNotification()

  //
  // ─── SEARCH REQUEST TO GET ALL GAINS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchGroups = async () => {
      const getGroups = await Api({
        endpoint: '/settings/gains_groups',
        method: 'GET',
      });

      if (getGroups.success && getGroups.data) {
        setGroups(getGroups.data);
        onFilter(getGroups.data[1].brands)
      } else {
        setGroups({});
      }
    };
    fetchGroups()
  }, []);

  // 
  // ─── DELETE GAIN GROUP ───────────────────────────────────────
  //
  const deleteGroup = async (group_id) => {
    // delete clicked group from object groups
    delete groups[group_id]
    //
    // ─── API REQUEST ───────────────────────────────────────
    //
    const deleteGroup = await Api({
      data: { fields: JSON.stringify(groups) },
      method: 'POST',
      endpoint: '/settings/gains_groups',
    })

    // 
    // ─── ON SUCCESS ───────────────────────────────────────
    //
    if (deleteGroup.success) {
      setGroups(groups)
      setClickedGroup('')
      return notification({ variant: 'success', message: deleteGroup.message })
    } else {
      notification({ variant: 'error', message: deleteGroup.message })
    }
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return brands.length && <>
    <div className='groupContainer'>
      <div className='addContainer' onClick={() => setOpenManageGainsGroups(true)}>
        Ajouter un groupe
      </div>
      {Boolean(Object.entries(groups).length) && Object.entries(groups).map(([key, value]) => {
        return <div className={`groupDiv ${isActive === +key ? 'groupActive' : ''}`} key={key} onClick={() => { onFilter(value.brands); setIsActive(+key) }}>
          <div>
            {value.name}
          </div>
          <div>
            <Tooltip title='Modifier'>
              <IconButton aria-label="manage" onClick={() => { setClickedGroup(key); setOpenManageGainsGroups(true) }}>
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title='Supprimer'>
              <IconButton aria-label="delete" onClick={() => { setClickedGroup(key); setOpenAlert(true) }}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>

        </div>
      })}
    </div>

    {/* ADD OR EDIT GAINS GROUPS MODAL */}
    <EditGainsGroups open={openManageGainsGroups} data={{ groups, brands, currentGroup: clickedGroup }} onUpdate={(prop) => setGroups(prop)} onClose={() => { setOpenManageGainsGroups(false); setClickedGroup('') }} />

    {/* ALERT DIALOG */}
    <AdminAlertPopOver open={openAlert} text='Etes-vous sûr de supprimer ce groupe de gain ?' func={() => deleteGroup(clickedGroup)} onClose={() => { setOpenAlert(false); setClickedGroup('') }} />
  </>
};

export default ListGainsGroups;