// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react'

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../../../contexts/Api'

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Button from '../../../Button'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'

// SCSS
import './ModalNotScanned.scss'
import Formfields, { SelectField } from '../../../Formfields'
import { createUseStyles } from 'react-jss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const ModalNotScanned = ({ open, onClose }) => {
  // FONTAWESOME
  library.add(faXmark)

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [content, setContent] = useState("");
  const [brands, setBrands] = useState([]);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [success, setSuccess] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [error, setError] = useState(false);
  const [empty, setEmpty] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const res = await Api({ endpoint: '/brands', query: { store: true }, method: 'GET' });
      if (res.success) {
        setBrands(res.data);
      }
    };
    fetchData();
  }, [])

  // 
  const onSend = async () => {
    if (!content || !selectedBrand || !selectedStore) return setEmpty(true);
    const sendRequest = await Api({
      data: { content: content, store_id: selectedStore.store_id, brand_id: selectedBrand.brand_id },
      endpoint: '/requests',
      method: 'POST',
    });
    if (sendRequest.success) {
      setShowForm(false);
      setSuccess(true);
    } else {
      setError(true);
    }
  }

  const close = () => {
    setSuccess(false);
    setError(false);
    setEmpty(false);
    setShowForm(true);
    onClose();
  }

  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    close: {
      position: 'absolute',
      top: 10,
      right: 15,

      '& svg': {
        fontSize: 17
      }
    },
    flexContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 10,
      marginTop: 20,
    },
    title: {
      marginBottom: 20,
    },
    error: {
      color: '#ED4E4A',
      fontWeight: 'bold',
    }
  })

  const classes = useStyle()

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalQrUser request-modal">
        <span className={classes.close} onClick={() => close()}><FontAwesomeIcon icon="fa-solid fa-xmark" /></span>
        {showForm && <div className={classes.flexContainer}>
          <h2 className={classes.title}>Je n'ai pas été scanné</h2>
          <SelectField field={{
            label: "Établissement",
            data: brands.map((brand) => ({ name: brand.name, id: brand.brand_id })),
            handleChange: (value) => {
              setSelectedBrand(brands.find((brand) => brand.brand_id === value));
              empty && setEmpty(false)
              if (selectedStore) { setSelectedStore(null); }
            }
          }} />
          {selectedBrand && (
            <SelectField field={{
              label: "Boutique",
              data: selectedBrand.stores.map((store) => ({ name: store.name, id: store.store_id })),
              handleChange: (value) => {
                setSelectedStore(selectedBrand.stores.find((store) => store.store_id === value));
                empty && setEmpty(false)
              }
            }} />
          )}
          <Formfields field={{ type: 'text', component: 'textarea', name: 'content', label: "Commentaire", size: '90%', handleChange: (text) => { setContent(text); empty && setEmpty(false) } }} />
          {empty && <p className={classes.error}>Veuillez remplir tous les champs</p>}
          <div className={classes.buttonContainer}>
            <Button className="button secondary" text='Annuler' onClick={onClose} />
            <Button className="button third" text='Envoyer' onClick={onSend} />
          </div>
        </div>}
        {success && <div>
          <h2 className={classes.title}>Votre demande a bien été envoyée</h2>
          <div>
            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg>
          </div>
        </div>}
        {error && <div>
          <h2 className={classes.title}>Une erreur est survenue.</h2>
          <div>
            <svg className="crossmark animateElement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="crossmark__circle addClass" cx="26" cy="26" r="25" fill="none" />
              <path className="cross__path cross__path--right animateElement" fill="none" d="M16,16 l20,20" />
              <path className="cross__path cross__path--left animateElement" fill="none" d="M16,36 l20,-20" />
            </svg>
          </div>
        </div>}
      </div>
    </div>
  </div>
}

export default ModalNotScanned;