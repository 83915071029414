import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { HelmetProvider } from 'react-helmet-async';
import * as Sentry from "@sentry/react";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import App from './App';
import './main.scss'

if (process.env.REACT_APP_MODE === "prod") {
  Sentry.init({
    dsn: "https://9f58fcad4a5a434fba900eaea9dfd893@o4504932989272064.ingest.sentry.io/4504932993007616",
  });
}

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <HelmetProvider>
    <Router>
      <SnackbarProvider maxSnack={3}>
        <App />
      </SnackbarProvider>
    </Router>
  </HelmetProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();