// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { useParams } from 'react-router-dom';
import parse from 'html-react-parser';
// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Title from '../../components/Title';
import HeroBanner from '../../components/HeroBanner';
import Button from '../../components/Button';

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../contexts/Api';
import Cookies from 'universal-cookie';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Offre = () => {

  // Récupérer l'id de l'actualité
  const params = useParams();

  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [offer, setOffer] = useState({})

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    offreInfos: {
      paddingLeft: '20px',

      '& h3': {
        fontWeight: '600',
        color: 'var(--grey)'
      },
      '& h5': {
        fontWeight: '300',
        color: 'var(--grey)'
      },
    },
    offreContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0 20px',
      minHeight: '200px',
      borderRadius: '30px',
      justifyContent: 'space-around',
      marginTop: '25px',

      '& p': {
        color: 'var(--grey)',
        fontSize: 14
      }
    },
  })
  const classes = useStyle();

  //
  // ─── SEARCH REQUEST TO GET OFFER ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchOffer = async () => {
      const getOffre = await Api({
        endpoint: '/offers',
        query: { offer_id: params.offre_id },
        method: 'GET',
      });

      if (getOffre.success) {
        setOffer(getOffre.data);
      } else {
        setOffer([]);
      }
    };
    if (!Object.keys(offer).length) fetchOffer();
  }, [offer, params.offre_id]);

  // 
  // ─── TRACKING USER OFFER ───────────────────────────────────────
  //
  useEffect(() => {
    const trackingUserOffer = async () => {
      await Api({ endpoint: '/tracking_actu_offer', method: 'POST', data: { type: 2, type_id: params.offre_id } })
    }
    if (userData.role === 2) trackingUserOffer()
  }, [])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return offer && <>
    <Title text={offer.title} return={true} />
    <HeroBanner link={`offers/${offer.offer_id}/${offer.banner ? offer.banner : offer.thumbnail}`} />
    <div className={classes.offreInfos}>
      {offer.title && <h3>{offer.title}</h3>}
      {offer.subtitle && <h5>{offer.subtitle}</h5>}
    </div>
    <div className={classes.offreContainer}>
      {offer.content && parse(offer.content)}
      {offer.url && <Button className="button cta" text={offer.cta || 'Commander'} href={offer.url} />}
    </div>
  </>
};

export default Offre;