// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react'
import { QRCodeSVG } from 'qrcode.react';

// 
// ─── IMPORTING SVG ───────────────────────────────────────
//
import BK from '../../../assets/static/LOGOBK.png'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons';

// SCSS
import '../Qr/User/ModalQrCode.scss'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const GainUser = ({ open, hash, name, brand, onClose }) => {

  // FONTAWESOME
  library.add(faXmark)

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={`modal-container two ${open ? '' : 'out'}`}>
    <div className="modal-background">
      <div className="modalQrUser">
        <FontAwesomeIcon icon="fa-solid fa-xmark" className='closeIcon' onClick={onClose} />
        <div className='userInfosModal'>
          <h3 className='nameUser'>{brand}</h3>
          <h2 className='firstNameUser'>{name}</h2>
        </div>
        <div className='qrContainer'>
          {/* Arrive bientôt... */}
          <QRCodeSVG
            size={200}
            value={hash}
            level={"L"}
            bgColor={"#f6f4f3"}
            imageSettings={{
              src: BK,
              x: undefined,
              y: undefined,
              height: 45,
              width: 50,
              excavate: false,
            }}
          />
        </div>
      </div>
    </div>
  </div>
}

export default GainUser;