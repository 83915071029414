// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import Api from '../../contexts/Api';
import { formatDate } from '../../contexts/Utils';

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDownload } from '@fortawesome/pro-duotone-svg-icons';

const Export = ({ data, date, type }) => {

  // FONTAWESOME
  library.add(faDownload)

  let fileName
  let total
  let data_to_send = {}
  let start = formatDate({ time: date.start, display: 'date' }) || ''
  let end = formatDate({ time: date.end, display: 'date' }) || ''

  const date_to_send = date.start.length ? `Du ${start} au ${end}` : 'Toutes périodes confondues'

  switch (type) {
    case 'caisse':
      fileName = 'passage_en_caisse';
      total = data.reduce((acc, o) => acc + parseInt(o.passageNumber), 0)
      for (let i = 0; i < data.length; i++) {
        const { name, passageNumber } = data[i];
        data_to_send[name] = passageNumber;
      }
      break;
    case 'palier':
      fileName = 'paliers_fidelite';
      const user_total = data.users.reduce((sum, value) => sum + value.number, 0)
      for (let i = 0; i < data.users.length; i++) {
        const { range, number } = data.users[i];
        data_to_send[range] = { nb: number, percent: +((number / user_total) * 100).toFixed(1) };
      }
      const gains_total = data.gains.reduce((sum, value) => sum + value.number, 0)
      for (let i = 0; i < data.gains.length; i++) {
        const { range, number } = data.gains[i];
        data_to_send[range] = { nb: number, percent: +((number / gains_total) * 100).toFixed(1) };
      }
      break;
    case 'gains':
      fileName = 'gains';
      total = {
        total: data.played,
        gains: data.gains_won,
        won: data.wonTotal,
        used: data.usedTotal
      }
      data_to_send = data
      break;
    default:
      fileName = 'export';
  }

  const handleExportClick = async () => {
    const getExport = await Api({
      endpoint: '/export',
      data: { data_to_send, total, date_to_send, type },
      method: 'POST'
    });

    if (getExport.success) {
      const link = document.createElement('a');
      link.href = "data:application/pdf;base64," + getExport.buffer;
      link.download = `${fileName}.xlsx`;
      link.click();
    }
  };

  return <button className='exportButton' onClick={handleExportClick}><FontAwesomeIcon icon={faDownload} /> Export XLSX</button>;
}

export default Export;