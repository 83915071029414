import React, { useEffect, useState } from 'react';
import Router from './components/Router/Router';
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom';

import backgroundVideo from './assets/video/video_alveoles.mp4'
import BannerIOS from './components/Modals/BannerIOS';
import backgroundRuche from './assets/static/background.png'

const App = () => {
  // STATE
  // Popover
  const [showPopover, setShowPopover] = useState(false);
  const [device, setDevice] = useState(null);
  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    wrapper: {
      position: 'relative',
      height: window.innerHeight,
    },
    video: {
      position: 'fixed',
      objectFit: 'cover',
      zIndex: -1,
      width: '100%',
      height: '100%'
    },
    backgroundRuche: {
      position: 'fixed',
      objectFit: 'cover',
      inset: 0,
      zIndex: -1,
      width: '100%',
      height: '100%'
    },
    main: {}
  })

  const classes = useStyle()

  // admin or not
  const app = useLocation();

  //
  // ─── BANNER PWA IOS ───────────────────────────────────────
  //
  useEffect(() => {
    const getDevice = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      const popoverClosed = localStorage.getItem('popoverClosed');


      // Android detection
      if (/android/i.test(userAgent) && (popoverClosed === null || popoverClosed === 'false')) {
        setShowPopover(true);
        setDevice('android');
        return;
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream && (popoverClosed === null || popoverClosed === 'false')) {
        setShowPopover(true);
        setDevice('ios');
        return;
      }

      return "unknown";
    }
    getDevice()
  }, []);

  const handlePopoverClose = () => {
    setShowPopover(false);
    localStorage.setItem('popoverClosed', 'true');
  };

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <>
    <div className={classes.wrapper}>
      {(showPopover && app.pathname.includes('/connexion')) && <BannerIOS device={device} close={handlePopoverClose} />}
      {/* {!app.pathname.includes('/admin') && device !== 'ios' && <video className={classes.video} src={backgroundVideo} autoPlay loop muted playsInline="playsinline" />} */}
      {!app.pathname.includes('/admin') && <img alt='ruche-background' className={classes.backgroundRuche} src={backgroundRuche} />}
      <Router />
    </div>
  </>
}

export default App;
