// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import React from 'react';
import { useNotification } from '../components/UseNotification'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Button = ({ onClick, className, text, disabled, href }) => {

  // Notification user
  const { notification } = useNotification()

  const error = () => {
    notification({ variant: 'error', message: 'Veuillez sélectionner votre entreprise' })
  }

  const button = <div onClick={disabled ? error : onClick} className={className} type="button">{text}</div>

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return href ? <a href={href}>
    {button}
  </a> :
    button
}

export default Button;