// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react';

// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle
} from "@mui/material";

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const AdminAlertPopOver = ({ open, status, onClose, id, onUpdate, func, text }) => {
  const onAgree = () => {
    if (status === 4) onUpdate({ fields: { status: 4 }, archive: true, id: id })
    if (status === 2) onUpdate({ fields: { status: 2 }, restore: true, id: id })
    onClose()
    func()
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth={true}
      maxWidth={'xs'}
    >
      <DialogTitle id="alert-dialog-title">
        {text}
      </DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>Annuler</Button>
        <Button variant='contained' onClick={onAgree} autoFocus>
          Accepter
        </Button>
      </DialogActions>
    </Dialog>
  )
};

export default AdminAlertPopOver;