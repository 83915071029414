// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { prepareFormData, getValue } from '../../../contexts/Utils';
import Api from '../../../contexts/Api';
import { useNotification } from '../../../components/UseNotification';
import TitleForm from '../../../components/Admin/TitleForm';
import Formfields from '../../../components/Admin/FormfieldsBack';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const EditUser = () => {
  // Récupérer l'id de l'utilisateur
  const params = useParams();

  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [user, setUser] = useState({})
  const [brands, setBrands] = useState([]);
  const [stores, setStores] = useState([])
  const [errors, setErrors] = useState({});

  // Admin notification
  const { notification } = useNotification()

  // Go back
  const navigate = useNavigate()

  //
  // ─── SEARCH REQUEST TO GET USER ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchUser = async () => {
      const getUser = await Api({
        endpoint: '/users',
        query: { query_user_id: params.user_id },
        method: 'GET',
      });

      if (getUser.success) {
        setUser(getUser.data)
      } else {
        setUser({});
      }
    };
    const fetchBrands = async () => {
      // Je lance ma requête avec l'API
      const getBrands = await Api({
        method: 'GET',
        query: { store: true },
        endpoint: '/brands',
      })

      if (getBrands.success && getBrands.data.length) {
        // Je rempli mon tableau avec les données
        setBrands(getBrands.data);
      }
    };
    if (!brands.length) fetchBrands()
    if (!Object.keys(user).length) fetchUser();
    if (brands.length && user.brand_id) handleStore(user.brand_id)
  }, [user, params.user_id, brands]);


  // 
  // ─── HANDLE MANAGE USER ───────────────────────────────────────
  //
  const handleChange = async () => {
    let data = prepareFormData({ formId: 'manageUser' })

    if (data.errors) {
      setErrors({ ...errors, 'manageUser': { ...data.errors } })
    } else {

      const manageUser = await Api({
        data: { fields: data.fields, email: true, edit: true, user_edit: params.user_id },
        method: 'PUT',
        endpoint: '/users'
      })

      // 
      // ─── ON SUCCESS ───────────────────────────────────────
      //
      if (manageUser.success) {
        navigate(-1)
        return notification({ variant: 'success', message: manageUser.message })
      } else {
        notification({ variant: 'error', message: manageUser.message })
      }

    }
  }

  // 
  // ─── HANDLE STORE SELECT ───────────────────────────────────────
  //
  const handleStore = (user_store) => {
    let selectBrand = brands.filter((brand) => brand.brand_id === user_store)
    let selectStore = selectBrand[0].stores.map((store) => { return { id: store.store_id, name: store.name } })
    setStores(selectStore);
  }

  // 
  // ─── UPLOAD FILE ───────────────────────────────────────
  //
  const uploadFile = async (file, fileName) => {
    // 
    // ─── PREPARE DATA FOR API REQUEST BODY ───────────────────────────────────────
    //
    const formData = new FormData();
    if (file) {
      formData.append('file', file, `${fileName}`);
    }
    formData.append('id', user.user_id)
    formData.append('file', 'users')
    formData.append('type', 'thumbnail')
    // 
    // ─── API REQUEST FOR FILE UPLOAD ───────────────────────────────────────
    //
    const params = {
      data: formData,
      endpoint: '/files',
      method: 'POST',
      removeHeader: true
    }
    const addFile = await Api(params);
    // 
    // ─── UPDATE FILE INFORMATIONS IF SUCCESSFUL ───────────────────────────────────────
    //
    if (addFile && addFile.success) {
      return notification({ variant: 'success', message: addFile.message })
    } else {
      notification({ variant: 'error', message: addFile.message })
    }
  }

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: 'text', component: 'text', name: 'name', default: user.name, label: "Nom", size: '49%' },
    { type: 'text', component: 'text', name: 'firstname', default: user.firstname, label: "Prénom", size: '49%' },
    { type: 'text', component: 'text', name: 'email', default: user.email, label: "Email", size: '100%' },
    { type: 'text', component: 'text', name: 'phone', default: user.phone, label: "Téléphone", size: '49%' },
    { type: 'date', component: 'datepicker', name: 'birth_date', default: user.birth_date, label: "Date de naissance", size: '49%' },
    { type: 'text', component: 'text', name: 'address', default: user.address, label: "Adresse", size: '100%' },
    { type: 'text', component: 'text', name: 'cp', default: user.cp, label: "Code postal", size: '49%' },
    { type: 'text', component: 'text', name: 'city', default: user.city, label: "Ville", size: '49%' },
    { type: 'text', component: 'text', name: 'reference', default: user.reference, label: "Référence", size: '49%' },
    {
      type: 'select', component: 'select', name: 'brand_id', label: "Enseigne reliée à l'utilisateur", size: '49%', onSelect: (e) => handleStore(e.target.dataset.value), data: brands.map((brand) => {
        return { id: brand.brand_id, name: brand.name };
      }), default: user.brand_id
    },
    {
      type: 'select', component: 'select', name: 'store_id', label: "Boutique reliée à l'utilisateur", size: '100%', data: stores, default: user.store_id
    },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className='containerAdmin'>
    <TitleForm text="Modifier l'utilisateur" />
    {Object.keys(user).length && <Box component="form" name='manageUser' className='adminform'>
      <div className='columnAdminForm'>
        <Formfields field={{ type: 'text', component: 'file', name: 'thumbnail', label: "Image de profil (maximum 8mo)", crop: true, afterCrop: (file, fileName) => uploadFile(file, fileName), fileName: user.thumbnail, link: `/users/${user.user_id}/${user.thumbnail}` }} />
      </div>
      <div className='contentAdminUserForm'>
        <div>Informations générales :</div>
        <div className='headerContentUser'>
          {formFields.map((field) => {
            return <Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['manageUser', field.name, 'message']) } }} />
          })}
        </div>
        <Button variant="contained" onClick={handleChange}>Enregistrer</Button>
      </div>
    </Box>}
  </div>
};

export default EditUser;