// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React } from 'react'
import { createUseStyles } from 'react-jss'

// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUpFromBracket } from '@fortawesome/pro-solid-svg-icons'
import { faSquarePlus } from '@fortawesome/pro-regular-svg-icons'
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons'
// 
// ─── IMPORTING PNG ───────────────────────────────────────
//
import addToMobile from '../../assets/static/add-to-home.png'

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const BannerIOS = ({ close, device }) => {

  // 
  // ───  STYLE DEFINITION ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      height: window.innerHeight / 2,
      padding: '20px',
      background: 'white',
      borderRadius: 10,
      boxSizing: 'border-box',
      textAlign: 'center',

      '& svg, & img': {
        width: 30,
        height: 30,
        fill: 'var(--orange-primary)',
        color: 'var(--orange-primary)'
      }
    },
    content: {
      position: 'relative',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',

      '& p': {
        fontSize: 14,
        lineHeight: 2
      }
    },
    contentText: {
      marginTop: 30,
      marginBottom: 20,
    },
    close: {
      position: 'absolute',
      top: -10,
      right: -10,
    },
    flex: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 5,
      flexWrap: 'wrap'
    }
  })

  const classes = useStyle()

  library.add(faArrowUpFromBracket, faSquarePlus, faEllipsisVertical, faCircleInfo)
  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <div className={classes.content}>
      <div className={classes.close} onClick={close}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" /></svg></div>
      <h2>Web-app disponible !</h2>
      {device === 'ios' && <div className={classes.contentText}>
        <p>Pour installer l'application sur votre téléphone,</p>
        <p className={classes.flex}> cliquez sur <FontAwesomeIcon icon="fa-solid fa-arrow-up-from-bracket" /> situé en dessous de la barre de recherche</p>
        <p className={classes.flex}>puis sur "Ajouter à l'écran d'accueil" <FontAwesomeIcon icon="fa-regular fa-square-plus" />.</p>
      </div>}
      {device === 'android' && <div className={classes.contentText}>
        <p>Pour installer l'application sur votre téléphone,</p>
        <p className={classes.flex}>cliquez sur <FontAwesomeIcon icon="fa-solid fa-ellipsis-vertical" /> situé en haut à droite</p>
        <p className={classes.flex}>puis sur "Ajouter à l'écran d'accueil" <img src={addToMobile} />.</p>
      </div>}
      <p className={classes.flex}>Vous devez passer par {device === 'ios' ? 'Safari' : 'Chrome'} pour accéder <br /> à cette fonctionnalité.</p>
    </div>
  </div>
}

export default BannerIOS;