// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useEffect, useState } from 'react';
import { createUseStyles } from 'react-jss';
import { Link } from 'react-router-dom';

// 
// ─── IMPORTING PNG ───────────────────────────────────────
//
import offre1 from '../../assets/static/offres1.png'

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Title from '../../components/Title'

// 
// ─── IMPORTING UTILS ───────────────────────────────────────
//
import Api from '../../contexts/Api';
import { timeStamp } from '../../contexts/Utils';
// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleArrowRight } from '@fortawesome/pro-light-svg-icons';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Offres = () => {
  //
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [activeIndex, setActiveIndex] = useState(0)
  const [offers, setOffers] = useState([])

  // FONTAWESOME
  library.add(faCircleArrowRight)

  // 
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    offresContainer: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflowX: 'auto',
      overflowY: 'hidden',
      gap: '20px',
      paddingBottom: 40,
      scrollSnapType: 'x mandatory',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    },
    offreCard: {
      scrollSnapAlign: 'center',
      flex: '0 0 auto',
      maxWidth: 270,
      position: 'relative',
      height: 436,

      '&:first-child': {
        marginLeft: '20px'
      },

      '&:last-child': {
        marginRight: '20px'
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        background: 'linear-gradient(transparent 50%, rgba(27, 37, 55, 0.5) 100%)',
        inset: '0px',
        borderRadius: '20px',
      },
    },
    offerCardSolo: {
      scrollSnapAlign: 'center',
      flex: '0 0 auto',
      maxWidth: 270,
      position: 'relative',

      '&::after': {
        content: '""',
        position: 'absolute',
        background: 'linear-gradient(transparent 50%, rgba(27, 37, 55, 0.5) 100%)',
        inset: '0px',
        borderRadius: '20px',
      },
    },
    offreImg: {
      width: '100%',
      height: '100%',
      borderRadius: 20,
      objectFit: 'cover'
    },
    seeMore: {
      zIndex: '1',
      position: 'absolute',
      bottom: '5px',
      right: '10px',
      color: 'white',
      fontSize: '1.5em'
    },
    swiperPagination: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '10px'
    },
    scrollIndicator: {
      width: 5,
      height: 5,
      margin: '0 5px',
      borderRadius: '50%',
      background: 'var(--secondary)'
    },
    activeDot: {
      width: 8,
      height: 8,
      background: 'var(--orange-primary)'
    }
  })
  const classes = useStyle();

  // 
  // ─── FETCH OFFERS ───────────────────────────────────────
  //
  useEffect(() => {
    async function fetchOffers() {
      // Je lance ma requête avec l'API
      const getOffer = await Api({
        method: 'GET',
        endpoint: '/offers',
        query: { status: 2, dateFilter: true }
      })

      if (getOffer.success && getOffer.data.length) {
        // Je rempli mon tableau avec les données
        setOffers(getOffer.data);
      }
    };
    if (!offers.length) fetchOffers()
  }, [offers])
  // 
  // ─── OBSERVER IN USE EFFECT ───────────────────────────────────────
  //
  useEffect(() => {
    let rootSelect = document.getElementById('offresContainer')
    let slides = [...document.querySelectorAll('[data-section]')]

    const options = {
      root: rootSelect,
      threshold: 0.5
    }
    const callback = slides => {
      slides.forEach((entry) => {
        if (!entry.intersectionRatio > 0) {
          return
        }
        // Id de la card
        let cardId = entry.target.dataset.section
        // Je set mon state avec l'id de la card si elle est visible totalement
        //
        if (entry.isIntersecting) setActiveIndex(parseInt(cardId))
      })
    }
    const observer = new IntersectionObserver(callback, options)
    slides.forEach(slide => observer.observe(slide))
  }, [offers])

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return offers && <>
    <Title text='En ce moment' />
    <div id='offresContainer' className={classes.offresContainer}>
      {/* MAP SUR MES OFFRES CARD */}
      {offers.map((value, index) => {
        return value.title ? <Link to={`/offres/${value.offer_id}`} key={index} data-section={index} className={classes.offreCard}>
          <img loading="lazy" className={classes.offreImg} src={value.thumbnail ? `${process.env.REACT_APP_URL}/img/offers/${value.offer_id}/${value.thumbnail}?${timeStamp()}` : offre1} alt={value.thumbnail} />
          <div to={`/offres/${value.offer_id}`} className={classes.seeMore}><FontAwesomeIcon icon="fa-light fa-circle-arrow-right" /></div>
          <h3>{value.title}</h3>
        </Link>
          : <a className={classes.offerCardSolo} key={index} data-section={index} href={value.url}>
            <img className={`${classes.offreImg} lazy`} data-src={value.thumbnail ? `${process.env.REACT_APP_URL}/img/offers/${value.offer_id}/${value.thumbnail}?${timeStamp()}` : offre1} alt={value.thumbnail} />
          </a>
      })}
    </div>
    {/* MAP POUR CREER LES BULLETS */}
    <div className={classes.swiperPagination}>
      {offers.map((bullet, index) => {
        return <div id={index} key={index} className={`${classes.scrollIndicator} ${activeIndex === index ? classes.activeDot : ''}`}></div>
      })}
    </div>
  </>
};

export default Offres;