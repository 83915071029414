// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

// 
// ─── UTILS ───────────────────────────────────────
//
import Api from '../contexts/Api';
import { prepareFormData, getValue } from '../contexts/Utils';

// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../components/Formfields';
import { useNotification } from '../components/UseNotification';
import Button from '../components/Button';

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const Login = () => {
  // 
  // ─── STATE DECLARATION ───────────────────────────────────────
  //
  const [errors, setErrors] = useState({});

  // redirect the user
  let navigate = useNavigate();
  // inform the user
  const { notification } = useNotification()
  // Cookies
  const cookies = new Cookies();
  const userData = cookies.get(process.env.REACT_APP_USER_COOKIE);

  // 
  // ─── FORM FIELDS ───────────────────────────────────────
  //
  const formFields = [
    { type: "email", component: "text", name: "email", placeholder: "e-mail", required: true, iconStart: 'fa-duotone fa-envelope' },
    { type: "password", component: "text", id: 'password', name: "password", placeholder: "mot de passe", required: true, iconStart: 'fa-solid fa-lock', iconEnd: true }
  ]

  // 
  // ─── HANDLE USER LOGIN ───────────────────────────────────────
  //
  const handleChange = async () => {
    const data = prepareFormData({ formId: 'loginForm' });

    if (data.errors) {
      setErrors({ ...errors, 'loginForm': { ...data.errors } })
    } else {
      const logUser = await Api({
        data,
        endpoint: '/auth/login'
      })
      // 
      // ─── ON SUCCESS WE USE REDUX TO LOG USER ───────────────────────────────────────
      //
      if (logUser.success && logUser.access_token) {
        cookies.set(process.env.REACT_APP_USER_COOKIE, logUser.data, {
          expires: new Date(Date.now() + 604800000), // time until expiration
          secure: false, // true if using https
        })
        return navigate("/");
      } else {
        notification({ variant: 'error', message: logUser.message })
      }
    }
  }

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return userData ? navigate('/') : <div className='authContainer'>
    <form className='authForm' name='loginForm'>
      <div className='loginForm'>
        {formFields.map((field) => (<Formfields key={field.name} field={{ ...field, ...{ error: getValue(errors, ['loginForm', field.name, 'message']) } }} />))}
      </div>
      <div className='forgetPassword'>
        <Link to="/mot-de-passe-oublie">Mot de passe oublié</Link>
      </div>
      <div className='buttonContainer'>
        <Button className="button secondary" text="Inscription" onClick={() => navigate("/inscription")} />
        <Button className="button third" text='Connexion' onClick={handleChange} />
      </div>
    </form>
    <div className='legalsContainer'>
      <Link target="_blank" to="/politique-de-confidentialite">Politique de confidentialité</Link>
    </div>
  </div>

};

export default Login;