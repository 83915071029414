// 
// ─── IMPORTING NECESSARY ELEMENTS ───────────────────────────────────────
//
import { React, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
// ─── MATERIAL UI ────────────────────────────
// COMPONENTS
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from "@mui/material";
//
// ─── UTILS ────────────────────────────
//
import Api from '../../../contexts/Api';
//
// ─── STYLE ────────────────────────────
//
import { createUseStyles } from "react-jss";
// 
// ─── IMPORTING FONTAWESOME ICONS ───────────────────────────────────────
//
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRotateRight } from '@fortawesome/pro-duotone-svg-icons';
// 
// ─── IMPORTING COMPONENTS ───────────────────────────────────────
//
import Formfields from '../../../components/Admin/FormfieldsBack';
import TitleForm from '../../../components/Admin/TitleForm';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// 
// ─── COMPONENT DECLARATION ───────────────────────────────────────
//
const DetailsBrand = () => {
  // FONTAWESOME
  library.add(faRotateRight)
  //
  // ─── DECLARATION DE MON STATE ───────────────────────────────────────
  //
  // BRAND
  const [brand, setBrand] = useState([]);
  // STORES
  const [stores, setStores] = useState([]);
  // USERS
  const [users, setUsers] = useState([]);
  // ANALYTICS
  const [analytics, setAnalytics] = useState({ all: [], filtered: [] });
  // DATE FILTER
  const [dateFilter, setDateFilter] = useState({ start: '', end: '', reset: false });
  // LOADING
  const [loading, setLoading] = useState(true);

  // Récupérer l'id de l'enseigne
  const params = useParams();

  //
  // ─── INITIALISATION DES STYLES ───────────────────────────────────────
  //
  const useStyle = createUseStyles({
    container: {
      marginTop: 45,
      display: 'flex',
      flexDirection: 'column',
      gap: 30,

      '& .MuiPaper-rounded': {
        marginBottom: 30
      },

      '& h1': {
        marginBottom: 10
      },

      '& h2': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif !important'
      }
    },
    gridContainer: {
      display: 'flex',
      gap: 30,
      alignItems: 'stretch'
    },
    containerBoxLeft: {
      width: window.innerWidth > 1500 ? '35%' : '30%',
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
    },
    containerBoxRight: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      gap: 30,
    },
    box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: '25px 30px',
      gap: 20,
      background: '#FFFFFF',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.08), 0px 2px 44px rgba(0, 0, 0, 0.05)',
      borderRadius: 7,

      '& .text': {
        fontSize: 16,
        fontWeight: 400,
        opacity: 0.5
      },

      '& .number': {
        fontSize: 96,
        color: '#E17F51',
        fontWeight: 900,
        margin: '0 auto'
      },

      '& .MuiInputBase-root': {
        padding: 0,
      },

      '& fieldset': {
        border: '1px solid #999999',
        borderRadius: 3
      }
    },
  })
  const classes = useStyle();

  //
  // ─── REQUEST TO GET BRAND INFOS, RELATED STORES AND USERS AND THEIR ANALYTICS ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchData = () => {
      Promise.all([
        Api({ endpoint: '/brands', query: { fields: ['name'], brand_id: params.brand_id }, method: 'GET' }),
        Api({ endpoint: '/stores', query: { fields: ['name', 'store_id'], brand_id: params.brand_id, analytics: 1 }, method: 'GET' }),
        Api({ endpoint: '/analytics/scan', query: { users: true, brand_id: params.brand_id }, method: 'GET' }),
        Api({ endpoint: '/analytics/scan', query: { stores: true, brand_id: params.brand_id }, method: 'GET' }),
      ])
        .then(([brandReq, storeReq, userReq, storesAnalyticsReq]) => {
          if (brandReq.success) setBrand(brandReq.data);
          if (storeReq.success) setStores(storeReq.data);
          if (userReq.success) setUsers(userReq.data);
          if (storesAnalyticsReq.success) setAnalytics({ all: storesAnalyticsReq.data, filtered: [] });
          setLoading(false);
        });
    }

    setBrand([]);
    setStores([]);
    setUsers([]);
    fetchData();
  }, [])

  //
  // ─── SEARCH REQUEST TO GET BOUTIQUES SCAN INFOS ───────────────────────────────────────
  //
  const fetchAnalytics = async () => {
    setDateFilter({ ...dateFilter, reset: false });
    const getAnalytics = await Api({
      endpoint: '/analytics/scan',
      query: { stores: true, brand_id: params.brand_id },
      method: 'GET'
    });

    if (getAnalytics.success && getAnalytics.data.length) {
      setAnalytics({ all: getAnalytics.data, filtered: [] });
    }
  };

  //
  // ─── SEARCH REQUEST TO GET PASSAGE SCAN INFOS BY DATE ───────────────────────────────────────
  //
  useEffect(() => {
    const fetchAnalytics = async () => {
      const getAnalytics = await Api({
        endpoint: '/analytics/scan',
        query: { stores: true, brand_id: params.brand_id, time_create: dateFilter.start, time_end: dateFilter.end },
        method: 'GET'
      });

      if (getAnalytics.success && getAnalytics.data.length) {
        setAnalytics({ ...analytics, filtered: getAnalytics.data });
      } else {
        setAnalytics({ all: [], filtered: [] });
      }
    };
    if (dateFilter.start || dateFilter.end) fetchAnalytics();
    else setAnalytics({ ...analytics, filtered: [] });
  }, [dateFilter]);


  let analyticsList = analytics.all
  if (analytics.filtered.length) analyticsList = analytics.filtered

  // ON BOUCLE POUR AJOUTER 0 EN DONNEE SI PAS D ANALYTICS 
  stores.map((value) => {
    if (analyticsList.find(e => e.store_id === value.store_id) === undefined) {
      let obj = {
        store_id: value.store_id,
        passageNumber: 0,
        name: value.name
      }
      analyticsList.push(obj)
    }
  })

  // ON TRIE ET RANGE L ARRAY PAR NOM D ENSEIGNE POUR MATCH L'ARRAY DES LABELS
  analyticsList.sort((a, b) => a.name.localeCompare(b.name))

  // DATA CHART
  const data = {
    labels: stores.map(store => { return store.name }),
    datasets: [
      {
        label: 'Passage en caisse',
        data: analyticsList.map(passage => { return passage.passageNumber }),
        backgroundColor: '#e17f51',
      },
    ],
    options: {
      indexAxis: 'y',
      elements: {
        bar: {
          borderWidth: 2,
        },
      },
      scales: {
        x: {
          ticks: {
            stepSize: 1
          }
        }
      },
      plugins: {
        legend: {
          position: 'right',
        },
      },
      responsive: true,
      maintainAspectRatio: false
    }
  };

  // PLUGIN FOR NO DATA MESSAGE
  const plugins = [
    {
      afterDraw: function (chart) {
        if (chart.data.datasets[0].data.every(item => item === 0)) {
          let ctx = chart.ctx;
          let width = chart.width;
          let height = chart.height;
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "30px Arial";
          ctx.fillText("Pas de données", width / 2, height / 2);
          ctx.restore();
        }
      },
    },
  ];

  // 
  // ─── COMPONENT RENDER ───────────────────────────────────────
  //
  return <div className={classes.container}>
    <TitleForm text={brand.name} />
    <div className={classes.gridContainer}>
      <div className={classes.containerBoxLeft}>
        <div className={classes.box} style={{ height: '100%', justifyContent: 'flex-start' }}>
          <Typography variant="h2" component="h2">Inscrits dans l'enseigne</Typography>
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableHead sx={{ background: '#e17f51' }}>
                <TableRow sx={{ '& th': { color: 'white' } }}>
                  <TableCell>Nom</TableCell>
                  <TableCell>Utilisation</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ?
                  <div>Chargement...</div>
                  : users.length ? users.map((user) => {
                    return <TableRow
                      hover
                      key={user.user_id}
                    >
                      <TableCell component="th" scope="row">
                        {user.name + ' ' + user.firstname}
                      </TableCell>
                      <TableCell>{user.passageNumber}</TableCell>
                    </TableRow>
                  }) : <TableRow hover>
                    <TableCell component="th" scope="row">
                      Pas d'inscrits
                    </TableCell>
                  </TableRow>}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
      <div className={classes.containerBoxRight}>
        <div className={classes.box}>
          <Typography variant="h2" component="h2">Passage en caisse</Typography>
          <div style={{ width: '100%' }}>
            <div className='barFilterContainer'>
              <div className='flexRowContainer'>
                <div className='flexColumnContainer'>
                  <div className='text'>Filtrer par date</div>
                  <div className='dates'>
                    <button className='resetButton' onClick={() => { setDateFilter({ start: '', end: '', reset: true }); fetchAnalytics() }}><FontAwesomeIcon icon={faRotateRight} /></button>
                    <div className='datesInput'>
                      <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_create', admin: true, start: true, size: '50%', reset: dateFilter.reset, handleChange: (e) => setDateFilter({ ...dateFilter, start: e, reset: false }) }} />
                      -
                      <Formfields field={{ type: 'date', component: 'datepicker', name: 'time_end', admin: true, size: '50%', className: 'needMargin', reset: dateFilter.reset, handleChange: (e) => setDateFilter({ ...dateFilter, end: e, reset: false }) }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <p className='tip tipDates'>
              Laisser vide pour toutes dates confondues
            </p>
            {loading ?
              <div>Chargement...</div>
              : analyticsList.length ?
                <div style={{ width: '100%', height: brand.name === "Domino's" ? '150vh' : '40vh' }}> <Bar options={data.options} data={data} plugins={plugins} /> </div> :
                <div>Pas de données d'analytics pour le moment.</div>
            }
          </div>
        </div>
      </div>
    </div>
  </div>
};

export default DetailsBrand;